import _ from "lodash";
import PropTypes from "prop-types";
import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { Fonts, Field, DollarIcon, HyperLink, LinkStyles } from "yuka";

import { useCompany } from "./hooks";

import { HALF_SECOND, ONE_BILLION } from "../utils/constants";
import { shortMoneyFormat } from "../utils/displayFormatUtils";
import MixpanelEvents from "../utils/mixpanel/MixpanelEvents";
import roundDecimal from "../utils/roundDecimal";
import useDebouncedState from "../utils/useDebouncedState";
import { PostMoneyDisclaimerModal } from "./modals";

const StyledCardContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 16px;
  width: 368px;
  box-sizing: border-box;
  text-align: left;
  gap: 16px;

  > :last-child {
    text-align: right;
  }
`;

const StyledInputPrompt = styled(Fonts.Body1theme30)`
  white-space: nowrap;
`;

const debouncedModifyPricePerShare = _.debounce(
  (companyName, previousValue, newValue) => {
    MixpanelEvents.modifyCustomPricePerShareField(
      companyName,
      previousValue,
      newValue
    );
  },
  HALF_SECOND,
  { leading: false, trailing: true }
);

const debouncedModifyImpliedValue = _.debounce(
  (companyName, previousValue, newValue) => {
    MixpanelEvents.modifyCustomValuationField(
      companyName,
      previousValue,
      newValue
    );
  },
  HALF_SECOND,
  { leading: false, trailing: true }
);

const numberFormatter = (value) => Number(roundDecimal(value, 6));

const PostMoneyCalculatorDropdown = ({
  latestFundingRound,
  latestOrderFlow,
}) => {
  const [disclaimerModalShowing, setDisclaimerModalShowing] = useState(false);
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [company, companyIsLoading] = useCompany();
  const [pricePerShare, setPricePerShare] = useState(null);
  const [impliedValue, setImpliedValue] = useState(null);

  // Two fields used for accurate mixpanel reporting.
  const [
    previousPricePerShare,
    setPreviousPricePerShare,
    _setPreviousPricePerShare,
  ] = useDebouncedState(null);
  const [
    previousImpliedValue,
    setPreviousImpliedValue,
    _setPreviousImpliedValue,
  ] = useDebouncedState(null);

  const outstandingShares = useMemo(
    () =>
      latestFundingRound
        ? latestFundingRound.postmoney_valuation /
          latestFundingRound.price_per_share
        : null,
    [latestFundingRound]
  );

  const valuationFromZXIndexValue = useMemo(() => {
    if (!outstandingShares || !latestOrderFlow?.zx_index_value) {
      return null;
    }
    return outstandingShares * latestOrderFlow.zx_index_value;
  }, [latestOrderFlow, outstandingShares]);

  useEffect(() => {
    // We must handle this logic in a useEffect to pre-load the latest funding round's values
    // because the API fetches are async and we need to set the initial values of the fields, but we
    // do not want the fields to be reset whenever they become null, so we can't just set them
    // conditionally if they're null, we need an extra variable.
    // Note that we're skipping the debounce logic for the 'previous' values since we want this
    // initial load to be snappy.
    if (isInitialLoad && latestOrderFlow && valuationFromZXIndexValue) {
      setIsInitialLoad(false);
      setPricePerShare(numberFormatter(latestOrderFlow.zx_index_value));
      _setPreviousPricePerShare(
        numberFormatter(latestOrderFlow.zx_index_value)
      );
      setImpliedValue(numberFormatter(valuationFromZXIndexValue) / ONE_BILLION);
      _setPreviousImpliedValue(
        numberFormatter(valuationFromZXIndexValue) / ONE_BILLION
      );
    }
  }, [
    _setPreviousImpliedValue,
    _setPreviousPricePerShare,
    latestOrderFlow,
    valuationFromZXIndexValue,
    isInitialLoad,
  ]);

  if (companyIsLoading) {
    return (
      <StyledCardContent>
        <Fonts.Body1theme50>Loading...</Fonts.Body1theme50>
      </StyledCardContent>
    );
  }

  if (!company) {
    return (
      <StyledCardContent>
        <Fonts.Body1theme50>An error has occurred.</Fonts.Body1theme50>
      </StyledCardContent>
    );
  }

  return (
    <StyledCardContent>
      <Fonts.Body1theme80>
        Calculate a custom post-money valuation or price per share based on{" "}
        {latestFundingRound?.event} at{" "}
        {shortMoneyFormat(latestFundingRound?.price_per_share)}/share.
      </Fonts.Body1theme80>
      <Field
        type="number"
        name="price_per_share"
        placeholder="Price / share"
        trailingIcon={() =>
          pricePerShare !== null ? (
            <StyledInputPrompt>Price / share</StyledInputPrompt>
          ) : null
        }
        leadingIcon={DollarIcon}
        onChange={(e) => {
          if (e.target.value === "") {
            debouncedModifyPricePerShare(
              company.name,
              previousPricePerShare,
              null
            );
            setPreviousPricePerShare(null);
            setPricePerShare(null);
            setImpliedValue(null); // Cast an empty conversion to empty input.
          } else {
            debouncedModifyPricePerShare(
              company.name,
              previousPricePerShare,
              e.target.value
            );
            setPreviousPricePerShare(e.target.value);
            setPricePerShare(e.target.value);
            const impliedValuation = outstandingShares
              ? outstandingShares * e.target.value
              : null;
            setImpliedValue(numberFormatter(impliedValuation / ONE_BILLION));
          }
        }}
        value={pricePerShare}
      />
      <Field
        type="number"
        name="valuation"
        placeholder="Valuation (billions)"
        trailingIcon={() =>
          impliedValue !== null ? (
            <StyledInputPrompt>Valuation (billions)</StyledInputPrompt>
          ) : null
        }
        leadingIcon={DollarIcon}
        onChange={(e) => {
          if (e.target.value === "") {
            debouncedModifyImpliedValue(
              company.name,
              previousImpliedValue,
              null
            );
            setPreviousImpliedValue(null);
            setImpliedValue(null);
            setPricePerShare(null); // Cast an empty conversion to empty input.
          } else {
            debouncedModifyImpliedValue(
              company.name,
              previousImpliedValue,
              e.target.value
            );
            setPreviousImpliedValue(e.target.value);
            setImpliedValue(e.target.value);
            const impliedPPS = outstandingShares
              ? (e.target.value * ONE_BILLION) / outstandingShares
              : null;
            setPricePerShare(numberFormatter(impliedPPS));
          }
        }}
        value={impliedValue}
      />
      <HyperLink
        linkStyle={LinkStyles.SUPPORT}
        onClick={() => {
          setDisclaimerModalShowing(true);
        }}
      >
        <Fonts.Caption2theme30>View disclaimer</Fonts.Caption2theme30>
      </HyperLink>
      {disclaimerModalShowing && (
        <PostMoneyDisclaimerModal
          onClose={() => setDisclaimerModalShowing(false)}
        />
      )}
    </StyledCardContent>
  );
};

PostMoneyCalculatorDropdown.propTypes = {
  latestFundingRound: PropTypes.shape({
    event: PropTypes.string,
    postmoney_valuation: PropTypes.string,
    price_per_share: PropTypes.string,
  }).isRequired,
  latestOrderFlow: PropTypes.shape({
    zx_index_value: PropTypes.number,
    total_volume: PropTypes.number,
  }).isRequired,
};

export default PostMoneyCalculatorDropdown;
