const LOADING_TEXT = "Loading...";

const ROBUSTNESS_FILTER = "robustness";
const ZX_INDEX_VALUE_CHANGE_FILTER = "zx_index_value_change";
const PREMIUM_TO_LAST_ROUND_FILTER = "last_round_price_diff";

const WATCHING = "Watching";
const NOT_WATCHING = "Not watching";
const WATCHLIST_BOOLEAN_MAP = {
  [WATCHING]: true,
  [NOT_WATCHING]: false,
};

const THIRTY_PERCENT_AND_ABOVE = "30% and above";
const TWENTY_PERCENT_AND_ABOVE = "20% and above";
const TEN_PERCENT_AND_ABOVE = "10% and above";
const ZERO_TO_FIVE_PERCENT = "0% to 5%";
const ZERO_PERCENT_AND_BELOW = "0% and below";
const ZERO_TO_NEGATIVE_FIVE_PERCENT = "0% to -5%";
const NEGATIVE_FIVE_PERCENT_AND_BELOW = "-5% and below";
const NEGATIVE_TEN_PERCENT_AND_BELOW = "-10% and below";
const NEGATIVE_TWENTY_PERCENT_AND_BELOW = "-20% and below";
const NEGATIVE_THIRTY_PERCENT_AND_BELOW = "-30% and below";

const NINE_AND_ABOVE = "9 and above";
const EIGHT_AND_ABOVE = "8 and above";
const SEVEN_AND_ABOVE = "7 and above";
const FOUR_TO_SIX = "4 to 6";
const THREE_AND_BELOW = "3 and below";

const RANGE_QUERY_PARAMS = {
  [THIRTY_PERCENT_AND_ABOVE]: {
    min: 30,
    max: null,
  },
  [TWENTY_PERCENT_AND_ABOVE]: {
    min: 20,
    max: null,
  },
  [TEN_PERCENT_AND_ABOVE]: {
    min: 10,
    max: null,
  },
  [ZERO_TO_FIVE_PERCENT]: {
    min: 0,
    max: 5,
  },
  [ZERO_PERCENT_AND_BELOW]: {
    min: null,
    max: 0,
  },
  [ZERO_TO_NEGATIVE_FIVE_PERCENT]: {
    min: -5,
    max: 0,
  },
  [NEGATIVE_FIVE_PERCENT_AND_BELOW]: {
    min: null,
    max: -5,
  },
  [NEGATIVE_TEN_PERCENT_AND_BELOW]: {
    min: null,
    max: -10,
  },
  [NEGATIVE_TWENTY_PERCENT_AND_BELOW]: {
    min: null,
    max: -20,
  },
  [NEGATIVE_THIRTY_PERCENT_AND_BELOW]: {
    min: null,
    max: -30,
  },
  [NINE_AND_ABOVE]: {
    min: 9,
    max: null,
  },
  [EIGHT_AND_ABOVE]: {
    min: 8,
    max: null,
  },
  [SEVEN_AND_ABOVE]: {
    min: 7,
    max: null,
  },
  [FOUR_TO_SIX]: {
    min: 4,
    max: 6,
  },
  [THREE_AND_BELOW]: {
    min: null,
    max: 3,
  },
};

const WATCHLIST_OPTIONS = [WATCHING, NOT_WATCHING];

const ZX_INDEX_VALUE_CHANGE_OPTIONS = [
  THIRTY_PERCENT_AND_ABOVE,
  TWENTY_PERCENT_AND_ABOVE,
  TEN_PERCENT_AND_ABOVE,
  ZERO_TO_FIVE_PERCENT,
  ZERO_PERCENT_AND_BELOW,
  ZERO_TO_NEGATIVE_FIVE_PERCENT,
  NEGATIVE_FIVE_PERCENT_AND_BELOW,
  NEGATIVE_TEN_PERCENT_AND_BELOW,
  NEGATIVE_TWENTY_PERCENT_AND_BELOW,
  NEGATIVE_THIRTY_PERCENT_AND_BELOW,
];

const ROBUSTNESS_SCORE_OPTIONS = [
  NINE_AND_ABOVE,
  EIGHT_AND_ABOVE,
  SEVEN_AND_ABOVE,
  FOUR_TO_SIX,
  THREE_AND_BELOW,
];

const PREMIUM_TO_LAST_ROUND_OPTIONS = [...ZX_INDEX_VALUE_CHANGE_OPTIONS];

export {
  LOADING_TEXT,
  ROBUSTNESS_FILTER,
  ZX_INDEX_VALUE_CHANGE_FILTER,
  PREMIUM_TO_LAST_ROUND_FILTER,
  WATCHING,
  NOT_WATCHING,
  WATCHLIST_BOOLEAN_MAP,
  RANGE_QUERY_PARAMS,
  WATCHLIST_OPTIONS,
  ZX_INDEX_VALUE_CHANGE_OPTIONS,
  ROBUSTNESS_SCORE_OPTIONS,
  PREMIUM_TO_LAST_ROUND_OPTIONS,
};
