import React, {
  useCallback,
  useRef,
  useEffect,
  useState,
  useMemo,
} from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import _ from "lodash";
import {
  Thumbnail,
  SearchIcon,
  FontColors,
  Modal,
  body1,
  List,
  ListItem,
  StyledCaption2,
  FilterChip,
  BuildingIcon,
  LineChartUpIcon,
} from "yuka";

import { Input } from "../hdYuka";

import useScrollable from "../utils/useScrollable";
import useInfiniteFetch from "../api/useInfiniteFetch";
import {
  API_ENDPOINTS,
  PRIVATE_MARKET_INDICES_FEATURE_NAME,
} from "../api/constants";
import { ZX_COMPANY_EXCHANGE, ZX_MARKET_INDEX } from "./constants";
import useDebouncedState from "../utils/useDebouncedState";
import LoadingSpinner from "../utils/LoadingSpinner";
import { ONE_SECOND } from "../utils/constants";
import useHasFeatureAccess from "../company/hooks/useHasFeatureAccess";
import RoundedSquareIcon from "../hdYuka/RoundedSquareIcon";

const StyledCardContent = styled.div`
  width: 600px;
  height: 400px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const StyledSearchContent = styled.div`
  overflow: auto;
  margin: 0 -16px;
`;

const StyledPlaceholderText = styled.div`
  ${body1}
  ${FontColors.theme50}
  text-align: center;
  margin-top: 160px;
`;

const ThumbnailContainer = styled.div`
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledCompanyThumbnail = styled.img`
  max-width: 24px;
  max-height: 24px;
  width: auto;
  height: auto;
`;

// We sometimes manually override the exchange value to display it in the UI.
const EXCHANGE_DISPLAY_NAMES = {
  ZXI: "ZX",
};

const FETCH_NEXT_COMPANIES_PAGE_THRESHOLD = 94; // Height of two search results
const MIN_SEARCH_QUERY_LENGTH = 2;

const AddComparisonModal = (props) => {
  const [searchQuery, setSearchQuery] = useDebouncedState(null);
  const [selectedComparisonType, setSelectedComparisonType] = useState(null);
  const searchResultsRef = useRef(null);

  const [zxMarketIndexAccess] = useHasFeatureAccess(
    PRIVATE_MARKET_INDICES_FEATURE_NAME
  );

  const COMPARISON_TYPES = {
    PRIVATE_COMPANIES: "Private Companies",
    PUBLIC_SYMBOLS: "Public Symbols",
    ...(zxMarketIndexAccess ? { ZX_MARKET_INDICES: "ZX Market Indices" } : {}),
  };

  // search all companies and symbols
  const allSecuritySearchQuery = useInfiniteFetch(
    API_ENDPOINTS.COMPANY_AND_SECURITY_SEARCH(),
    {
      "filter[search]": searchQuery || undefined,
      security_type: selectedComparisonType || undefined,
      removed: false,
    },
    {
      keepPreviousData: true,
    }
  );

  const onScrollDownFetchNext = useCallback(
    (distanceFromBottom) => {
      if (
        distanceFromBottom < FETCH_NEXT_COMPANIES_PAGE_THRESHOLD &&
        allSecuritySearchQuery.hasNextPage &&
        !allSecuritySearchQuery.isFetchingNextPage
      ) {
        allSecuritySearchQuery
          .fetchNextPage()
          .then((response) => response.data);
      }
    },
    [allSecuritySearchQuery]
  );

  // We need to debounce the scroll handler so we don't send duplicate API requests.
  const debouncedOnScrollDownFetchNext = useMemo(
    () =>
      _.debounce(onScrollDownFetchNext, ONE_SECOND, {
        leading: true,
        trailing: false,
      }),
    [onScrollDownFetchNext]
  );

  useScrollable({
    scrollRef: searchResultsRef,
    onScrollUp: _.noop,
    onScrollDown: debouncedOnScrollDownFetchNext,
  });

  useEffect(() => {
    if (searchQuery?.length >= MIN_SEARCH_QUERY_LENGTH) {
      allSecuritySearchQuery.refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery, selectedComparisonType]);

  const changeHandler = useCallback(
    (event) => {
      setSearchQuery.cancel();
      setSearchQuery(event.target.value);
    },
    [setSearchQuery]
  );

  const securities = allSecuritySearchQuery.isSuccess
    ? allSecuritySearchQuery.cleanedData.data
    : [];

  const getAvatar = useCallback((security) => {
    if (security.main_picture) {
      if (security.exchange === ZX_COMPANY_EXCHANGE) {
        return <Thumbnail src={security.main_picture} size="24px" />;
      }
      // We need a special 24x24 container since some logos are taller than they are wide.
      // In those cases, we want to center the logo within the 24x24 box. We're shipping a 1-off
      // for this use case.
      return (
        <ThumbnailContainer>
          <StyledCompanyThumbnail src={security.main_picture} />
        </ThumbnailContainer>
      );
    }

    return (
      <RoundedSquareIcon
        icon={
          security.exchange === ZX_MARKET_INDEX ? LineChartUpIcon : BuildingIcon
        }
      />
    );
  }, []);

  return (
    <Modal onClose={() => props.setModal(null)} title="Add New Comparison">
      <StyledCardContent>
        <Input
          placeholder="Search"
          leadingIcon={SearchIcon}
          onChange={changeHandler}
          autoFocus // eslint-disable-line jsx-a11y/no-autofocus
        />
        <>
          <div>
            <FilterChip
              text="All"
              selected={selectedComparisonType === null}
              onClick={() => setSelectedComparisonType(null)}
            />
            {Object.values(COMPARISON_TYPES).map((label) => (
              <FilterChip
                key={label}
                text={label}
                selected={selectedComparisonType === label}
                onClick={() => setSelectedComparisonType(label)}
              />
            ))}
          </div>
        </>
        {securities.length > 0 ? (
          <StyledSearchContent>
            <List ref={searchResultsRef} divider>
              {securities.map((security) => (
                <ListItem
                  avatar={getAvatar(security)}
                  key={security.apiId}
                  onClick={() => props.onSelectComparison(security)}
                  text={security.symbol}
                  trailingContent={
                    <StyledCaption2>{`${security.name} | ${
                      EXCHANGE_DISPLAY_NAMES[security.exchange] ||
                      security.exchange
                    }`}</StyledCaption2>
                  }
                />
              ))}
              {allSecuritySearchQuery.isFetchingNextPage && (
                <LoadingSpinner absolute={false} />
              )}
            </List>
          </StyledSearchContent>
        ) : (
          <StyledPlaceholderText>
            {allSecuritySearchQuery.isLoading && (
              <LoadingSpinner absolute={false} />
            )}
            {allSecuritySearchQuery.isSuccess
              ? "No securities found. Try adjusting your search."
              : "Start your search with private companies, public symbols, or ETFs"}
          </StyledPlaceholderText>
        )}
      </StyledCardContent>
    </Modal>
  );
};

AddComparisonModal.propTypes = {
  setModal: PropTypes.func.isRequired,
  onSelectComparison: PropTypes.func.isRequired,
};

export default AddComparisonModal;
