import styled, { css } from "styled-components";

import { PrimaryColorPalette, ColorPalette } from "../../StylingConstants";
import { body1, caption2, FontColors } from "../../Typography";
import Menu from "../../Layout/Menu";
import { InputChip } from "../../Button/Chip";

const SIZE_SMALL = css`
  ${caption2}
  line-height: 16px;
`;
const SIZE_REGULAR = css`
  ${body1}
  line-height: 18px;
`;

const StyledInput = styled.input`
  ${FontColors.theme80}

  &:disabled {
    ${FontColors.theme30}
  }

  font-family: "Google Sans", "Roboto", sans-serif;
  width: 100%;

  ${props =>
    props.readOnly
      ? `
    cursor: pointer;
  `
      : ""}

  ::placeholder,
  ::-ms-input-placeholder,
  &-ms-input-placeholder {
    color: ${ColorPalette.white30};
  }

  // Wipe defaults for everything
  padding: 0;
  border: 0;
  background: none;
  height: unset;
  min-width: 1px;
  outline: none;

  &:focus {
    outline: none;
  }
  &[required] {
    box-shadow: none;
  }

  &:hover,
  &:active {
    background: none;
  }
`;

const StyledTextArea = styled(StyledInput).attrs({ as: "textarea" })`
  min-height: ${props => (props.$height ? props.$height : "120px")};
  box-sizing: border-box;
  padding: 11px 0;
  resize: none;
`;

const StyledInputWrapper = styled.div`
  cursor: text;
  display: flex;
  gap: 12px;
  align-items: center;
  border: 1px solid
    ${props => (props.$error ? props.theme.colors.red : PrimaryColorPalette.white15)};
  border-radius: 8px;
  background-color: ${PrimaryColorPalette.black30};
  box-sizing: border-box; // needed to adjust with padding
  min-height: ${props => (props.$size === SIZE_SMALL ? "32px" : "40px")};
  height: ${props => (props.$size === SIZE_SMALL ? "32px" : "40px")};
  padding: 0 11px;

  > svg:first-child {
    flex-shrink: 0;
  }

  > svg:last-child {
    flex-shrink: 0;
    padding-left: 12px;
  }

  input,
  textarea {
    ${props => props.$size || SIZE_REGULAR}
  }

  ${props =>
    !props.$error && !props.$disabled
      ? `
    &:hover {
      border-color: ${PrimaryColorPalette.white30};
    }
  `
      : ""}
  // Menu style here applies to Select and Autocomplete
  ${Menu}:focus-within + &, &:focus-within {
    border-color: ${props => props.theme.colors.branding400};
    outline: none;

    // Not passed in HTML because focus isn't a react state 90% of the time
    svg:first-child path {
      fill: ${PrimaryColorPalette.white50};
    }
  }
  ${props =>
    props.$disabled
      ? `
    background-color: ${ColorPalette.white05};
    border-color: ${ColorPalette.white05};
    color: ${PrimaryColorPalette.white15};
  `
      : ""}

  ${InputChip} + ${InputChip} {
    margin-left: 0;
  }
`;

export { StyledInput, StyledTextArea, StyledInputWrapper, SIZE_SMALL, SIZE_REGULAR };
