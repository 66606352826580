/* eslint-disable no-magic-numbers, import/prefer-default-export */
// The variables here correspond to those in variables.less and colors.less
const PrimaryColorPalette = {
  blue900: "#ccfafd",
  blue800: "#99f5fb",
  blue700: "#66f0fa",
  blue600: "#33ebf8",
  blue500: "#00e6f6",
  blue400: "#05bdca",
  blue300: "#0a949e",
  blue200: "#106c72",
  blue100: "#154346",
  white100: "rgba(255, 255, 255, 1)",
  white80: "rgba(255, 255, 255, .8)",
  white50: "rgba(255, 255, 255, .5)",
  white30: "rgba(255, 255, 255, .3)",
  white15: "rgba(255, 255, 255, .15)",
  black100: "rgba(0, 0, 0, 1)",
  black80: "rgba(0, 0, 0, .8)",
  black50: "rgba(0, 0, 0, .5)",
  black30: "rgba(0, 0, 0, .3)",
  black15: "rgba(0, 0, 0, .15)",
  sell: "#ff6262",
  buy: "#00f68b",
  alert: "#fad656",
};
PrimaryColorPalette.accent = PrimaryColorPalette.blue500;

const OldColorPalette = {
  // This is for setting colors in SVGs, and should mirror the colors in colors.less
  // green
  greenLight: "#00f4b6",
  greenFaint: "#ccfcf0",
  greenNormal: "#1eb980",
  greenDark: "#00804d",
  greenOff: "#005f56",
  // red
  redFaint: "#ffe5e3",
  redLight: "#ffd5ce",
  redNormal: "#ff7d77",
  redDark: "#ff5b50",
  redOff: "#a5473d",
  // violet
  violetLight: "#c5cae9",
  violetNormal: "#7986cb",
  violetDark: "#3f51b5",
  // yellow
  yellowLight: "#ffa900",
  // white
  fullWhite: PrimaryColorPalette.white100,
  darkWhite: PrimaryColorPalette.white80,
  lightWhite: PrimaryColorPalette.white50,
  faintWhite: PrimaryColorPalette.white30,
  minWhite: PrimaryColorPalette.white15,
  white10: "rgba(255, 255, 255, 0.1)", // Active states
  white05: "rgba(255, 255, 255, 0.05)",

  // black
  fullBlack: PrimaryColorPalette.black100,
  darkBlack: PrimaryColorPalette.black80,
  lightBlack: PrimaryColorPalette.black50,
  faintBlack: PrimaryColorPalette.black30,
  minBlack: PrimaryColorPalette.black15,
  black10: "rgba(0, 0, 0, 0.1)",
  black05: "rgba(0, 0, 0, 0.05)", // darken the background to create layers
  // solid greyscale
  lightGray: "#6b6b6b",
  // elevation
  elevation0: "#1b1b1b",
  elevation1: "#242424",
  elevation2: "#2b2b2b",
  elevation3: "#3b3b3b",
  elevation4: "#4b4b4b",
  elevation5: "#5b5b5b",
  // Dark background colors for the page, panels, and other layered components
};

// Remapping of old colors to new ones where they differ
const RemappedColorPalette = {
  yellowLight: PrimaryColorPalette.alert,
  redDark: PrimaryColorPalette.sell,
  greenNormal: PrimaryColorPalette.buy,
};

// Colors internal to yuka
const YukaColorPalette = {
  // elevation
  surface0: "#0a0a0a",
  surface1: "#1a1a1a",
  surface2: "#2a2a2a",
  surface3: "#3a3a3a",
  surface4: "#4a4a4a",
  surface5: "#5a5a5a",
  hoverTransparent: "rgba(255, 255, 255, 0.05)",
  activeTransparent: "rgba(255, 255, 255, 0.07)",
  avatarGreen: "#05ca9b",
  avatarDarkBlue: "#3c6fbc",
  avatarPurple: "#8e5cce",
  avatarPink: "#d87cc9",
  avatarOrange: "#e96e6e",
};

const ColorPalette = {
  ...OldColorPalette,
  ...RemappedColorPalette,
  ...PrimaryColorPalette,
};

// dark table colors TODO possibly remove this?
const TableColors = {
  border: "#4b4b4b",
  header: "#4b4b4b",
  headerText: "rgba(255, 255, 255, .5)",
  headerActive: "#6b6b6b",
  headerHover: "#555555",
  row: "#3b3b3b",
  rowText: "rgba(255, 255, 255, .5)",
  rowActive: "#555555",
  rowActiveText: "rgba(255, 255, 255, 1)",
  rowHover: "#444444",
  rowSelected: "#1dca7f",
  rowSelectedText: "rgba(255, 255, 255, 1)",
};

// Used in Live Tickets sections in Activity Tab. Most are one-off colors.
const LiveTicketChartColors = [
  ColorPalette.violetNormal,
  "#c885c5",
  "#fb8ba6",
  "#ffa581",
  "#f9cb6f",
  ColorPalette.lightWhite,
];

// Z-INDEXES
// ---------
// relevant context from 3rd party libraries:
// .selectize-dropdown: 10  <- low but it is okay because selectize is nested in the DOM
// #nprogress .bar/.spinner: 1031
// .qtip: ~ 15000  <- it is set inline by the library to approximate values
const zIndexVisible = 1; // when you just need an element to have ANY value
const zIndexExtraVisible = zIndexVisible + 1; // when it needs to be above that

const zIndexModal = 100;
const zIndexModalVisible = zIndexModal + 1;

const Z_INDEXES = {
  zIndexVisible,
  zIndexExtraVisible,
  zIndexModal,
  zIndexModalVisible,
};

// Breakpoints for responsive sizing
const mobileScreen = 420;
const smallScreen = 600;
const mediumScreen = 992;
const largeScreen = 1200;
const extraLargeScreen = 1440;

// Media screen settings
const smallAndUp = `only screen and (min-width : ${mobileScreen + 1}px)`;
const mediumAndUp = `only screen and (min-width : ${smallScreen + 1}px)`;
const largeAndUp = `only screen and (min-width : ${mediumScreen + 1}px)`;
const extraLargeAndUp = `only screen and (min-width : ${largeScreen + 1}px)`;

const mobileAndDown = `only screen and (max-width : ${mobileScreen}px)`;
const smallAndDown = `only screen and (max-width : ${smallScreen}px)`;
const mediumAndDown = `only screen and (max-width : ${mediumScreen}px)`;
const largeAndDown = `only screen and (max-width : ${largeScreen}px)`;
const extraLargeAndDown = `only screen and (max-width : ${extraLargeScreen}px)`;

const MEDIA_QUERIES = {
  smallAndUp,
  mediumAndUp,
  largeAndUp,
  extraLargeAndUp,
  mobileAndDown,
  smallAndDown,
  mediumAndDown,
  largeAndDown,
  extraLargeAndDown,
};

const Orientations = {
  VERTICAL: "vertical",
  HORIZONTAL: "horizontal",
};

const SelectAnchorTypes = {
  TEXT_BOX: "text_box",
  CHIP: "chip",
};

const DEFAULT_COLOR_THEME = {
  branding900: ColorPalette.blue900,
  branding800: ColorPalette.blue800,
  branding700: ColorPalette.blue700,
  branding600: ColorPalette.blue600,
  branding500: ColorPalette.blue500,
  branding400: ColorPalette.blue400,
  branding300: ColorPalette.blue300,
  branding200: ColorPalette.blue200,
  branding100: ColorPalette.blue100,
  red: ColorPalette.sell,
  redHover: "#ff7272",
  redActive: "#ff7a7a",
  green: ColorPalette.buy,
  greenHover: "#33f8a2",
  greenActive: "#66fab9",
  yellow: ColorPalette.alert,
};

export {
  YukaColorPalette,
  PrimaryColorPalette,
  ColorPalette,
  LiveTicketChartColors,
  TableColors,
  Z_INDEXES,
  MEDIA_QUERIES,
  DEFAULT_COLOR_THEME,
  Orientations,
  SelectAnchorTypes,
};
