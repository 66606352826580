import { mpTrack } from "./MixpanelUtils";

import { KEY_ARROW_UP } from "../useHotkeys";

const MixpanelEvents = {
  /* Main Navigation */
  viewCompanyList: () => {
    mpTrack("view company list", {
      "event version": 1,
    });
  },
  viewMarketActivity: () => {
    mpTrack("view market activity", {
      "event version": 1,
    });
  },
  viewInsights: () => {
    mpTrack("view insights", {
      "event version": 1,
    });
  },
  viewSuperChart: () => {
    mpTrack("view super chart", {
      "event version": 1,
    });
  },
  viewAccountSettings: () => {
    mpTrack("view account settings", {
      "event version": 1,
    });
  },

  /* Company List */
  filterCompanyList: (filters) => {
    mpTrack("filter company list", {
      ...filters,
      "event version": 1,
    });
  },

  /* Company Profile */
  viewCompanyProfile: (companyName) => {
    mpTrack("view company profile", {
      "event version": 1,
      company: companyName,
    });
  },
  viewCompanyPriceAndVolumeGraph: (companyName) => {
    mpTrack("view company price and volume graph", {
      "event version": 1,
      company: companyName,
    });
  },
  viewCompanyBidAskPriceGraph: (companyName) => {
    mpTrack("view company bid-ask price graph", {
      "event version": 1,
      company: companyName,
    });
  },
  viewCompanyReportedMarksGraph: (companyName) => {
    mpTrack("view company reported marks graph", {
      "event version": 1,
      company: companyName,
    });
  },
  viewCompanyBidAskRatioGraph: (companyName) => {
    mpTrack("view company bid-ask ratio graph", {
      "event version": 1,
      company: companyName,
    });
  },
  viewCompanyRelativeActivityGraph: (companyName) => {
    mpTrack("view company relative activity graph", {
      "event version": 1,
      company: companyName,
    });
  },
  downloadXlsSpreadsheet: (source) => {
    mpTrack("download xls spreadsheet", {
      source,
      "event version": 2,
    });
  },
  viewCompanyNews: (companyName) => {
    mpTrack("view company news", {
      "event version": 1,
      company: companyName,
    });
  },
  addCompanyToWatchlist: (source, companyName) => {
    mpTrack("add company to watchlist", {
      "event version": 1,
      source,
      company: companyName,
    });
  },
  removeCompanyFromWatchlist: (source, companyName) => {
    mpTrack("remove company from watchlist", {
      "event version": 1,
      source,
      company: companyName,
    });
  },
  navigateToCompanyWebsite: (companyName, url) => {
    mpTrack("navigate to company website", {
      "event version": 1,
      url,
      company: companyName,
    });
  },
  viewCompanyCertificatesOfIncorporationList: (companyName) => {
    mpTrack("view company certificates of incorporation list", {
      "event version": 1,
      company: companyName,
    });
  },
  viewCompanyCertificateOfIncorporation: (companyName, coiDate) => {
    mpTrack("view company certificate of incorporation", {
      "event version": 1,
      company: companyName,
      date: coiDate,
    });
  },
  /* Enterprise Value Calculator */
  toggleCustomEnterpriseValuationCalculator: (companyName, isOpen) => {
    mpTrack("toggle postmoney valuation calculator", {
      "event version": 1,
      company: companyName,
      open: isOpen,
    });
  },
  modifyCustomPricePerShareField: (companyName, oldValue, newValue) => {
    mpTrack("modify custom price per share field", {
      "event version": 1,
      company: companyName,
      "old value": oldValue === undefined ? "" : oldValue,
      "new value": newValue,
    });
  },
  modifyCustomValuationField: (companyName, oldValue, newValue) => {
    mpTrack("modify custom valuation field", {
      "event version": 1,
      company: companyName,
      "old value": oldValue === undefined ? "" : oldValue,
      "new value": newValue,
    });
  },
  /* Valuation Calculator */
  viewCompanyValuation: (companyName) => {
    mpTrack("view company valuation", {
      "event version": 1,
      company: companyName,
    });
  },
  openCustomValuationModal: (companyName, isCreate) => {
    mpTrack("open custom valuation modal", {
      "event version": 1,
      create: isCreate,
      company: companyName,
    });
  },
  closeValuationModal: (companyName, isCreate) => {
    mpTrack("close custom valuation modal", {
      "event version": 1,
      create: isCreate,
      company: companyName,
    });
  },
  clickValuationDate: (companyName) => {
    mpTrack("click valuation date", {
      "event version": 1,
      company: companyName,
    });
  },
  modifyValuationDate: (companyName, oldValue, newValue) => {
    mpTrack("modify custom valuation date", {
      "event version": 1,
      company: companyName,
      "old value": oldValue === undefined ? "" : oldValue,
      "new value": newValue,
    });
  },
  modifyPreviousValuation: (companyName, oldValue, newValue) => {
    mpTrack("modify custom valuation previous valuation value", {
      "event version": 1,
      company: companyName,
      "old value": oldValue === undefined ? "" : oldValue,
      "new value": newValue,
    });
  },
  modifyPreviousValuationDate: (companyName, oldValue, newValue) => {
    mpTrack("modify custom valuation previous valuation date", {
      "event version": 1,
      company: companyName,
      "old value": oldValue === undefined ? "" : oldValue,
      "new value": newValue,
    });
  },
  modifyValuationWeightField: (
    companyName,
    fieldName,
    fieldType,
    oldValue,
    newValue
  ) => {
    mpTrack("modify valuation weight field", {
      "event version": 1,
      company: companyName,
      field: fieldName,
      fieldType: fieldType,
      "old value": oldValue,
      "new value": newValue,
    });
  },
  selectValuationComparison: (companyName, comparison, comparisonType) => {
    mpTrack("select valuation comparison", {
      "event version": 1,
      company: companyName,
      comparison: comparison,
      comparisonType,
    });
  },
  clearValuationComparison: (companyName, comparisonType) => {
    mpTrack("clear valuation comparison", {
      "event version": 1,
      company: companyName,
      comparisonType,
    });
  },
  createCustomValuation: (companyName, valuationDate) => {
    mpTrack("create custom valuation", {
      "event version": 1,
      company: companyName,
      date: valuationDate,
    });
  },
  deleteCustomValuation: (companyName, valuationDate) => {
    mpTrack("delete custom valuation", {
      "event version": 1,
      company: companyName,
      date: valuationDate,
    });
  },
  modifyDefaultComponentValue: (
    companyName,
    fieldName,
    fieldType,
    defaultValue,
    newValue
  ) => {
    mpTrack("modify default component value", {
      "event version": 1,
      company: companyName,
      field: fieldName,
      fieldType,
      defaultValue,
      newValue,
    });
  },
  restoreDefaultComponentValue: (companyName, fieldName, fieldType) => {
    mpTrack("restore default component value", {
      "event version": 1,
      company: companyName,
      field: fieldName,
      fieldType,
    });
  },
  addCustomCriterion: (companyName) => {
    mpTrack("add custom criterion", {
      "event version": 1,
      company: companyName,
    });
  },
  removeCustomCriterion: (companyName, criterionName) => {
    mpTrack("remove custom criterion", {
      "event version": 1,
      company: companyName,
      field: criterionName,
    });
  },
  modifyCustomCriterionName: (companyName, oldName, newName) => {
    mpTrack("modify custom criterion name", {
      "event version": 1,
      company: companyName,
      "old name": oldName === undefined ? "" : oldName,
      "new name": newName,
    });
  },
  modifyCustomCriterionValue: (
    companyName,
    criterionName,
    oldValue,
    newValue
  ) => {
    mpTrack("modify custom criterion value", {
      "event version": 1,
      company: companyName,
      field: criterionName,
      "old value": oldValue === undefined ? "" : oldValue,
      "new value": newValue,
    });
  },

  /* Market Activity */
  heatMapFilterSector: (sector) => {
    mpTrack("heat map filter sector", {
      "event version": 1,
      sector: sector,
    });
  },

  /* Super Chart */
  openKeyCompanySearchDropdown: () => {
    mpTrack("open key company search dropdown", {
      "event version": 1,
    });
  },
  superChartSelectCompany: (companyName) => {
    mpTrack("super chart select company", {
      "event version": 1,
      company: companyName,
    });
  },
  superChartAddComparison: (companyName, symbol) => {
    mpTrack("super chart add comparison", {
      "event version": 1,
      company: companyName,
      symbol: symbol,
    });
  },
  superChartAddIndicator: (companyName, indicator) => {
    mpTrack("super chart add indicator", {
      "event version": 1,
      company: companyName,
      indicator: indicator,
    });
  },
  superChartFilterKeyCompanySearch: (searchQuery, filters) => {
    mpTrack("super chart filter key company search", {
      "event version": 1,
      search: searchQuery,
      ...filters,
    });
  },

  /* Global search */
  toggleGlobalSearch: (hotkeyUsed, isOpen) => {
    mpTrack("toggle global search", {
      "event version": 1,
      hotkey: hotkeyUsed,
      open: isOpen,
    });
  },
  navigateGlobalSearchDropdown: (direction) => {
    mpTrack("navigate global search dropdown", {
      "event version": 1,
      direction: direction === KEY_ARROW_UP ? "up" : "down",
    });
  },
  globalSearchSelectCompany: (hotkeyUsed, searchQuery, companyName) => {
    mpTrack("global search select company", {
      "event version": 1,
      hotkey: hotkeyUsed,
      search: searchQuery,
      company: companyName,
    });
  },
  globalSearchQueryCompany: (searchQuery) => {
    mpTrack("global search query company", {
      "event version": 1,
      search: searchQuery,
    });
  },

  /* Portfolios */
  viewPortfolios: () => {
    mpTrack("view portfolios", {
      "event version": 1,
    });
  },
  viewPortfolioProfile: (id, name) => {
    mpTrack("view portfolio profile", {
      "event version": 1,
      id,
      name,
    });
  },
  createPortfolio: (id, name) => {
    mpTrack("create portfolio", {
      "event version": 1,
      id,
      name,
    });
  },
  renamePortfolio: (id, oldName, name) => {
    mpTrack("rename portfolio", {
      "event version": 1,
      id,
      name,
      "old name": oldName,
    });
  },
  deletePortfolio: (id, name) => {
    mpTrack("delete portfolio", {
      "event version": 1,
      id,
      name,
    });
  },
  addCompanyToPortfolio: (source, portfolioId, companyName) => {
    mpTrack("add company to portfolio", {
      "event version": 1,
      source,
      portfolio: portfolioId,
      company: companyName,
    });
  },
  removeCompanyFromPortfolio: (portfolioId, companyName) => {
    mpTrack("remove company from portfolio", {
      "event version": 1,
      portfolio: portfolioId,
      company: companyName,
    });
  },
  exportPortfolio: (id, name) => {
    mpTrack("export portfolio", {
      "event version": 1,
      id,
      name,
    });
  },

  /* Insights */
  openMonthlyMoversArticle: (articleTitle) => {
    mpTrack("open monthly movers article", {
      "event version": 1,
      article: articleTitle,
    });
  },
  openReportOrPressRelease: (articleTitle) => {
    mpTrack("open report or press release", {
      "event version": 1,
      article: articleTitle,
    });
  },

  /* Miscellaneous */
  clickGlobalNavigationPage: (option) => {
    mpTrack("click global navigation page", {
      "event version": 1,
      option,
    });
  },
  useHotkey: (hotkey) => {
    mpTrack("use hotkey", {
      "event version": 1,
      hotkey,
    });
  },
  openTooltip: (tooltipName) => {
    mpTrack("open helper tooltip", {
      "event version": 1,
      tooltip: tooltipName,
    });
  },
  openReportABug: () => {
    mpTrack("open report a bug", {
      "event version": 1,
    });
  },
  openTermsOfUse: () => {
    mpTrack("open terms of use", {
      "event version": 1,
    });
  },
  openPrivacyPolicy: () => {
    mpTrack("open privacy policy", {
      "event version": 1,
    });
  },
  openNDA: () => {
    mpTrack("open NDA", {
      "event version": 1,
    });
  },
};

export default MixpanelEvents;
