import { DateTime } from "luxon";
import PropTypes from "prop-types";
import { useEffect } from "react";

const CompanyLineGraphTooltip = ({ setHoveredDate, payload }) => {
  // Props are injected by recharts.
  useEffect(() => {
    setHoveredDate(payload[0]?.payload?.time);
  }, [setHoveredDate, payload]);
  if (!payload[0]?.payload?.time) {
    return null;
  }

  const formattedDate = DateTime.fromISO(payload[0]?.payload?.time).toFormat(
    "dd MMM yy"
  );
  const [day, month, year] = formattedDate.split(" ");
  // Inject the apostrophe to the year.
  return `${day} ${month} '${year}`;
};

CompanyLineGraphTooltip.propTypes = {
  setHoveredDate: PropTypes.func,
  payload: PropTypes.array,
};

export default CompanyLineGraphTooltip;
