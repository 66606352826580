import { useParams } from "react-router-dom";

import useCompanyFetch from "../../company/utils/useCompanyFetch";

/*
 * Pulls the company ID out of the URL and fetches the company data. Useful for determining
 * if the URL provided ID actually matches a company.
 */
const useCompany = () => {
  const { id: companyId } = useParams();

  const companyQuery = useCompanyFetch(companyId);

  if (companyQuery.isLoading) {
    return [null, true];
  }

  if (!companyQuery.isSuccess) {
    return [null, false];
  }
  return [companyQuery.cleanedData.data, false];
};

export default useCompany;
