import PropTypes from "prop-types";
import { useMemo, useState } from "react";
import {
  Modal,
  Fonts,
  ModalStyles,
  ButtonStyles,
  Button,
  Table,
  DateCell,
  CurrencyCell,
  YukaThemeProvider,
  ColorPalette,
  YukaColorPalette,
  HyperLink,
  LinkStyles,
} from "yuka";

import { ValuationCalculatorModal } from "../../company/ValuationCalculator";
import {
  StyledModalButtonRow,
  StyledModalContent,
} from "../../Portfolios/StyledComponents";
import MixpanelEvents from "../../utils/mixpanel/MixpanelEvents";
import { useCompany } from "../hooks";

const PastValuationsModal = ({ onClose, valuations }) => {
  const [company, companyLoading] = useCompany();
  const [viewingValuation, setViewingValuation] = useState(null);

  const columns = useMemo(
    () => [
      {
        id: "date",
        accessor: "valuation_date",
        header: "Valuation date",
        cellRenderer: DateCell,
        width: 4,
      },
      {
        id: "valuation",
        accessor: "computed_value",
        header: "Valuation",
        cellRenderer: CurrencyCell,
        width: 3,
      },
      {
        id: "viewDetails",
        align: "right",
        accessor: (valuation) => valuation,
        header: "",
        width: 2,
        cellRenderer: ({ value: valuation }) => (
          <HyperLink
            linkStyle={LinkStyles.DEFAULT}
            onClick={(event) => {
              MixpanelEvents.openCustomValuationModal(company?.name, false);
              setViewingValuation(valuation);
              event.stopPropagation();
            }}
          >
            View details
          </HyperLink>
        ),
      },
    ],
    [company?.name]
  );

  if (companyLoading) {
    return <Modal title="Loading past valuations" />;
  }

  return (
    <Modal modalStyle={ModalStyles.MINIMAL} onClose={onClose}>
      <StyledModalContent $maxHeight={500} $width={552}>
        <Fonts.Headline2theme80>
          Past valuations&nbsp;
          <Fonts.Headline2theme50>for&nbsp;</Fonts.Headline2theme50>
          {company.name}
        </Fonts.Headline2theme80>
        <YukaThemeProvider
          theme={{
            tableStyles: {
              colors: {
                header: YukaColorPalette.surface1,
                row: "transparent",
                rowHover: "rgba(255, 255, 255, 0.05)",
                rowActive: "rgba(255, 255, 255, 0.07)",
              },
              header: {
                borderTop: `1px dashed ${ColorPalette.white15}`,
                borderBottom: `1px dashed ${ColorPalette.white15}`,
              },
              cells: {
                borderBottom: `1px dashed ${ColorPalette.white15}`,
              },
            },
          }}
        >
          <Table
            usePercentageColumnWidths
            columns={columns}
            data={valuations}
          />
        </YukaThemeProvider>
      </StyledModalContent>
      <StyledModalButtonRow>
        <Button buttonStyle={ButtonStyles.RAISED} onClick={onClose}>
          Close
        </Button>
      </StyledModalButtonRow>
      {viewingValuation && (
        <ValuationCalculatorModal
          onClose={(event, skipMixpanel = false) => {
            if (!skipMixpanel) {
              MixpanelEvents.closeValuationModal(
                company.name,
                !viewingValuation
              );
            }
            setViewingValuation(null);
          }}
          companyName={company.name}
          companyId={company.zb_id}
          valuation={viewingValuation}
          previousValuations={valuations}
        />
      )}
    </Modal>
  );
};

PastValuationsModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  valuations: PropTypes.arrayOf(
    PropTypes.shape({
      valuation_date: PropTypes.string,
      computed_value: PropTypes.number,
    })
  ).isRequired,
};

export default PastValuationsModal;
