import PropTypes from "prop-types";
import { Layer, Rectangle } from "recharts";
import { ColorPalette } from "yuka";

import { SANKEY_NODE_UNKNOWN_SECURITY } from "./constants";

import { DataverseColors } from "../hdYuka/constants";
import { percentFormat } from "../utils/displayFormatUtils";

const CompanyTransferabilitySankeyNode = (props) => {
  const { x, y, width, height, index, payload } = props;

  const depthColorMap = {
    0: DataverseColors.branding500,
    1: "#749AD3",
    2: "#9874D3",
  };

  // The unknown security is a special case, but other than that we color the nodes based on
  // depth in the chart.
  const color =
    payload.name === SANKEY_NODE_UNKNOWN_SECURITY
      ? DataverseColors.yellow
      : depthColorMap[payload.depth];

  return (
    <Layer key={`CustomNode${index}`}>
      <Rectangle
        x={x}
        y={y}
        width={width}
        height={height}
        fill={color}
        fillOpacity="1"
      />
      <text x={x} y={y - 24} fontSize="12" fill={ColorPalette.white50}>
        {payload.name}
      </text>
      <text x={x} y={y - 8} fontSize="12" fill={ColorPalette.white80}>
        {percentFormat(payload.value, 0)}
      </text>
    </Layer>
  );
};

CompanyTransferabilitySankeyNode.propTypes = {
  x: PropTypes.number,
  y: PropTypes.number,
  width: PropTypes.number,
  height: PropTypes.number,
  index: PropTypes.number,
  payload: PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.number,
    depth: PropTypes.number,
  }),
};

export default CompanyTransferabilitySankeyNode;
