import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";

import {
  ZX_INDEX_VALUE_CHANGE_FILTER,
  ROBUSTNESS_FILTER,
  PREMIUM_TO_LAST_ROUND_FILTER,
  ZX_INDEX_VALUE_CHANGE_OPTIONS,
  ROBUSTNESS_SCORE_OPTIONS,
  PREMIUM_TO_LAST_ROUND_OPTIONS,
  WATCHLIST_OPTIONS,
} from "./constants";
import FilterButton from "./FilterButton";
import SingleSelect from "./FilterSelectDropdown";

const StyledFilterContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
`;

const makeSelectOptions = (options) =>
  options.map((option) => ({
    value: option,
    label: option,
  }));

const WatchlistFilterDropdown = (props) => (
  <SingleSelect
    {...props}
    label="Watchlist"
    options={makeSelectOptions(WATCHLIST_OPTIONS)}
  />
);

const ZXIndexValueChangeFilterDropdown = (props) => (
  <SingleSelect
    {...props}
    label="ZX Index Value Week Change %"
    options={makeSelectOptions(ZX_INDEX_VALUE_CHANGE_OPTIONS)}
  />
);

const RobustnessFilterDropdown = (props) => (
  <SingleSelect
    {...props}
    label="Robustness Score (RS)"
    options={makeSelectOptions(ROBUSTNESS_SCORE_OPTIONS)}
  />
);

const PremiumToLastRoundDropdown = (props) => (
  <SingleSelect
    {...props}
    label="ZX Index Value Premium to LR Price"
    options={makeSelectOptions(PREMIUM_TO_LAST_ROUND_OPTIONS)}
  />
);

const CompanyFilters = ({ makeOnChangeFunction, filters }) => {
  return (
    <StyledFilterContainer>
      <FilterButton
        onChange={makeOnChangeFunction("watchlist")}
        value={filters.watchlist}
        dropdownComponent={WatchlistFilterDropdown}
      >
        Watchlist
      </FilterButton>
      <FilterButton
        value={filters[ROBUSTNESS_FILTER]}
        onChange={makeOnChangeFunction(ROBUSTNESS_FILTER)}
        dropdownComponent={RobustnessFilterDropdown}
      >
        Robustness Score
      </FilterButton>
      <FilterButton
        value={filters[ZX_INDEX_VALUE_CHANGE_FILTER]}
        onChange={makeOnChangeFunction(ZX_INDEX_VALUE_CHANGE_FILTER)}
        dropdownComponent={ZXIndexValueChangeFilterDropdown}
      >
        Week change %
      </FilterButton>
      <FilterButton
        value={filters[PREMIUM_TO_LAST_ROUND_FILTER]}
        onChange={makeOnChangeFunction(PREMIUM_TO_LAST_ROUND_FILTER)}
        dropdownComponent={PremiumToLastRoundDropdown}
      >
        Premium to Last Round
      </FilterButton>
    </StyledFilterContainer>
  );
};

CompanyFilters.propTypes = {
  filters: PropTypes.shape({
    watchlist: PropTypes.string,
    robustness: PropTypes.string,
    zx_index_value_change: PropTypes.string,
    last_round_price_diff: PropTypes.string,
  }).isRequired,
  makeOnChangeFunction: PropTypes.func.isRequired,
};

export default CompanyFilters;
