import PropTypes from "prop-types";
import styled from "styled-components";
import { ColorPalette, Fonts } from "yuka";

const StyledKeyContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${ColorPalette.white30};
  border-radius: 4px;
  width: 16px;
  height: 16px;
`;

const StyledHotkeyPrompt = styled(Fonts.Body1theme30).attrs({ as: "div" })`
  display: flex;
  align-items: center;
  white-space: nowrap;
  gap: 10px;
`;

const HotkeyPrompt = ({ action, hotkey }) => {
  return (
    <StyledHotkeyPrompt>
      Type <StyledKeyContainer>{hotkey}</StyledKeyContainer> to {action}
    </StyledHotkeyPrompt>
  );
};

HotkeyPrompt.propTypes = {
  action: PropTypes.string.isRequired,
  hotkey: PropTypes.string.isRequired,
};

export default HotkeyPrompt;
