import { useLayoutEffect, useMemo, useState } from "react";

/*
 * Hook for tracking dimensions of an element in a slightly different manner. The above
 * useElementDimensions has the drawback that the dimensions don't update when an element goes from
 * scrollable to not scrollable, which can cause underlying containers widths to change. This
 * hook uses ResizeObserver to track dimensions of an element, with the caveat that ResizeObserver
 * isn't supported by opera mini or KaiOS, 0.19% global usage.
 *
 * This function also doesn't accept a ref parameter, it returns a function ref, the ref.current
 * value, and the dimensions of the element, derived from `contentRect()`.
 * https://developer.mozilla.org/en-US/docs/Web/API/ResizeObserver
 *
 * When considering whether to use this function in lieu of `useElementDimensions`, follow this
 * simple flow chart:
 *
 * Am I working in Table.jsx? -> Yes -> use this function
 *                            -> No  -> probably use useElementDimensions
 *
 * Implementation is *mostly* stolen from
 * react-use https://github.com/streamich/react-use/blob/master/src/useMeasure.ts
 */

const useElementContentRect = () => {
  const [element, ref] = useState(null);
  const [rect, setRect] = useState({ width: 0 });

  const observer = useMemo(
    () =>
      // eslint-disable-next-line compat/compat
      new ResizeObserver(entries => {
        if (entries[0]) {
          const { x, y, width, height, top, left, bottom, right } = entries[0].contentRect;
          setRect({ x, y, width, height, top, left, bottom, right });
        }
      }),
    []
  );

  useLayoutEffect(() => {
    if (!element) return;
    observer.observe(element);
    return () => {
      observer.disconnect();
    };
  }, [element, observer]);

  return [ref, element, rect];
};

export default useElementContentRect;
