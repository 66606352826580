import PropTypes from "prop-types";
import { Fonts, XCloseIcon } from "yuka";

import { LegendItemContainer, StyledCircle } from "./StyledComponents";

import { DataverseColors } from "../../hdYuka/constants";
import ToggleableIcon from "../../superchart/SuperchartLegend/ToggleableIcon";
import useChartReferencePoint from "../../superchart/SuperchartLegend/useChartReferencePoint";
import { shortMoneyFormat } from "../../utils/displayFormatUtils";

const VolumeAggregateLegendItem = ({
  isHovered,
  chartElement,
  onMouseEnter,
  onMouseLeave,
  hoveredDate,
  onRemove,
}) => {
  const { data } = chartElement;
  const referencePoint = useChartReferencePoint(hoveredDate, data, true);

  return (
    <LegendItemContainer
      $isHovered={isHovered}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <Fonts.Body1theme80>Volume - 3M AGG.</Fonts.Body1theme80>
      {referencePoint && (
        <Fonts.Body1theme80>
          Total - {shortMoneyFormat(referencePoint?.total)}M
        </Fonts.Body1theme80>
      )}
      <StyledCircle color={DataverseColors.green} />
      <Fonts.Body1theme80>
        Bid{referencePoint && ` ${shortMoneyFormat(referencePoint?.bid)}M`}
      </Fonts.Body1theme80>
      <StyledCircle color={DataverseColors.red} />
      <Fonts.Body1theme80>
        Offer{referencePoint && ` ${shortMoneyFormat(referencePoint?.offer)}M`}
      </Fonts.Body1theme80>
      {onRemove ? (
        <ToggleableIcon icon={XCloseIcon} onClick={onRemove} />
      ) : null}
    </LegendItemContainer>
  );
};

VolumeAggregateLegendItem.propTypes = {
  isHovered: PropTypes.bool,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  onRemove: PropTypes.func,
  chartElement: PropTypes.shape({
    data: PropTypes.shape({}),
  }).isRequired,
  hoveredDate: PropTypes.string,
};

export default VolumeAggregateLegendItem;
