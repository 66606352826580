import React, { useMemo, useRef, useCallback, useContext } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { Fonts, ListItem, Thumbnail } from "yuka";
import { useNavigate } from "react-router-dom";

import useInfiniteFetch from "../../api/useInfiniteFetch";
import { API_ENDPOINTS } from "../../api/constants";
import highlightText from "../utils/highlightText";
import useScrollable from "../../utils/useScrollable";
import { StyledList, StyledPlaceholderTextContainer } from "./StyledComponents";
import {
  FETCH_NEXT_COMPANIES_PAGE_THRESHOLD,
  MIN_SEARCH_QUERY_LENGTH,
} from "../constants";
import { ROUTING_PATH } from "../../routes/constants";
import ProfileContext from "../../superchart/ProfileContext";
import LoadingSpinner from "../../utils/LoadingSpinner";
import { StyledLoadingSpinnerContainer } from "../../utils/StyledComponents";
import RoundedSquareIcon from "../../hdYuka/RoundedSquareIcon";

const PortfolioList = ({ searchQuery, toggleDropdown }) => {
  const searchResultsRef = useRef(null);
  const navigate = useNavigate();
  const { setProfile } = useContext(ProfileContext);

  const showSearchResults = useMemo(
    () => searchQuery.length >= MIN_SEARCH_QUERY_LENGTH,
    [searchQuery]
  );

  const portfolioQuery = useInfiniteFetch(
    API_ENDPOINTS.SUPERCHART_PORTFOLIOS(),
    { search: showSearchResults ? searchQuery : undefined },
    { enabled: true }
  );

  const portfolios = useMemo(() => {
    return portfolioQuery.isSuccess && !portfolioQuery.isRefetching
      ? portfolioQuery.cleanedData.data
      : [];
  }, [
    portfolioQuery.isSuccess,
    portfolioQuery.isRefetching,
    portfolioQuery.cleanedData,
  ]);

  const onScrollDownFetchNext = useCallback(
    (distanceFromBottom) => {
      if (
        distanceFromBottom < FETCH_NEXT_COMPANIES_PAGE_THRESHOLD &&
        portfolioQuery.hasNextPage &&
        !portfolioQuery.isFetchingNextPage
      ) {
        portfolioQuery.fetchNextPage().then((response) => response.data);
      }
    },
    [portfolioQuery]
  );

  useScrollable({
    scrollRef: searchResultsRef,
    onScrollUp: _.noop,
    onScrollDown: onScrollDownFetchNext,
  });

  return (
    <>
      <StyledList divider ref={searchResultsRef}>
        {portfolios.map((portfolio, idx) => (
          <ListItem
            key={`portfolio_${idx}`}
            onClick={() => {
              setProfile(portfolio);
              toggleDropdown();
              return navigate(
                ROUTING_PATH.SUPERCHART(portfolio.primary?.zb_id)
              );
            }}
            text={
              showSearchResults
                ? highlightText(portfolio.primary?.name, searchQuery)
                : portfolio.primary?.name
            }
            avatar={
              portfolio.primary?.main_picture ? (
                <Thumbnail src={portfolio.primary?.main_picture} size="24px" />
              ) : (
                <RoundedSquareIcon />
              )
            }
            trailingContent={
              <Fonts.Caption2theme50>
                {showSearchResults
                  ? highlightText(portfolio.name, searchQuery)
                  : portfolio.name}
              </Fonts.Caption2theme50>
            }
          />
        ))}
        {portfolioQuery.isFetching && (
          <StyledLoadingSpinnerContainer>
            <LoadingSpinner absolute={false} />
          </StyledLoadingSpinnerContainer>
        )}
      </StyledList>
      {portfolios.length === 0 && !portfolioQuery.isFetching && (
        <StyledPlaceholderTextContainer>
          {showSearchResults
            ? `No saved profile matches "${searchQuery}"`
            : "No saved profiles"}
        </StyledPlaceholderTextContainer>
      )}
    </>
  );
};

PortfolioList.propTypes = {
  searchQuery: PropTypes.string.isRequired,
  toggleDropdown: PropTypes.func.isRequired,
};

export default PortfolioList;
