import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import React, { useEffect, useMemo, useState } from "react";
import _ from "lodash";

import {
  Card,
  ChevronDownIcon,
  Fonts,
  ChevronRightIcon,
  CardStyles,
} from "yuka";

import { Field, useDropdown } from "../hdYuka";
import useInfiniteFetch from "../api/useInfiniteFetch";
import { API_ENDPOINTS } from "../api/constants";
import { shortMoneyFormat } from "../utils/displayFormatUtils";
import useFetch from "../api/useFetch";
import roundDecimal from "../utils/roundDecimal";
import { companyProfileBreakpoint, ERROR_MESSAGE } from "./constants";
import useWindowDimensions from "../utils/useWindowDimensions";
import LoadingSpinner from "../utils/LoadingSpinner";
import { HALF_SECOND, ONE_BILLION } from "../utils/constants";
import MixpanelEvents from "../utils/mixpanel/MixpanelEvents";
import useCompanyFetch from "./utils/useCompanyFetch";
import useDebouncedState from "../utils/useDebouncedState";
import AlternativeCardState from "./AlternativeCardState";
import {
  StyledCardHeaderTooltipText,
  StyledTooltipTextHeader,
} from "../utils/StyledComponents";

const WINDOW_SIZE_MEDIA_QUERY = `(max-width : ${companyProfileBreakpoint}px)`;

const StyledCardContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media ${`only screen and ${WINDOW_SIZE_MEDIA_QUERY}`} {
    flex-direction: row;
    justify-content: space-between;
  }
`;

const StyledInputPrompt = styled(Fonts.Body1theme30)`
  white-space: nowrap;
`;

const StyledValuationCard = styled(Card)`
  overflow: hidden;
  transition: max-height 0.3s ease-in-out;
  padding: 0;
  ${({ $expanded }) =>
    $expanded
      ? css`
          max-height: 385px;
        `
      : css`
          max-height: 53px;
        `}
  @media ${`only screen and ${WINDOW_SIZE_MEDIA_QUERY}`} {
    width: 50%;
    max-height: 385px;
  }
`;

const StyledFlexDiv = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledInnerCardHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  padding: 16px;

  @media ${`only screen and ${WINDOW_SIZE_MEDIA_QUERY}`} {
    cursor: unset;
  }
`;

const StyledInnerCardBody = styled.div`
  margin: 0 16px 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const CARD_TITLE = "Post-Money Calculator";

const numberFormatter = (value) => Number(roundDecimal(value, 6));

const debouncedModifyPricePerShare = _.debounce(
  (companyName, previousValue, newValue) => {
    MixpanelEvents.modifyCustomPricePerShareField(
      companyName,
      previousValue,
      newValue
    );
  },
  HALF_SECOND,
  { leading: false, trailing: true }
);

const debouncedModifyImpliedValue = _.debounce(
  (companyName, previousValue, newValue) => {
    MixpanelEvents.modifyCustomValuationField(
      companyName,
      previousValue,
      newValue
    );
  },
  HALF_SECOND,
  { leading: false, trailing: true }
);

const PostMoneyCalculator = ({ companyId }) => {
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [expanded, setExpanded] = useState(false);
  const [pricePerShare, setPricePerShare] = useState(null);
  const [impliedValue, setImpliedValue] = useState(null);

  // Two fields used for accurate mixpanel reporting.
  const [
    previousPricePerShare,
    setPreviousPricePerShare,
    _setPreviousPricePerShare,
  ] = useDebouncedState(null);
  const [
    previousImpliedValue,
    setPreviousImpliedValue,
    _setPreviousImpliedValue,
  ] = useDebouncedState(null);

  const { width: windowWidth } = useWindowDimensions();

  const companyLatestOrderFlowQuery = useFetch(
    API_ENDPOINTS.COMPANY_LATEST_ORDER_FLOW(),
    {
      id: companyId,
      time_frame: "weekly",
    }
  );
  // We'll fetch the company so we can do mixpanel tracking by name.
  const company = useCompanyFetch(companyId);
  const companyName = company.cleanedData?.data?.name;
  // We are only fetching the latest HDFundingRound since we can only compute an
  // "outstanding shares" value if the most recent one has a postmoney valuation and a price per
  // share. Otherwise, we run the risk of offering a wildly out of date estimate.
  const hdFundingRoundsReq = useInfiniteFetch(
    API_ENDPOINTS.HD_FUNDING_ROUNDS(),
    {
      exclude_sub_rounds: true,
      company: companyId,
      "page[size]": 1,
    }
  );

  const mostRecentFundingRound = useMemo(() => {
    // Finds the most recent funding round with a postmoney valuation + a pps
    if (!hdFundingRoundsReq.cleanedData?.data) return null;
    const fundingRound =
      hdFundingRoundsReq.cleanedData?.data.length > 0
        ? hdFundingRoundsReq.cleanedData?.data[0]
        : null;

    return fundingRound &&
      fundingRound.postmoney_valuation &&
      fundingRound.price_per_share
      ? fundingRound
      : null;
  }, [hdFundingRoundsReq.cleanedData]);

  const outstandingShares = useMemo(
    () =>
      mostRecentFundingRound
        ? mostRecentFundingRound.postmoney_valuation /
          mostRecentFundingRound.price_per_share
        : null,
    [mostRecentFundingRound]
  );

  const latestOrderFlow = useMemo(() => {
    if (companyLatestOrderFlowQuery.isSuccess) {
      return companyLatestOrderFlowQuery.cleanedData?.data?.[0];
    }
    return null;
  }, [
    companyLatestOrderFlowQuery.isSuccess,
    companyLatestOrderFlowQuery.cleanedData,
  ]);

  const valuationFromZXIndexValue = useMemo(() => {
    if (!outstandingShares || !latestOrderFlow?.zx_index_value) {
      return null;
    }
    return outstandingShares * latestOrderFlow.zx_index_value;
  }, [latestOrderFlow, outstandingShares]);

  useEffect(() => {
    // We must handle this logic in a useEffect to pre-load the latest funding round's values
    // because the API fetches are async and we need to set the initial values of the fields, but we
    // do not want the fields to be reset whenever they become null, so we can't just set them
    // conditionally if they're null, we need an extra variable.
    // Note that we're skipping the debounce logic for the 'previous' values since we want this
    // initial load to be snappy.
    if (isInitialLoad && latestOrderFlow && valuationFromZXIndexValue) {
      setIsInitialLoad(false);
      setPricePerShare(numberFormatter(latestOrderFlow.zx_index_value));
      _setPreviousPricePerShare(
        numberFormatter(latestOrderFlow.zx_index_value)
      );
      setImpliedValue(numberFormatter(valuationFromZXIndexValue) / ONE_BILLION);
      _setPreviousImpliedValue(
        numberFormatter(valuationFromZXIndexValue) / ONE_BILLION
      );
    }
  }, [latestOrderFlow, valuationFromZXIndexValue, isInitialLoad]);

  const disclaimerTooltipText = () => (
    <StyledCardHeaderTooltipText>
      <StyledTooltipTextHeader>Disclaimer</StyledTooltipTextHeader>
      <p>
        Share count as stated or implied in Company press release or news
        article. Price per share as per company filings. Although care has been
        taken to ensure the accuracy, completeness, and reliability of the
        information, Zanbato makes no warranty, express or implied, or assumes
        any legal liability or responsibility for the accuracy, completeness,
        reliability or usefulness of any information.
      </p>
    </StyledCardHeaderTooltipText>
  );

  const [dropdownElement, tooltipRef, toggleTooltip] = useDropdown(
    disclaimerTooltipText
  );

  const showIcon = windowWidth > companyProfileBreakpoint;

  if (companyLatestOrderFlowQuery.isLoading || hdFundingRoundsReq.isLoading) {
    return (
      <AlternativeCardState cardTitle={CARD_TITLE}>
        <LoadingSpinner absolute={false} />
      </AlternativeCardState>
    );
  }

  if (companyLatestOrderFlowQuery.isError || hdFundingRoundsReq.isError) {
    return (
      <AlternativeCardState cardTitle={CARD_TITLE}>
        {ERROR_MESSAGE}
      </AlternativeCardState>
    );
  }

  if (!outstandingShares) {
    return (
      <AlternativeCardState cardTitle={CARD_TITLE}>
        Post-money calculation not currently available for this company
      </AlternativeCardState>
    );
  }

  const onClickHeader = () => {
    MixpanelEvents.toggleCustomEnterpriseValuationCalculator(
      companyName,
      !expanded
    );
    setExpanded(!expanded);
  };

  return (
    <Card
      title={CARD_TITLE}
      linkText="Disclaimer"
      linkProps={{
        ref: tooltipRef,
        onClick: () => {
          MixpanelEvents.openTooltip("Company Profile Sacra Disclaimer");
          toggleTooltip();
        },
      }}
    >
      <StyledCardContent>
        <StyledFlexDiv>
          <Fonts.Headline3theme80>
            {shortMoneyFormat(valuationFromZXIndexValue)}{" "}
            <Fonts.Body1theme50>USD</Fonts.Body1theme50>
          </Fonts.Headline3theme80>
          <Fonts.Body1theme50>
            Based on current ZX Index value of{" "}
            <Fonts.Body1theme80>
              {shortMoneyFormat(latestOrderFlow?.zx_index_value)}
            </Fonts.Body1theme80>{" "}
            per share (Robustness Score {latestOrderFlow?.robustness})
          </Fonts.Body1theme50>
        </StyledFlexDiv>
        <StyledFlexDiv>
          <Fonts.Headline3theme80>
            {shortMoneyFormat(mostRecentFundingRound?.postmoney_valuation)}{" "}
            <Fonts.Body1theme50>USD</Fonts.Body1theme50>
          </Fonts.Headline3theme80>
          <Fonts.Body1theme50>
            Based on {mostRecentFundingRound?.event} at{" "}
            <Fonts.Body1theme80>
              {shortMoneyFormat(mostRecentFundingRound?.price_per_share)}
            </Fonts.Body1theme80>{" "}
            per share
          </Fonts.Body1theme50>
        </StyledFlexDiv>
        <StyledValuationCard cardStyle={CardStyles.BASIC} $expanded={expanded}>
          <StyledInnerCardHeader onClick={showIcon ? onClickHeader : null}>
            <Fonts.Body1theme80>Custom calculation</Fonts.Body1theme80>
            {showIcon ? (
              expanded ? (
                <ChevronDownIcon />
              ) : (
                <ChevronRightIcon />
              )
            ) : null}
          </StyledInnerCardHeader>
          <StyledInnerCardBody>
            <Field
              type="number"
              name="price_per_share"
              placeholder="Price / share"
              trailingIcon={() =>
                pricePerShare !== null ? (
                  <StyledInputPrompt>Price / share</StyledInputPrompt>
                ) : null
              }
              onChange={(e) => {
                if (e.target.value === "") {
                  debouncedModifyPricePerShare(
                    companyName,
                    previousPricePerShare,
                    null
                  );
                  setPreviousPricePerShare(null);
                  setPricePerShare(null);
                  setImpliedValue(null); // Cast an empty conversion to empty input.
                } else {
                  debouncedModifyPricePerShare(
                    companyName,
                    previousPricePerShare,
                    e.target.value
                  );
                  setPreviousPricePerShare(e.target.value);
                  setPricePerShare(e.target.value);
                  const impliedValuation = outstandingShares
                    ? outstandingShares * e.target.value
                    : null;
                  setImpliedValue(
                    numberFormatter(impliedValuation / ONE_BILLION)
                  );
                }
              }}
              value={pricePerShare}
            />
            <Field
              type="number"
              name="valuation"
              placeholder="Valuation (billions)"
              trailingIcon={() =>
                impliedValue !== null ? (
                  <StyledInputPrompt>Valuation (billions)</StyledInputPrompt>
                ) : null
              }
              onChange={(e) => {
                if (e.target.value === "") {
                  debouncedModifyImpliedValue(
                    companyName,
                    previousImpliedValue,
                    null
                  );
                  setPreviousImpliedValue(null);
                  setImpliedValue(null);
                  setPricePerShare(null); // Cast an empty conversion to empty input.
                } else {
                  debouncedModifyImpliedValue(
                    companyName,
                    previousImpliedValue,
                    e.target.value
                  );
                  setPreviousImpliedValue(e.target.value);
                  setImpliedValue(e.target.value);
                  const impliedPPS = outstandingShares
                    ? (e.target.value * ONE_BILLION) / outstandingShares
                    : null;
                  setPricePerShare(numberFormatter(impliedPPS));
                }
              }}
              value={impliedValue}
            />
            <Fonts.Caption2theme50>
              Calculated outputs are based on {mostRecentFundingRound?.event} at{" "}
              {shortMoneyFormat(mostRecentFundingRound?.price_per_share)}/share.
            </Fonts.Caption2theme50>
          </StyledInnerCardBody>
        </StyledValuationCard>
      </StyledCardContent>
      {dropdownElement}
    </Card>
  );
};

PostMoneyCalculator.propTypes = {
  companyId: PropTypes.string.isRequired,
};

export default PostMoneyCalculator;
