import _ from "lodash";
import React, { useState, useEffect, useCallback, useMemo } from "react";
import styled from "styled-components";
import {
  PlusIcon,
  TargetIcon,
  ColorPalette,
  Card,
  CardStyles,
  Button,
  ButtonStyles,
  body2,
  FontColors,
  StarIcon,
  Modal,
  HyperLink,
  LinkTypes,
  LinkStyles,
  Fonts,
} from "yuka";

import AddComparisonModal from "./AddComparisonModal";
import AddIndicatorModal from "./AddIndicatorModal";
import {
  THREE_MONTHS_KEY,
  SIX_MONTHS_KEY,
  ONE_YEAR_KEY,
  TWO_YEAR_KEY,
  KEY_COMPANY_SERIES_TYPE,
  ZX_COMPARISON_SERIES_TYPE,
  OLD_INDICATOR_TYPES,
  ZX_MARKET_INDEX_SERIES_COMPARISON_TYPE,
  PUBLIC_COMPARISON_SERIES_TYPE,
} from "./constants";
import { useCompanyFromURL } from "./hooks";
import ManageProfileDropdown from "./ManageProfileDropdown";
import OptionSelectList from "./OptionSelectList";
import ProfileContext from "./ProfileContext";
import SuperchartGraph from "./SuperchartGraph";
import SuperchartHeader from "./SuperchartHeader";
import {
  combinePublicAndPreIPOData,
  makeSuperchartData,
  oldMakeAllIndicatorData,
  pickRandomColor,
  mapGenericSecurityToId,
  mapGenericSecurityToSeriesType,
} from "./utils";

import {
  API_ENDPOINTS,
  PRIVATE_MARKET_INDICES_FEATURE_NAME,
  PUBLIC_COMPS_VOLUME_FEATURE_NAME,
} from "../api/constants";
import useDelete from "../api/useDelete";
import useFetch from "../api/useFetch";
import useFetches from "../api/useFetches";
import useHasFeatureAccess from "../company/hooks/useHasFeatureAccess";
import { PAGE_WIDTH } from "../constants";
import { useDropdown } from "../hdYuka";
import { ACTIONS, useDispatch } from "../routes/StateProvider";
import MixpanelEvents from "../utils/mixpanel/MixpanelEvents";
import useDocumentTitle from "../utils/useDocumentTitle";

const SuperchartContainer = styled.div`
  ${PAGE_WIDTH}
  padding-top: 48px;
  flex-grow: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding-bottom: 16px;
  align-self: center;
  justify-content: center;
`;

const StyledGraphCard = styled(Card)`
  height: 100%;
`;

const StyledKeyActionsRow = styled.div`
  display: flex;
  padding-left: 16px;
`;

const StyledCardFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid ${ColorPalette.white15};
  margin-top: -16px;
  gap: 16px;
  padding: 16px 16px 0 16px;
  > :last-child {
    text-align: right;
  }
`;

const StyledNoCompanyPlaceholder = styled.div`
  display: flex;
  height: calc(100% - 50px);
  justify-content: center;
  align-items: center;
  ${body2}
  ${FontColors.theme30}
`;

const StyledProfileHeader = styled.div`
  padding-left: 16px;
  padding-bottom: 16px;
  width: fit-content;
`;

const DEFAULT_DATE_RANGE = ONE_YEAR_KEY;

const Superchart = () => {
  useDocumentTitle("ZXData: Superchart");
  const dispatch = useDispatch();

  useEffect(() => {
    MixpanelEvents.viewSuperChart();
  }, []);

  const [publicCompsVolumeAccess] = useHasFeatureAccess(
    PUBLIC_COMPS_VOLUME_FEATURE_NAME
  );
  const [zxMarketIndexAccess] = useHasFeatureAccess(
    PRIVATE_MARKET_INDICES_FEATURE_NAME
  );

  const [priceDateRange, setPriceDateRange] = useState(DEFAULT_DATE_RANGE);
  const [compModal, setCompModal] = useState(null);
  const [indicatorModal, setIndicatorModal] = useState(null);
  const [selectedIndicators, setSelectedIndicators] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  // Store key company in a state object.
  const keyCompany = useCompanyFromURL();
  const { apiId: keyCompanyId } = keyCompany;

  // Store all comparisons in a single state object.
  // List of { id: "21312412", type: "PUBLIC_COMPARISON", symbol: "AAPL", data: [] }
  const [comparisons, setComparisons] = useState([]);

  // Store currently open saved profile in a state object.
  const [profile, setProfile] = useState(null);

  const deletePortfolioQuery = useDelete(
    API_ENDPOINTS.SUPERCHART_PORTFOLIOS(),
    {
      message: "Saved Profile deleted",
    }
  );

  const keyCompanyQuery = useFetch(
    API_ENDPOINTS.COMPANY_PRICE_VOLUME_ORDER_FLOW(),
    {
      company: keyCompanyId,
    },
    {
      enabled: !_.isNil(keyCompanyId),
    }
  );

  const comparisonQueryObjects = useMemo(() => {
    return comparisons
      .map(({ type, id }) => {
        if (type === ZX_COMPARISON_SERIES_TYPE) {
          return {
            url: API_ENDPOINTS.COMPANY_PRICE_VOLUME_ORDER_FLOW(),
            queryParams: { company: id },
          };
        } else if (
          type === ZX_MARKET_INDEX_SERIES_COMPARISON_TYPE &&
          zxMarketIndexAccess
        ) {
          return {
            url: API_ENDPOINTS.ZX_MARKET_INDEX_VALUES(),
            queryParams: { market_index: id },
          };
        } else if (type === PUBLIC_COMPARISON_SERIES_TYPE) {
          // Need to also add a query for the potentially existent orderflow data for
          // public companies.
          return [
            {
              url: API_ENDPOINTS.PUBLIC_SECURITY_QUOTE(),
              queryParams: { security: id },
            },
            {
              supplementary: true,
              url: API_ENDPOINTS.COMPANY_PRICE_VOLUME_ORDER_FLOW(),
              queryParams: { public_security: id },
            },
          ];
        }
      })
      .flat();
  }, [zxMarketIndexAccess, comparisons]);

  const comparisonsQueries = useFetches(comparisonQueryObjects, {});

  const superchartComparisonData = useMemo(() => {
    if (comparisonsQueries.isAnySuccess) {
      // Since for public companies we need to query our private endpoints to cover the
      // "recently IPO'd" case we need to first pre-process the result of our comparison queries.
      // Attempt to match the public company data with the orderflow data based on location in
      // the queries output.
      const actualCleanedData = comparisonsQueries.cleanedData
        .map((comparisonData, index) => {
          if (!comparisonData && !comparisonQueryObjects[index].supplementary) {
            return comparisonData;
          }
          if (comparisonQueryObjects[index + 1]?.supplementary) {
            // combine the next query's results with this one.
            return {
              ...comparisonData,
              data: combinePublicAndPreIPOData(
                comparisonData.data,
                comparisonsQueries.cleanedData[index + 1]?.data || []
              ),
            };
          } else if (comparisonQueryObjects[index].supplementary) {
            return null;
          }
          return comparisonData;
        })
        .filter((data) => data !== null); // Remove supplementary data points.

      return actualCleanedData.map((comparisonData, index) => {
        const data = comparisonData?.data.length
          ? makeSuperchartData(comparisonData.data, comparisons[index].type)[
              priceDateRange
            ]
          : [];

        return {
          ...comparisons[index],
          isLoading: !comparisonData?.data,
          data,
        };
      });
    }
    return [];
  }, [priceDateRange, comparisons, comparisonQueryObjects, comparisonsQueries]);

  useEffect(() => {
    // Purge comparisons who have successfully been queried but have been found to have no data.
    // Report using a toast on the list of comparisons that had no data.
    const difference = [];
    const newComparisons = comparisons.filter((comp) => {
      const comparisonData = superchartComparisonData.find(
        (data) => data.id === comp.id
      );
      if (
        comparisonData &&
        !comparisonData.isLoading &&
        !comparisonData.data?.length
      ) {
        difference.push(comparisonData);
        return false;
      }
      // Allow comparisons that have not been loaded yet + comparisons with data through the filter.
      return true;
    });

    if (newComparisons.length !== comparisons.length) {
      // Typically because of network latency we'll see 1 of these toasts per failing company, but
      // we might as well handle the list case if there's a weird race between network responses
      // coming back and the rendering cycle.
      dispatch({
        type: ACTIONS.addToast,
        message: `No available data for ${difference
          .map((comparison) => comparison.name)
          .join(", ")}.`,
      });
      setComparisons(newComparisons);
    }
  }, [dispatch, comparisons, superchartComparisonData]);

  const onRemoveComparison = useCallback(
    (comparisonId) =>
      setComparisons(comparisons.filter((comp) => comp.id !== comparisonId)),
    [comparisons]
  );

  const keyCompanyData = useMemo(() => {
    if (keyCompanyQuery.isSuccess) {
      return makeSuperchartData(
        keyCompanyQuery.cleanedData.data,
        KEY_COMPANY_SERIES_TYPE
      );
    }
    return null;
  }, [keyCompanyQuery.isSuccess, keyCompanyQuery.cleanedData]);

  const keyCompanyIndicatorData = useMemo(() => {
    if (keyCompanyQuery.isSuccess) {
      return oldMakeAllIndicatorData(keyCompanyQuery.cleanedData.data);
    }
    return null;
  }, [keyCompanyQuery]);

  const onSelectIndicator = useCallback(
    (indicatorName) => {
      if (!_.isNull(keyCompanyData)) {
        setSelectedIndicators(_.uniq([...selectedIndicators, indicatorName]));
      }
      setIndicatorModal(null);
      MixpanelEvents.superChartAddIndicator(keyCompany.name, indicatorName);
    },
    [keyCompanyData, keyCompany.name, selectedIndicators]
  );

  const onSelectComparison = useCallback(
    (security) => {
      setComparisons([
        ...comparisons,
        {
          id: mapGenericSecurityToId(security),
          type: mapGenericSecurityToSeriesType(security),
          name: security.symbol,
          color: pickRandomColor(),
          exchange: security.exchange,
          enabled: true,
        },
      ]);
      MixpanelEvents.superChartAddComparison(keyCompany.name, security.name);
      setCompModal(null);
    },
    [comparisons, keyCompany.name]
  );

  const onSelectPortfolio = useCallback(
    (portfolio) => {
      const newComparisons = portfolio?.comparisons || [];
      const newIndicators = portfolio
        ? Object.values(OLD_INDICATOR_TYPES).reduce((acc, obj) => {
            if (portfolio[obj.apiDataKey]) acc.push(obj.name);
            return acc;
          }, [])
        : [];
      setComparisons(
        newComparisons.map((comparison) => {
          // Attempt to find the "new" comparison in the state, so we don't regenerate the color for
          // comparisons that are already on the chart.
          const existingComparison = comparisons.find(
            (comp) => comp.id === comparison.id
          );
          return (
            existingComparison || {
              id: comparison.id,
              name: comparison.symbol,
              color: pickRandomColor(),
              exchange: comparison.exchange,
              enabled: true,
              type: comparison.type,
            }
          );
        })
      );
      setSelectedIndicators(newIndicators);
      setProfile(portfolio);
    },
    [comparisons]
  );

  const removeIndicator = useCallback(
    (indicatorName) => {
      setSelectedIndicators(_.without(selectedIndicators, indicatorName));
    },
    [selectedIndicators]
  );

  const noDataIndicators = useMemo(() => {
    return _.keys(
      _.pickBy(keyCompanyIndicatorData, (data) => _.isEmpty(data[TWO_YEAR_KEY]))
    );
  }, [keyCompanyIndicatorData]);

  // The next three useMemos define the data that is ultimately passed to SuperchartGraph
  const superchartKeyCompanyData = useMemo(() => {
    if (!keyCompany || !keyCompanyData) return null;

    return {
      id: keyCompanyId,
      name: keyCompany.name,
      data: keyCompanyData[priceDateRange],
    };
  }, [keyCompanyId, keyCompany, keyCompanyData, priceDateRange]);

  const superchartIndicatorData = useMemo(() => {
    if (!keyCompanyIndicatorData) return [];

    const activeIndicators = _.pick(
      keyCompanyIndicatorData,
      selectedIndicators
    );

    return _.map(activeIndicators, (indicatorData, indicatorName) => ({
      id: indicatorName,
      name: indicatorName,
      label: OLD_INDICATOR_TYPES[indicatorName].label,
      apiDataKey: OLD_INDICATOR_TYPES[indicatorName].apiDataKey,
      data: indicatorData[priceDateRange],
    }));
  }, [keyCompanyIndicatorData, selectedIndicators, priceDateRange]);

  const isProfileDirty = useMemo(() => {
    // Check if the number of comparisons on the profile is different from the number of comparisons
    // on superchart. If they are the same, then check if the union of the two arrays has the
    // same number of ids as the profile comparisons. Check similarly for indicators.
    const originalProfileIndicators = profile
      ? Object.values(OLD_INDICATOR_TYPES).reduce((acc, obj) => {
          if (profile[obj.apiDataKey]) acc.push(obj.name);
          return acc;
        }, [])
      : [];
    const originalProfileComparisonIds = profile
      ? profile.comparisons.map((comp) => comp.id)
      : [];
    const comparisonIds = comparisons.map((comp) => comp.id);

    return Boolean(
      profile &&
        (originalProfileComparisonIds.length !== comparisonIds.length ||
          _.union(originalProfileComparisonIds, comparisonIds).length !==
            originalProfileComparisonIds.length ||
          !_.isEqual(originalProfileIndicators, selectedIndicators))
    );
  }, [profile, comparisons, selectedIndicators]);

  const deletePortfolio = useCallback(() => {
    setShowDeleteModal(false);
    deletePortfolioQuery.mutate({ id: profile.apiId });
    setProfile(null);
  }, [setShowDeleteModal, deletePortfolioQuery, profile]);

  const deleteModal = (
    <Modal
      onClose={() => setShowDeleteModal(false)}
      ctaText="Delete"
      title="Confirm Delete"
      height={158}
      width={400}
      onClickCta={deletePortfolio}
    >
      <span>Are you sure you want to delete this saved profile?</span>
    </Modal>
  );

  const manageProfileDropdownContent = ({ toggleIsOpen }) => (
    <ManageProfileDropdown
      keyCompany={keyCompanyId}
      toggleIsOpen={toggleIsOpen}
      comparisons={superchartComparisonData}
      indicators={superchartIndicatorData}
      isProfileDirty={isProfileDirty}
      setShowDeleteModal={setShowDeleteModal}
    />
  );

  const [dropdownElement, dropdownRef, toggleDropdown] = useDropdown(
    manageProfileDropdownContent,
    { cardStyle: CardStyles.PADDED }
  );

  const manageProfileButtonText = useMemo(() => {
    if (_.isNil(profile)) {
      return "Save Profile";
    }
    return isProfileDirty ? "Save Changes to Profile" : "Manage Saved Profile";
  }, [profile, isProfileDirty]);

  return (
    <SuperchartContainer>
      <ProfileContext.Provider
        value={{ profile, setProfile: onSelectPortfolio }}
      >
        <SuperchartHeader company={keyCompany} />
        <StyledGraphCard cardStyle={CardStyles.SECTIONED}>
          <div>
            {!_.isNil(profile?.apiId) && (
              <StyledProfileHeader>
                <em>
                  Saved profile: {profile.name}
                  {isProfileDirty && (
                    <Fonts.Body2theme50>
                      &nbsp;*edits unsaved
                    </Fonts.Body2theme50>
                  )}
                </em>
              </StyledProfileHeader>
            )}
            <StyledKeyActionsRow>
              <Button
                buttonStyle={ButtonStyles.RAISED}
                leadingIcon={PlusIcon}
                disabled={_.isEmpty(keyCompany)}
                onClick={() =>
                  setCompModal(
                    <AddComparisonModal
                      setModal={setCompModal}
                      onSelectComparison={onSelectComparison}
                    />
                  )
                }
              >
                New Comparison
              </Button>
              <Button
                buttonStyle={ButtonStyles.RAISED}
                leadingIcon={TargetIcon}
                disabled={_.isEmpty(keyCompany)}
                onClick={() =>
                  setIndicatorModal(
                    <AddIndicatorModal
                      setModal={setIndicatorModal}
                      onSelectIndicator={onSelectIndicator}
                      selectedIndicators={selectedIndicators}
                      noDataIndicators={noDataIndicators}
                      companyName={keyCompany?.name}
                    />
                  )
                }
              >
                Add Indicator
              </Button>
              <Button
                buttonStyle={ButtonStyles.RAISED}
                onClick={toggleDropdown}
                leadingIcon={StarIcon}
                ref={dropdownRef}
                disabled={
                  _.isEmpty(comparisons) && _.isEmpty(selectedIndicators)
                }
              >
                {manageProfileButtonText}
              </Button>
              {dropdownElement}
            </StyledKeyActionsRow>
          </div>
          {_.isNull(superchartKeyCompanyData) || _.isNull(keyCompany) ? (
            <StyledNoCompanyPlaceholder>
              No company selected
            </StyledNoCompanyPlaceholder>
          ) : (
            <SuperchartGraph
              primaryCompany={superchartKeyCompanyData}
              indicators={superchartIndicatorData}
              comparisons={superchartComparisonData.filter((c) => !c.isLoading)}
              removeComp={onRemoveComparison}
              removeIndicator={removeIndicator}
              displayVolume={publicCompsVolumeAccess}
            />
          )}
          <StyledCardFooter>
            <OptionSelectList
              title="Range"
              defaultOption={DEFAULT_DATE_RANGE}
              onOptionChange={(option) => {
                setPriceDateRange(option);
              }}
              disabled={_.isNil(keyCompanyId)}
              options={[
                { label: THREE_MONTHS_KEY },
                { label: SIX_MONTHS_KEY },
                { label: ONE_YEAR_KEY },
                { label: TWO_YEAR_KEY },
              ]}
            />
            <Fonts.Caption2theme30>
              TradingView Lightweight Charts™ Copyright © 2023 TradingView, Inc.{" "}
              <HyperLink
                url="https://www.tradingview.com/"
                linkStyle={LinkStyles.INVISIBLE}
                linkType={LinkTypes.EXTERNAL_LINK}
              >
                https://www.tradingview.com/
              </HyperLink>
            </Fonts.Caption2theme30>
          </StyledCardFooter>
        </StyledGraphCard>
        {compModal}
        {indicatorModal}
        {showDeleteModal && deleteModal}
      </ProfileContext.Provider>
    </SuperchartContainer>
  );
};

export default Superchart;
