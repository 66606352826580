import { DateTime } from "luxon";
import { useMemo } from "react";

/**
 * Custom hook to generate the x-axis ticks for the company profile line graph. We prefer to show
 * exactly 12 ticks if the date range spans > 12 months of data. We take an even distribution of the
 * dates so that the spaces between the dates are equal. If the date range spans < 12 months
 * of data, we show all the dates.
 *
 * @param companyData
 * @return {Array<string>} - List of dates to show on the x-axis.
 */
const useXAxisTicks = (companyData) =>
  useMemo(() => {
    // Get the first report_period in each month for the companyData
    const dates = [];

    if (companyData?.data) {
      let lastDate = null;
      for (let i = 0; i < companyData.data.length; i++) {
        const date = DateTime.fromISO(companyData.data[i].time);
        if (lastDate === null || date.month !== lastDate.month) {
          dates.push(date.toISODate());
          lastDate = date;
        }
      }
    }
    // If dates is larger than 12, take an evenly distributed sample of them.
    if (dates.length > 12) {
      const sampleSize = Math.ceil(dates.length / 12);
      return dates.filter((_, i) => i % sampleSize === 0);
    }
    return dates;
  }, [companyData]);

export default useXAxisTicks;
