import PropTypes from "prop-types";
import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import {
  body1,
  caption2,
  TripleChevronDownIcon,
  TripleChevronUpIcon,
} from "yuka";

import {
  DARK_CUTOFF,
  DARK_GREEN_COLOR,
  DARK_RED_COLOR,
  GRAY_COLOR,
  GRAY_TEXT_COLOR,
  GREEN_TEXT_COLOR,
  GREEN_TEXT_LIGHT_COLOR,
  LIGHT_CUTOFF,
  LIGHT_GREEN_COLOR,
  LIGHT_RED_COLOR,
  MID_CUTOFF,
  MID_GREEN_COLOR,
  MID_RED_COLOR,
  RED_TEXT_COLOR,
  RED_TEXT_LIGHT_COLOR,
} from "./constants";

import { ROUTING_PATH } from "../routes/constants";
import { percentFormat } from "../utils/displayFormatUtils";

const StyledBlock = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  cursor: pointer;
  background-color: ${(props) => props.$backgroundColor};
  padding: 12px;
  min-width: 156px; // 180 - 24px;
  max-width: 216px; // 240 - 24px;
  box-sizing: content-box;
  height: 64px;
  // override width if $width is manually set
  flex-basis: ${(props) => (props.$width ? `${props.$width - 24}px` : null)};
  flex-grow: ${(props) => (props.$width ? 0 : 1)};
  flex-shrink: ${(props) => (props.$width ? 0 : 1)};
`;

const StyledMainTextContainer = styled.div`
  display: flex;
  color: ${(props) => props.$color};
  flex-grow: 1;
  flex-direction: column;
`;

const StyledCompanyNameContainer = styled.span`
  ${body1}
  flex: 1;
  white-space: nowrap; /* Prevent text from wrapping */
  overflow: hidden; /* Hide any overflowing text */
  text-overflow: ellipsis; /* Display ellipsis (...) for overflowed text */
`;

const StyledSectorsContainer = styled.span`
  ${caption2}
  flex: 1;
  white-space: nowrap; /* Prevent text from wrapping */
  overflow: hidden; /* Hide any overflowing text */
  text-overflow: ellipsis; /* Display ellipsis (...) for overflowed text */
`;

const StyledPercentChangeContainer = styled.div`
  display: flex;
  ${body1}
  color: ${(props) => props.$color};
  justify-content: flex-end;
  align-self: flex-end;
`;

const HeatMapBlock = (props) => {
  const navigate = useNavigate();

  const absChange = Math.abs(props.change);

  let backgroundColor;
  let textColor;
  let chevronTopColor;
  let chevronMiddleColor;
  let icon;

  // Positive Change
  if (props.change > 0) {
    textColor = GREEN_TEXT_COLOR;
    // > 50%
    if (absChange >= LIGHT_CUTOFF) {
      backgroundColor = LIGHT_GREEN_COLOR;
      // 5-50%
    } else if (absChange >= MID_CUTOFF && absChange < LIGHT_CUTOFF) {
      backgroundColor = MID_GREEN_COLOR;
      chevronTopColor = GREEN_TEXT_LIGHT_COLOR;
      // < 5%
    } else if (absChange >= DARK_CUTOFF) {
      backgroundColor = DARK_GREEN_COLOR;
      chevronTopColor = GREEN_TEXT_LIGHT_COLOR;
      chevronMiddleColor = GREEN_TEXT_LIGHT_COLOR;
    }
    icon = (
      <TripleChevronUpIcon
        color={GREEN_TEXT_COLOR}
        middleColor={chevronMiddleColor}
        topColor={chevronTopColor}
      />
    );
    // Negative Change
  } else if (props.change < 0) {
    textColor = RED_TEXT_COLOR;
    // > 50%
    if (absChange >= LIGHT_CUTOFF) {
      backgroundColor = LIGHT_RED_COLOR;
      // 5-50%
    } else if (absChange >= MID_CUTOFF && absChange < LIGHT_CUTOFF) {
      backgroundColor = MID_RED_COLOR;
      chevronTopColor = RED_TEXT_LIGHT_COLOR;
      // < 5%
    } else if (absChange >= DARK_CUTOFF) {
      backgroundColor = DARK_RED_COLOR;
      chevronTopColor = RED_TEXT_LIGHT_COLOR;
      chevronMiddleColor = RED_TEXT_LIGHT_COLOR;
    }
    icon = (
      <TripleChevronDownIcon
        color={RED_TEXT_COLOR}
        middleColor={chevronMiddleColor}
        topColor={chevronTopColor}
      />
    );
    // No Change
  } else {
    // there shouldn't be any blocks with no change
    textColor = GRAY_TEXT_COLOR;
    backgroundColor = GRAY_COLOR;
    icon = "--";
  }

  const onClick = () => {
    navigate(ROUTING_PATH.COMPANY(props.companyId));
  };

  return (
    <StyledBlock
      $backgroundColor={backgroundColor}
      $width={props.width}
      onClick={onClick}
      ref={props.firstBlockRef}
    >
      <StyledMainTextContainer $color={textColor}>
        <StyledCompanyNameContainer>
          {props.companyName}
        </StyledCompanyNameContainer>
        <StyledSectorsContainer>{props.sectorNames}</StyledSectorsContainer>
      </StyledMainTextContainer>
      <StyledPercentChangeContainer $color={textColor}>
        {icon}
        &nbsp;
        {props.change ? percentFormat(props.change * 100) : "0%"}
      </StyledPercentChangeContainer>
    </StyledBlock>
  );
};

HeatMapBlock.propTypes = {
  change: PropTypes.number.isRequired,
  companyId: PropTypes.string.isRequired,
  companyName: PropTypes.string.isRequired,
  firstBlockRef: PropTypes.oneOf([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(React.Element) }),
  ]),
  width: PropTypes.number,
  sectorNames: PropTypes.string.isRequired,
};

HeatMapBlock.defaultProps = {
  firstBlockRef: null,
  width: null,
};

export default HeatMapBlock;
