import _ from "lodash";
import { DateTime } from "luxon";
import React, { useCallback, useMemo, useState } from "react";
import { Bar, BarChart, Tooltip, XAxis, ResponsiveContainer } from "recharts";
import styled from "styled-components";
import { ColorPalette, Fonts } from "yuka";

import ActiveVolumeBar from "./ActiveVolumeBar";
import { SELECTED_RATIO_TYPE_VOLUME } from "./constants";
import SelectTypeDropdown from "./SelectTypeDropdown";

import { API_ENDPOINTS } from "../../../api/constants";
import useFetch from "../../../api/useFetch";
import { DataverseColors } from "../../../hdYuka/constants";
import SurfaceZeroCard from "../../../hdYuka/SurfaceZeroCard";
import applyOpacityToHex from "../../../utils/applyOpacityToHex";
import { ORDER_FLOW_TIME_FRAME_MONTHLY } from "../../../utils/constants";
import { percentFormat } from "../../../utils/displayFormatUtils";
import LoadingSpinner from "../../../utils/LoadingSpinner";
import { useCompany } from "../../hooks";
import {
  List,
  ListItem,
  StyledCircle,
  StyledEmptyPill,
  StyledCenteredEmptyState,
} from "../../StyledComponents";

const GraphXAxisYearContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
`;

const LegendItemContainer = styled(Fonts.Body1theme50).attrs({ as: "div" })`
  display: flex;
  gap: 12px;
  align-items: center;
`;

const BidAskRatioCard = () => {
  const [company, companyIsLoading] = useCompany();
  const [hoverStats, setHoverStats] = useState(null);
  const [selectedRatioType, setSelectedRatioType] = useState(
    SELECTED_RATIO_TYPE_VOLUME
  );
  const selectedBidType = useMemo(
    () =>
      selectedRatioType === SELECTED_RATIO_TYPE_VOLUME
        ? "percent_bid_by_volume"
        : "percent_bid_by_tickets",
    [selectedRatioType]
  );
  const selectedAskType = useMemo(
    () =>
      selectedRatioType === SELECTED_RATIO_TYPE_VOLUME
        ? "percent_ask_by_volume"
        : "percent_ask_by_tickets",
    [selectedRatioType]
  );

  const bidOfferRatioQuery = useFetch(
    `${API_ENDPOINTS.COMPANY_BID_OFFER_RATIO_DATA(company.zb_id, {
      time_frame: ORDER_FLOW_TIME_FRAME_MONTHLY,
    })}?time_frame=${ORDER_FLOW_TIME_FRAME_MONTHLY}`
  );

  const bidOfferRatioData = bidOfferRatioQuery?.data?.data;
  const graphData = useMemo(
    () =>
      _.map(bidOfferRatioData, (d) => ({
        date: d.attributes.report_period,
        percent_bid_by_tickets: d.attributes.percent_bid_by_tickets,
        percent_ask_by_tickets: 1 - d.attributes.percent_bid_by_tickets,
        percent_bid_by_volume: d.attributes.percent_bid_by_volume,
        percent_ask_by_volume: 1 - d.attributes.percent_bid_by_volume,
      })),
    [bidOfferRatioData]
  );

  const xAxisTicks = useMemo(() => _.map(graphData, "date"), [graphData]);
  const startDate = useMemo(() => _.last(xAxisTicks), [xAxisTicks]);
  const endDate = useMemo(() => _.first(xAxisTicks), [xAxisTicks]);

  // Set hover point data
  const onMouseMove = useCallback(
    (d) => {
      if (!graphData) {
        return;
      }

      if (d.activeTooltipIndex) {
        const hoveredBarData = graphData[d.activeTooltipIndex];
        setHoverStats({
          ...hoveredBarData,
          report_period: d.activeLabel,
        });
      } else {
        setHoverStats(null);
      }
    },
    [graphData]
  );

  // The active dot on the <Line /> component requires a tooltip to be enabled
  // So we pass in a null tooltip function as a hack
  const renderNullTooltip = useCallback(() => null, []);

  if (companyIsLoading) {
    return <LoadingSpinner />;
  }

  if (!bidOfferRatioData) {
    return (
      <SurfaceZeroCard title="Bid-Ask Ratio">
        <StyledEmptyPill>
          <StyledCenteredEmptyState $margin={48}>
            Data not available
          </StyledCenteredEmptyState>
        </StyledEmptyPill>
      </SurfaceZeroCard>
    );
  }

  return (
    <SurfaceZeroCard
      title="Bid-Ask Ratio"
      headerTrailingContent={
        <SelectTypeDropdown
          selectedRatioType={selectedRatioType}
          setSelectedRatioType={setSelectedRatioType}
        />
      }
    >
      <ResponsiveContainer width="100%" minHeight={150}>
        <BarChart
          data={graphData}
          barCategoryGap={8}
          onMouseMove={onMouseMove}
          margin={{
            top: 0,
            right: 12,
            left: 12,
            bottom: 0,
          }}
        >
          <XAxis
            axisLine={false}
            tickLine={false}
            interval={0}
            fontSize={11}
            tickFormatter={(value) => DateTime.fromISO(value).toFormat("MMM")}
            ticks={xAxisTicks}
            dataKey="date"
            stroke={ColorPalette.white50}
          />
          <Tooltip
            content={renderNullTooltip}
            cursor={{ fill: "transparent" }}
          />
          <Bar
            dataKey={selectedBidType}
            fill={applyOpacityToHex(DataverseColors.green, 0.5)}
            stackId="bidAskRatioStack"
            activeBar={ActiveVolumeBar(
              selectedBidType,
              DataverseColors.green,
              0
            )}
          />
          <Bar
            dataKey={selectedAskType}
            fill={applyOpacityToHex(DataverseColors.red, 0.5)}
            stackId="bidAskRatioStack"
            style={{ transform: "translateY(-2px)" }}
            activeBar={ActiveVolumeBar(selectedAskType, DataverseColors.red, 1)}
          />
        </BarChart>
      </ResponsiveContainer>
      <GraphXAxisYearContainer>
        <Fonts.Caption1theme50>
          {DateTime.fromISO(startDate).year}
        </Fonts.Caption1theme50>
        <Fonts.Caption1theme50>
          {DateTime.fromISO(endDate).year}
        </Fonts.Caption1theme50>
      </GraphXAxisYearContainer>
      <List>
        <ListItem>
          <LegendItemContainer>
            <StyledCircle color={DataverseColors.green} />
            Bid %
          </LegendItemContainer>
          {hoverStats && hoverStats[selectedBidType] ? (
            <Fonts.Body2theme80>
              {percentFormat(hoverStats[selectedBidType] * 100, 0, 0)}
            </Fonts.Body2theme80>
          ) : (
            <Fonts.Body2theme30>--</Fonts.Body2theme30>
          )}
        </ListItem>
        <ListItem>
          <LegendItemContainer>
            <StyledCircle color={DataverseColors.red} />
            Ask %
          </LegendItemContainer>
          {hoverStats && hoverStats[selectedAskType] ? (
            <Fonts.Body2theme80>
              {percentFormat(hoverStats[selectedAskType] * 100, 0, 0)}
            </Fonts.Body2theme80>
          ) : (
            <Fonts.Body2theme30>--</Fonts.Body2theme30>
          )}
        </ListItem>
        <ListItem>
          <LegendItemContainer>Report Period</LegendItemContainer>
          {hoverStats?.report_period ? (
            <Fonts.Body2theme80>
              {DateTime.fromISO(hoverStats.report_period).toLocaleString({
                month: "short",
                year: "numeric",
              })}
            </Fonts.Body2theme80>
          ) : (
            <Fonts.Body2theme30>--</Fonts.Body2theme30>
          )}
        </ListItem>
      </List>
    </SurfaceZeroCard>
  );
};

export default BidAskRatioCard;
