import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import {
  Table,
  NumberCell,
  PercentageCell,
  BookmarkCell,
  ColorPalette,
  Fonts,
  YukaThemeProvider,
  YukaColorPalette,
} from "yuka";

import CompanyCellRenderer from "./CompanyCellRenderer";
import CompanyFilters from "./CompanyFilters";
import {
  PREMIUM_TO_LAST_ROUND_FILTER,
  RANGE_QUERY_PARAMS,
  ROBUSTNESS_FILTER,
  WATCHLIST_BOOLEAN_MAP,
  ZX_INDEX_VALUE_CHANGE_FILTER,
  LOADING_TEXT,
} from "./constants";
import { getLastRoundPriceDiff } from "./utils";

import { API_ENDPOINTS } from "../api/constants";
import useDelete from "../api/useDelete";
import useFetch from "../api/useFetch";
import useFetches from "../api/useFetches";
import useInfiniteFetch from "../api/useInfiniteFetch";
import useWrite from "../api/useWrite";
import { AuthContext } from "../auth";
import { PAGE_WIDTH } from "../constants";
import { ROUTING_PATH } from "../routes/constants";
import {
  MONEY_FORMAT_MAX_DECIMAL_PLACES,
  MONEY_FORMAT_MIN_DECIMAL_PLACES,
  WEEKLY,
} from "../utils/constants";
import {
  expandedMoneyFormat,
  percentFormat,
} from "../utils/displayFormatUtils";
import MixpanelEvents from "../utils/mixpanel/MixpanelEvents";
import { StyledError } from "../utils/StyledComponents";
import useDocumentTitle from "../utils/useDocumentTitle";
import { Walkthrough } from "../Walkthrough";

const StyledCompanyListContainer = styled.div`
  ${PAGE_WIDTH}
  padding-top: 48px;
  flex-grow: 1;
  overflow: hidden;
  display: flex;
  gap: 24px;
  flex-direction: column;
  align-self: center;
`;

const EmptyInfoContainer = styled.div`
  padding: 0 24px;
`;

const RANGE_QUERY_PARAM_KEYS = [
  ROBUSTNESS_FILTER,
  ZX_INDEX_VALUE_CHANGE_FILTER,
  PREMIUM_TO_LAST_ROUND_FILTER,
];

const CompanyListHeader = styled(Fonts.Headline3theme80)`
  width: fit-content;
`;

const WALKTHROUGH_STEPS = [
  {
    title: "New company screener",
    text:
      "With an updated view and more filters, you can now sift through hundreds of private " +
      "companies and discover the right fit for your investment strategy.",
    positionSelector: "#company-list-header",
  },
  {
    title: "Powerful global search",
    text: "You can quickly and easily find any ZXData private company using the global search.",
    positionSelector: "#global-search-bar",
  },
  {
    title: "Brand new portfolio feature",
    text: "You can now manage your private market investments within ZXData.",
    positionSelector: "#portfolios-global-nav-link",
  },
];

const CompanyList = () => {
  const navigate = useNavigate();
  useDocumentTitle("ZXData: Company List");
  const { user, setUserData } = useContext(AuthContext);

  const walkthroughUpdate = useWrite(
    API_ENDPOINTS.WALKTHROUGH_PROGRESS(user?.id),
    true,
    {
      silent: true,
    }
  );
  const [walkthroughShowing, setWalkthroughShowing] = useState(
    !user?.walkthrough_progress?.walkthrough_company_list
  );

  const [filters, setFilters] = useState({
    watchlist: null,
    [ROBUSTNESS_FILTER]: null,
    [ZX_INDEX_VALUE_CHANGE_FILTER]: null,
    [PREMIUM_TO_LAST_ROUND_FILTER]: null,
  });

  useEffect(() => {
    MixpanelEvents.viewCompanyList();
  }, []);

  const companyQueryInfo = useInfiniteFetch(
    API_ENDPOINTS.COMPANY_LATEST_ORDER_FLOW(),
    {
      ordering: "-robustness,-volume",
      time_frame: WEEKLY,
      "page[size]": 30,
      // Intentionally can be undefined to include both watchlisted and non-watchlisted.
      watchlist: WATCHLIST_BOOLEAN_MAP[filters.watchlist],
      ...RANGE_QUERY_PARAM_KEYS.reduce(
        (acc, key) =>
          filters[key]
            ? {
                ...acc,
                [`${key}_min`]: RANGE_QUERY_PARAMS[filters[key]].min,
                [`${key}_max`]: RANGE_QUERY_PARAMS[filters[key]].max,
              }
            : acc,
        {}
      ),
    }
  );

  const watchlistQueryInfo = useFetch(API_ENDPOINTS.WATCHLIST_COMPANIES());
  const watchlistDelete = useDelete(API_ENDPOINTS.WATCHLIST_COMPANIES(), {
    silent: true,
  });
  const watchlistUpdate = useWrite(API_ENDPOINTS.WATCHLIST_COMPANIES(), false, {
    silent: true,
  });

  // map for quick lookup on watchlisted companies
  const watchlistMap = useMemo(() => {
    if (watchlistQueryInfo.isSuccess) {
      return watchlistQueryInfo.cleanedData.reduce((obj, watchlistItem) => {
        obj[watchlistItem.company] = watchlistItem;
        return obj;
      }, {});
    }
    return {};
  }, [watchlistQueryInfo.isSuccess, watchlistQueryInfo.cleanedData]);

  const companies = useMemo(() => {
    if (companyQueryInfo.isSuccess) {
      return companyQueryInfo.cleanedData.data;
    }
    return [];
  }, [companyQueryInfo.isSuccess, companyQueryInfo.cleanedData]);

  const hdFundingRoundsQuery = useFetches(
    companies?.map((company) => ({
      url: API_ENDPOINTS.HD_FUNDING_ROUNDS(),
      queryParams: {
        company: company.apiId,
        "page[size]": 1,
      },
    }))
  );

  // We'll determine the funding round related to each company based on ordering.
  const fundingRounds = useMemo(() => {
    if (hdFundingRoundsQuery.isAnySuccess) {
      return hdFundingRoundsQuery.cleanedData.map(
        (queryResult) => queryResult?.data?.[0]
      );
    }
    return [];
  }, [hdFundingRoundsQuery.isAnySuccess, hdFundingRoundsQuery.cleanedData]);

  const fundingRoundsMap = useMemo(() => {
    let result = {};
    for (let i = 0; i < companies.length; i++) {
      if (!fundingRounds[i] && hdFundingRoundsQuery.queryInfo[i].isLoading) {
        // Check to see if it's loading
        result[companies[i].apiId] = LOADING_TEXT;
      } else {
        result[companies[i].apiId] = fundingRounds[i];
      }
    }
    return result;
  }, [companies, fundingRounds, hdFundingRoundsQuery.queryInfo]);

  /*
   * Used to wrap the `setFilters` function to use the child filter buttons onChange interface.
   */
  const makeOnChangeFunction = useCallback(
    (fieldName) => {
      return (newValue) => {
        setFilters({
          ...filters,
          [fieldName]: newValue,
        });
      };
    },
    [filters]
  );

  if (companyQueryInfo.isError) {
    return (
      <StyledCompanyListContainer>
        <Fonts.Headline3theme80>Company List</Fonts.Headline3theme80>
        <CompanyFilters
          filters={filters}
          makeOnChangeFunction={makeOnChangeFunction}
        />
        <EmptyInfoContainer>
          <StyledError>An error has occurred</StyledError>
        </EmptyInfoContainer>
      </StyledCompanyListContainer>
    );
  }

  const onRowClick = ({ row: { index } }) => {
    const companyId = companies[index].apiId;

    navigate(ROUTING_PATH.COMPANY(companyId));
  };

  const tableColumns = [
    {
      id: "watchlist",
      accessor: (company) => company.apiId in watchlistMap,
      onBookmark: ({ row: { original: company }, event }) => {
        const companyId = company.apiId;
        if (companyId in watchlistMap) {
          watchlistDelete.mutate({ id: companyId });
        } else {
          watchlistUpdate.mutate({ company: companyId });
        }
        event?.stopPropagation();
      },
      cellRenderer: BookmarkCell,
      align: "center",
      width: 50,
      useFixedWidth: true,
      header: "",
      sticky: true,
    },
    {
      id: "name",
      accessor: (company) => ({
        name: company.name,
        avatar: company.main_picture,
      }),
      header: "Company",
      width: 250,
      sticky: true,
      cellRenderer: CompanyCellRenderer,
    },
    {
      id: "zx_index_value",
      accessor: "zx_index_value",
      header: "ZX Index Value",
      width: 130,
      cellRenderer: NumberCell,
      formatter: (value) =>
        value ? (
          expandedMoneyFormat(
            value,
            MONEY_FORMAT_MIN_DECIMAL_PLACES,
            MONEY_FORMAT_MAX_DECIMAL_PLACES
          )
        ) : (
          <Fonts.Body1theme30>--</Fonts.Body1theme30>
        ),
    },
    {
      id: "weekChange",
      accessor: "zx_index_value_percent_change",
      header: "Week Change",
      width: 120,
      cellRenderer: PercentageCell,
      isUnitInterval: true,
    },
    {
      id: "lastRoundPrice",
      accessor: (company) => ({ company, fundingRoundsMap }),
      header: "LR Price",
      width: 100,
      cellRenderer: NumberCell,
      formatter: ({ company, fundingRoundsMap }) => {
        const fundingRound = fundingRoundsMap[company.apiId];
        if (fundingRound === LOADING_TEXT) {
          return LOADING_TEXT;
        }
        if (!fundingRound || !fundingRound.price_per_share) {
          return <Fonts.Body1theme30>--</Fonts.Body1theme30>;
        }
        return expandedMoneyFormat(
          fundingRound.price_per_share,
          MONEY_FORMAT_MIN_DECIMAL_PLACES,
          MONEY_FORMAT_MAX_DECIMAL_PLACES
        );
      },
    },
    {
      id: "premiumToLastRound",
      accessor: (company) => ({
        company,
        fundingRoundsMap,
      }),
      header: "Premium to LR",
      width: 130,
      cellRenderer: NumberCell,
      formatter: ({ company, fundingRoundsMap }) => {
        const fundingRound = fundingRoundsMap[company.apiId];
        if (fundingRound === LOADING_TEXT) {
          return LOADING_TEXT;
        }
        if (!fundingRound || !fundingRound.price_per_share) {
          return <Fonts.Body1theme30>--</Fonts.Body1theme30>;
        }
        return getLastRoundPriceDiff(
          fundingRound.price_per_share,
          company.zx_index_value
        );
      },
    },
    {
      id: "volume",
      accessor: "total_volume",
      header: "$ Volume (M)",
      width: 120,
      cellRenderer: NumberCell,
      formatter: (value) => expandedMoneyFormat(value, 0, 0),
    },
    {
      id: "robustnessScore",
      accessor: "robustness",
      header: "Robustness Score",
      width: 160,
      cellRenderer: NumberCell,
      formatter: (value) =>
        (
          <>
            {value}
            <Fonts.Body1theme50>/10</Fonts.Body1theme50>
          </>
        ) || <Fonts.Body1theme30>--</Fonts.Body1theme30>,
    },
    {
      id: "bid_ask_ratio",
      accessor: "bid_ask_ratio",
      header: "Bid/Ask Ratio",
      width: 120,
      cellRenderer: NumberCell,
      formatter: (value) => percentFormat(value * 100, 0),
    },
  ];

  return (
    <YukaThemeProvider
      theme={{
        tableStyles: {
          colors: {
            header: YukaColorPalette.surface0,
            row: YukaColorPalette.surface0,
            rowHover: ColorPalette.surface1,
          },
          header: {
            height: 42,
            borderTop: `1px dashed ${ColorPalette.white15}`,
            borderBottom: `1px dashed ${ColorPalette.white15}`,
          },
          cells: {
            height: 56,
            borderBottom: `1px dashed ${ColorPalette.white15}`,
          },
        },
      }}
    >
      <StyledCompanyListContainer>
        <CompanyListHeader id="company-list-header">
          Company List
        </CompanyListHeader>
        {walkthroughShowing &&
          !user?.walkthrough_progress?.walkthrough_company_list && (
            <Walkthrough
              steps={WALKTHROUGH_STEPS}
              onFinish={() => {
                setWalkthroughShowing(false);
                walkthroughUpdate.mutate(
                  { walkthrough_company_list: true },
                  {
                    onSuccess: () => {
                      setUserData();
                    },
                  }
                );
              }}
            />
          )}
        <CompanyFilters
          filters={filters}
          makeOnChangeFunction={makeOnChangeFunction}
        />
        <Table
          data={companies}
          fundingRoundsMap={fundingRoundsMap}
          onRowClick={onRowClick}
          paginationFunc={
            companyQueryInfo.hasNextPage && !companyQueryInfo.isFetchingNextPage
              ? companyQueryInfo.fetchNextPage
              : null
          }
          isLoading={companyQueryInfo.isLoading}
          isPaginationLoading={companyQueryInfo.isFetchingNextPage}
          columns={tableColumns}
        />
      </StyledCompanyListContainer>
    </YukaThemeProvider>
  );
};

export default CompanyList;
