import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";
import {
  ArrowUpIcon,
  Fonts,
  List,
  ListItem,
  useDropdown,
  CheckIcon,
  ColorPalette,
  ArrowDownIcon,
  PageSection,
} from "yuka";

import {
  SORT_CRITERIA,
  SORT_CRITERIA_TO_LABEL,
  SORT_DIRECTION_ASC,
  SORT_DIRECTIONS,
} from "./constants";

import { ActionButton } from "../hdYuka";

const StyledDropdownContainer = styled(PageSection)`
  padding: 12px 0;
  margin: -16px 0;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const StyledSection = styled(Fonts.Overlinetheme50).attrs({ as: "div" })`
  padding: 8px 12px;
`;

const StyledListItem = styled(ListItem)`
  padding: 0 12px;
  height: 32px;
  min-height: 32px;
`;

const IconPlaceholderDiv = styled.div`
  width: 18px;
  height: 18px;
`;

const SortByDropdown = ({ updateSort, currentSort }) => {
  const SearchDropdown = () => (
    <StyledDropdownContainer>
      <div>
        <StyledSection>Sort by</StyledSection>
        <List>
          {SORT_CRITERIA.map((sortCriteria) => (
            <StyledListItem
              key={`sort_criteria_${sortCriteria}`}
              leadingIconColor={ColorPalette.white50}
              leadingIcon={
                sortCriteria === currentSort.criteria
                  ? CheckIcon
                  : IconPlaceholderDiv
              }
              text={sortCriteria}
              onClick={() =>
                updateSort({ ...currentSort, criteria: sortCriteria })
              }
            />
          ))}
        </List>
      </div>
      <div>
        <StyledSection>Sort direction</StyledSection>
        <List>
          {SORT_DIRECTIONS.map((sortDirection) => (
            <StyledListItem
              key={`sort_direction_${sortDirection.key}`}
              leadingIconColor={ColorPalette.white50}
              leadingIcon={
                sortDirection === currentSort.direction
                  ? CheckIcon
                  : IconPlaceholderDiv
              }
              text={sortDirection}
              onClick={() =>
                updateSort({ ...currentSort, direction: sortDirection })
              }
            />
          ))}
        </List>
      </div>
    </StyledDropdownContainer>
  );

  const [dropdownElement, dropdownRef, toggleDropdown] =
    useDropdown(SearchDropdown);

  return (
    <>
      <ActionButton
        active={dropdownElement !== null}
        ref={dropdownRef}
        onClick={toggleDropdown}
        icon={
          currentSort.direction === SORT_DIRECTION_ASC
            ? ArrowUpIcon
            : ArrowDownIcon
        }
      >
        Sort by {SORT_CRITERIA_TO_LABEL[currentSort.criteria]}
      </ActionButton>
      {dropdownElement}
    </>
  );
};

SortByDropdown.propTypes = {
  updateSort: PropTypes.func.isRequired,
  currentSort: PropTypes.shape({
    direction: PropTypes.oneOf(SORT_DIRECTIONS),
    criteria: PropTypes.oneOf(SORT_CRITERIA),
  }).isRequired,
};

export default SortByDropdown;
