import React from "react";
import PropTypes from "prop-types";
import { useForm, useFormState } from "react-final-form";

import Modal from "../Layout/Modal";

const CONFIRM_MESSAGE =
  "Are you sure you want to close this form? Any unsaved changes will be lost";

/**
 * A lightweight wrapper around the styleguide form component that hooks in logic for
 * determining if we should prompt on closing.
 *
 * Consider having the <form> element in here as well if handleSubmit/onSubmit provided to
 * abstract that interaction out.
 *
 * @param {object} props
 *
 * @returns {Element}
 */
const FormModal = ({
  submitText,
  onSubmit,
  requireDirtyForSubmit,
  requireDirtyForAlt,
  ...props
}) => {
  const { dirty, valid, submitting } = useFormState({
    subscription: { dirty: true, valid: true, submitting: true },
  });
  const { submit } = useForm();

  return (
    <Modal
      {...props}
      closeConfirmationMessage={dirty ? CONFIRM_MESSAGE : ""}
      onClickCta={onSubmit || submit}
      altDisabled={!((dirty || !requireDirtyForAlt) && valid && !submitting)}
      ctaDisabled={!((dirty || !requireDirtyForSubmit) && valid && !submitting)}
      ctaText={submitText}
    />
  );
};

FormModal.propTypes = {
  requireDirtyForAlt: PropTypes.bool,
  requireDirtyForSubmit: PropTypes.bool,
  submitText: PropTypes.string,
  onSubmit: PropTypes.func,
  children: PropTypes.node,
};

FormModal.defaultProps = {
  submitText: "Submit",
  requireDirtyForSubmit: true,
};

export default FormModal;
