import { useMemo } from "react";
import { ResponsiveContainer, Sankey } from "recharts";
import styled from "styled-components";
import { CardStyles, Fonts } from "yuka";

import { API_ENDPOINTS } from "../../api/constants";
import useFetch from "../../api/useFetch";
import { SurfaceZeroCard } from "../../hdYuka";
import CompanyTransferabilitySankeyLink from "../CompanyTransferabilitySankeyLink";
import CompanyTransferabilitySankeyNode from "../CompanyTransferabilitySankeyNode";
import {
  SANKEY_NODE_COMMON,
  SANKEY_NODE_DIRECT_APPROVED,
  SANKEY_NODE_DIRECT_ROFR,
  SANKEY_NODE_FORWARD,
  SANKEY_NODE_PREFERRED,
  SANKEY_NODE_SPV,
  SANKEY_NODE_UNKNOWN_SECURITY,
} from "../constants";
import { useCompany } from "../hooks";
import { StyledCenteredEmptyState, StyledEmptyPill } from "../StyledComponents";

const StyledContainer = styled.div`
  height: 380px;
  width: 100%;
  box-sizing: border-box;
  padding: 0 16px;
`;

const StyledFooter = styled(Fonts.Caption2theme30).attrs({ as: "div" })`
  padding-right: 16px;
  text-align: right;
`;

const CompanyTransferabilityCard = () => {
  const [company, companyIsLoading] = useCompany();

  const transactionRecordAggregateQuery = useFetch(
    API_ENDPOINTS.TRANSACTION_RECORD_AGGREGATES(),
    {
      company: company?.zb_id,
    },
    {
      enabled: !companyIsLoading && Boolean(company?.zb_id),
    }
  );

  const transactionRecordData = useMemo(
    () =>
      transactionRecordAggregateQuery.isSuccess
        ? transactionRecordAggregateQuery.cleanedData?.data?.[0]
        : null,
    [
      transactionRecordAggregateQuery.isSuccess,
      transactionRecordAggregateQuery.cleanedData?.data,
    ]
  );

  const transferabilityData = useMemo(() => {
    if (!transactionRecordData) {
      return {
        nodes: [],
        links: [],
      };
    }
    // Add in the unknown percentages for convenience.
    const actualData = {
      ...transactionRecordData,
      approved_volume_unknown:
        Number(transactionRecordData.approved_volume_percentage) -
        Number(transactionRecordData.approved_volume_common_percentage) -
        Number(transactionRecordData.approved_volume_preferred_percentage),
      rofr_volume_unknown:
        Number(transactionRecordData.rofr_volume_total_percentage) -
        Number(transactionRecordData.rofr_volume_common_percentage) -
        Number(transactionRecordData.rofr_volume_preferred_percentage),
    };
    const pushNode = (nodeName, depth) => {
      nodes.push({
        name: nodeName,
        depth,
      });
    };
    const pushLink = (source, target, value) => {
      links.push({
        source,
        target,
        value,
      });
    };
    const ALL_TRADES_NODE = 0;
    const FIRST_LAYER = 0;
    const SECOND_LAYER = 1;
    const THIRD_LAYER = 2;
    // We'll store the node index that the direct approved and direct rofr nodes end up at
    // so we can refer to them for creating the links to the security types.
    let directApprovedNodeIndex = 0;
    let directRofrNodeIndex = 0;

    let curNode = 1;
    const nodes = [{ name: "All trades", depth: FIRST_LAYER }];
    const links = [];
    // We have to build the nodes and links in a specific order, and dependent on what data
    // is present. Specific nodes always have the same depth, but the links are dynamic because
    // we don't include a node if its % is 0.
    if (actualData.approved_volume_percentage) {
      directApprovedNodeIndex = curNode;
      pushNode(SANKEY_NODE_DIRECT_APPROVED, SECOND_LAYER);
      pushLink(
        ALL_TRADES_NODE,
        curNode++,
        actualData.approved_volume_percentage * 100
      );
    }
    if (actualData.rofr_volume_total_percentage) {
      directRofrNodeIndex = curNode;
      pushNode(SANKEY_NODE_DIRECT_ROFR, SECOND_LAYER);
      pushLink(
        ALL_TRADES_NODE,
        curNode++,
        actualData.rofr_volume_total_percentage * 100
      );
    }
    if (actualData.spv_volume_percentage) {
      pushNode(SANKEY_NODE_SPV, SECOND_LAYER);
      pushLink(
        ALL_TRADES_NODE,
        curNode++,
        actualData.spv_volume_percentage * 100
      );
    }
    if (actualData.derivative_volume_percentage) {
      pushNode(SANKEY_NODE_FORWARD, SECOND_LAYER);
      pushLink(
        ALL_TRADES_NODE,
        curNode++,
        actualData.derivative_volume_percentage * 100
      );
    }
    if (directApprovedNodeIndex && actualData.approved_volume_unknown !== 0) {
      pushNode(SANKEY_NODE_UNKNOWN_SECURITY, THIRD_LAYER);
      pushLink(
        directApprovedNodeIndex,
        curNode++,
        actualData.approved_volume_unknown * 100
      );
    }
    if (
      directApprovedNodeIndex &&
      actualData.approved_volume_preferred_percentage
    ) {
      pushNode(SANKEY_NODE_PREFERRED, THIRD_LAYER);
      pushLink(
        directApprovedNodeIndex,
        curNode++,
        actualData.approved_volume_preferred_percentage * 100
      );
    }
    if (
      directApprovedNodeIndex &&
      actualData.approved_volume_common_percentage
    ) {
      pushNode(SANKEY_NODE_COMMON, THIRD_LAYER);
      pushLink(
        directApprovedNodeIndex,
        curNode++,
        actualData.approved_volume_common_percentage * 100
      );
    }
    if (directRofrNodeIndex && actualData.rofr_volume_unknown !== 0) {
      pushNode(SANKEY_NODE_UNKNOWN_SECURITY, THIRD_LAYER);
      pushLink(
        directRofrNodeIndex,
        curNode++,
        actualData.rofr_volume_unknown * 100
      );
    }
    if (directRofrNodeIndex && actualData.rofr_volume_preferred_percentage) {
      pushNode(SANKEY_NODE_PREFERRED, THIRD_LAYER);
      pushLink(
        directRofrNodeIndex,
        curNode++,
        actualData.rofr_volume_preferred_percentage * 100
      );
    }
    if (directRofrNodeIndex && actualData.rofr_volume_common_percentage) {
      pushNode(SANKEY_NODE_COMMON, THIRD_LAYER);
      pushLink(
        directRofrNodeIndex,
        curNode++,
        actualData.rofr_volume_common_percentage * 100
      );
    }

    return {
      nodes,
      links,
    };
  }, [transactionRecordData]);

  if (transactionRecordAggregateQuery.isLoading || !transactionRecordData) {
    return (
      <SurfaceZeroCard title="Company Tranferability">
        <StyledEmptyPill>
          <StyledCenteredEmptyState $margin={48}>
            Data not available
          </StyledCenteredEmptyState>
        </StyledEmptyPill>
      </SurfaceZeroCard>
    );
  }

  /**
   * We need to add some margin to the right of the sankey graph because the labels for each node
   * extend beyond the right edge of the node. The longest 3rd-layer label is "Unknown Security",
   * which with our typeface takes about 98px. Subtract the width of the node (24), and we get 74px.
   * @type {number}
   */
  const SANKEY_MARGIN_RIGHT = 74;
  /**
   * Similarly to the right margin, we need to Add enough margin to cover the labels which appear
   * above the nodes. 34 is 10 pixels larger than the y offset for the text, so we get some
   * narrow padding between the two rows of text, and the bar.
   * @type {number}
   */
  const SANKEY_MARGIN_TOP = 34;

  return (
    <SurfaceZeroCard
      title="Company Transferability"
      cardStyle={CardStyles.SECTIONED}
    >
      <StyledContainer>
        <ResponsiveContainer>
          <Sankey
            allowFloatingTerminalNodes
            // iterations={0} Forces nodes to align at the top of the container, and not reorder.
            iterations={0}
            data={transferabilityData}
            linkCurvature={0.5}
            node={<CompanyTransferabilitySankeyNode />}
            link={<CompanyTransferabilitySankeyLink />}
            nodePadding={60}
            nodeWidth={24}
            margin={{
              left: 0,
              right: SANKEY_MARGIN_RIGHT,
              top: SANKEY_MARGIN_TOP,
              bottom: 0,
            }}
          />
        </ResponsiveContainer>
      </StyledContainer>
      <StyledFooter>Percentage of transfers by volume</StyledFooter>
    </SurfaceZeroCard>
  );
};

export default CompanyTransferabilityCard;
