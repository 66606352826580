import PropTypes from "prop-types";
import React from "react";
import { Navigate, Route, Routes, Outlet } from "react-router-dom";
import styled from "styled-components";
import { PageSection } from "yuka";

import { ROUTING_PATH } from "./constants";

import AccountSettings from "../account";
import ChangeEmailConfirm from "../account/ChangeEmailConfirm";
import DocView from "../agreements/DocView";
import {
  HOME_PAGE_FEATURE_NAME,
  PORTFOLIOS_FEATURE_NAME,
  UPDATED_COMPANY_PROFILE_FEATURE_NAME,
} from "../api/constants";
import BannerList from "../banner/BannerList";
import CompanyView from "../company";
import CompanyList from "../company-list";
import useHasFeatureAccess from "../company/hooks/useHasFeatureAccess";
import CompanyProfile from "../CompanyProfile";
import GlobalNav from "../global-nav";
import Insights from "../insights";
import Login from "../login";
import LoginBase from "../login/LoginBase";
import NewUserSetPasswordForm from "../login/NewUserSetPasswordForm";
import ResetPasswordConfirm from "../login/reset-password/ResetPasswordConfirm";
import ResetPasswordForm from "../login/reset-password/ResetPasswordForm";
import ResetPasswordRequest from "../login/reset-password/ResetPasswordRequest";
import Portfolios from "../Portfolios";
import ToastList from "../toast/ToastList";
import LoadingSpinner from "../utils/LoadingSpinner";
import MarketActivity from "../market-activity";
import Superchart from "../superchart";

const StyledPageSection = styled(PageSection)`
  flex-direction: column;
  overflow: hidden;
  height: 100vh;
  width: 100%;
`;

const FeatureProtectedRoute = (props) => {
  const [hasFeatureAccess, featureAccessLoading] = useHasFeatureAccess(
    props.featureName
  );

  if (featureAccessLoading) {
    return <LoadingSpinner />;
  }

  return (
    <React.Fragment>
      {hasFeatureAccess ? <Outlet /> : <Navigate to={ROUTING_PATH.LOGIN()} />}
    </React.Fragment>
  );
};

FeatureProtectedRoute.propTypes = {
  featureName: PropTypes.string.isRequired,
};

const MainRoutes = () => {
  const [homePageFeatureAccess] = useHasFeatureAccess(HOME_PAGE_FEATURE_NAME);
  const [portfoliosFeatureAccess] = useHasFeatureAccess(
    PORTFOLIOS_FEATURE_NAME
  );
  // We built the new company profile from the ground up so we'll select which component to render
  // based on feature access.
  const [updatedCompanyProfileFeatureAccess] = useHasFeatureAccess(
    UPDATED_COMPANY_PROFILE_FEATURE_NAME
  );
  const CompanyProfileComponent = updatedCompanyProfileFeatureAccess
    ? CompanyProfile
    : CompanyView;

  return (
    <>
      <BannerList />
      <Routes>
        <Route
          path={ROUTING_PATH.LOGIN()}
          element={
            <LoginBase>
              <Login />
            </LoginBase>
          }
        />
        <Route
          path={ROUTING_PATH.RESET_PASSWORD()}
          element={
            <LoginBase>
              <ResetPasswordRequest />
            </LoginBase>
          }
        />
        <Route
          path={ROUTING_PATH.RESET_PASSWORD_CONFIRM()}
          element={
            <LoginBase>
              <ResetPasswordConfirm />
            </LoginBase>
          }
        />
        <Route
          path={`${ROUTING_PATH.SET_PASSWORD_BASE()}:token`}
          element={
            <LoginBase>
              <ResetPasswordForm />
            </LoginBase>
          }
        />
        <Route
          path={`${ROUTING_PATH.SET_PASSWORD_NEW_USER()}:token`}
          element={
            <LoginBase>
              <NewUserSetPasswordForm />
            </LoginBase>
          }
        />
        <Route
          path={`${ROUTING_PATH.ACCOUNT_CHANGE_EMAIL_CONFIRM()}:token`}
          element={
            <LoginBase>
              <ChangeEmailConfirm />
            </LoginBase>
          }
        />
        <Route
          path={ROUTING_PATH.ACCOUNT()}
          element={
            <StyledPageSection>
              <GlobalNav />
              <AccountSettings />
            </StyledPageSection>
          }
        />
        <Route path={`${ROUTING_PATH.AGREEMENT()}`} element={<DocView />} />
        <Route path={`${ROUTING_PATH.AGREEMENTS()}`} element={<DocView />} />
        <Route
          path={`${ROUTING_PATH.AGREEMENTS()}:type`}
          element={<DocView />}
        />
        <Route
          path={ROUTING_PATH.HOME()}
          element={
            <FeatureProtectedRoute featureName={HOME_PAGE_FEATURE_NAME} />
          }
        >
          <Route
            path=""
            element={
              <StyledPageSection>
                <GlobalNav />
                <span>Home</span>
              </StyledPageSection>
            }
          />
        </Route>
        <Route
          path="/company/:id"
          exact
          element={
            <StyledPageSection>
              <GlobalNav />
              <CompanyProfileComponent />
            </StyledPageSection>
          }
        />
        <Route
          path={ROUTING_PATH.PORTFOLIOS()}
          element={
            <FeatureProtectedRoute featureName={PORTFOLIOS_FEATURE_NAME} />
          }
        >
          <Route
            path=""
            element={
              <StyledPageSection>
                <GlobalNav />
                <Portfolios />
              </StyledPageSection>
            }
          />
        </Route>
        <Route
          path={`${ROUTING_PATH.PORTFOLIOS()}:id`}
          element={
            <FeatureProtectedRoute featureName={PORTFOLIOS_FEATURE_NAME} />
          }
        >
          <Route
            path=""
            element={
              <StyledPageSection>
                <GlobalNav />
                <Portfolios />
              </StyledPageSection>
            }
          />
        </Route>
        <Route
          path={ROUTING_PATH.COMPANIES()}
          element={
            <StyledPageSection>
              <GlobalNav />
              <CompanyList />
            </StyledPageSection>
          }
        />
        {(updatedCompanyProfileFeatureAccess && portfoliosFeatureAccess) || (
          <Route
            path={ROUTING_PATH.MARKET_ACTIVITY()}
            element={
              <StyledPageSection>
                <GlobalNav />
                <MarketActivity />
              </StyledPageSection>
            }
          />
        )}
        {updatedCompanyProfileFeatureAccess || (
          <Route
            path={`${ROUTING_PATH.SUPERCHARTS()}:id?`}
            element={
              <StyledPageSection>
                <GlobalNav />
                <Superchart />
              </StyledPageSection>
            }
          />
        )}
        <Route
          path={ROUTING_PATH.INSIGHTS()}
          element={
            <StyledPageSection>
              <GlobalNav />
              <Insights />
            </StyledPageSection>
          }
        />
        <Route
          path="*"
          element={
            <Navigate
              to={
                homePageFeatureAccess
                  ? ROUTING_PATH.HOME()
                  : ROUTING_PATH.COMPANIES()
              }
              replace
            />
          }
        />
      </Routes>
      <ToastList />
    </>
  );
};

export default MainRoutes;
