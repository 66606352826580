import React from "react";
import { ThemeProvider, useTheme } from "styled-components";
import _ from "lodash";

import defaultTheme from "./defaultTheme";
import { __internalsSurfaceContext as SurfaceContext } from "./Layout/internal/Surface";
import { DEFAULT_COLOR_THEME } from "./StylingConstants";

const useYukaTheme = () => {
  const theme = useTheme();
  const computedTheme = React.useMemo(() => ({ ...defaultTheme, ...theme }), [theme]);
  return computedTheme;
};

/*
 * Merges default styles with user-provided theme. We only propagate values supported in the
 * base object, so no extra user input is passed through.
 */
const mergeWithDefaultOptions = (base, override) => {
  if (!override || !_.isObject(override) || !Object.keys(override).length) {
    return _.cloneDeep(base);
  }
  const newOptions = {};
  Object.keys(base).forEach(key => {
    // Possibly recurse into nested objects.
    let nextOptions = override[key] || base[key];
    if (!Array.isArray(override[key]) && _.isObject(base[key]) && _.isObject(override[key])) {
      nextOptions = mergeWithDefaultOptions(base[key], override[key]);
    }
    newOptions[key] = nextOptions;
  });
  return newOptions;
};

// eslint-disable-next-line react/prop-types
const YukaThemeProvider = ({ theme = {}, children }) => {
  const parentTheme = useYukaTheme();
  const computedTheme = React.useMemo(() => {
    const baseTheme = {
      ...defaultTheme,
      ...parentTheme,
      ...theme,
    };
    const colors = mergeWithDefaultOptions(DEFAULT_COLOR_THEME, baseTheme.colors);
    const button = mergeWithDefaultOptions(defaultTheme.button, baseTheme.button);
    const iconButton = mergeWithDefaultOptions(defaultTheme.iconButton, baseTheme.iconButton);
    return {
      ...baseTheme,
      colors,
      button,
      iconButton,
    };
  }, [theme, parentTheme]);

  const provider = <ThemeProvider theme={computedTheme}>{children}</ThemeProvider>;
  // Intentionally only check if provided - do not want to insert providers unintentionally
  if (theme.surfaceLevel) {
    return <SurfaceContext.Provider value={theme.surfaceLevel}>{provider}</SurfaceContext.Provider>;
  }
  return provider;
};

export { YukaThemeProvider, useYukaTheme };
