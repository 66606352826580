import React, { useMemo } from "react";
import { Fonts } from "yuka";

import CompanyQuickPriceCard from "./cards/CompanyQuickPriceCard";
import EmptyCompanyCarousel from "./EmptyCompanyCarousel";
import { CarouselContainer, StyledCarouselActions } from "./StyledComponents";

import { API_ENDPOINTS } from "../api/constants";
import useFetch from "../api/useFetch";
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "../hdYuka/Carousel";
import { WEEKLY } from "../utils/constants";

const MostActiveCompanies = () => {
  const mostActiveCompaniesQuery = useFetch(
    API_ENDPOINTS.COMPANY_LATEST_ORDER_FLOW(),
    {
      time_frame: WEEKLY,
      most_active: true,
      "page[size]": 12,
    }
  );

  const mostActiveCompanies = useMemo(() => {
    if (!mostActiveCompaniesQuery.isSuccess) {
      return null;
    }
    return mostActiveCompaniesQuery.cleanedData.data.map(
      (company) => company.apiId
    );
  }, [
    mostActiveCompaniesQuery.isSuccess,
    mostActiveCompaniesQuery.cleanedData,
  ]);

  if (!mostActiveCompanies || mostActiveCompanies.length === 0) {
    return <EmptyCompanyCarousel title="Most active companies" />;
  }

  return (
    <CarouselContainer>
      <Fonts.Headline1theme80>Most active companies</Fonts.Headline1theme80>
      <Carousel slidesToScroll={6}>
        <CarouselContent>
          {mostActiveCompanies.map((companyId, index) => (
            <CarouselItem key={index}>
              <CompanyQuickPriceCard companyId={companyId} />
            </CarouselItem>
          ))}
        </CarouselContent>
        <StyledCarouselActions>
          <CarouselPrevious />
          <CarouselNext />
        </StyledCarouselActions>
      </Carousel>
    </CarouselContainer>
  );
};

export default MostActiveCompanies;
