import _ from "lodash";
import PropTypes from "prop-types";
import React, { useCallback, useContext, useMemo, useState } from "react";
import ReactDOM from "react-dom";
import styled, { css } from "styled-components";
import {
  Fonts,
  Button,
  ButtonStyles,
  YukaThemeProvider,
  Z_INDEXES,
} from "yuka";

import { AuthContext } from "../auth";
import { KEY_ACTION_BUTTON_CONTAINER } from "../hdYuka/yukaThemeOverrides";
import useElementDimensions from "../utils/useElementDimensions";
import useWindowDimensions from "../utils/useWindowDimensions";

const IndicatorArrow = (
  <svg width="16" height="10" viewBox="0 0 16 10" fill="none">
    <path d="M8 0L16 10H0L8 0Z" fill="#5A686A" />
  </svg>
);

const StyledIndicator = styled.div`
  height: 10px;
  width: 16px;
  position: absolute;
  z-index: ${Z_INDEXES.zIndexModal};
`;

const StyledWalkthroughContainer = styled.div`
  position: absolute;
  box-sizing: border-box;
  z-index: ${Z_INDEXES.zIndexModal};
  border-radius: 12px;
  background: #5a686a;
  padding: 24px;
  display: flex;
  gap: 24px;
  width: ${(props) => props.$width}px;
  flex-direction: column;

  ${(props) =>
    props.$center &&
    css`
      left: 50%;
      top: 50%;
      margin-left: -${props.$width / 2}px;
      margin-top: -${props.$height / 2}px;
    `}
`;

const StyledHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const StyledFooter = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`;

const DEFAULT_WIDTH = 400;

const StyledBody2 = styled(Fonts.Body2theme80)`
  line-height: 1.5; // Dataverse update for walkthroughs.
`;

/**
 * Presentational component to render a step of a tutorial.
 *
 * @param {object} props
 *
 * @returns {React.Element}
 */
const Walkthrough = ({ steps, initialStep, onFinish }) => {
  const { width: windowWidth, height: windowHeight } = useWindowDimensions();
  const [contentRef, , { height }] = useElementDimensions();
  const [currentStepIndex, setCurrentStepIndex] = useState(initialStep);
  const { user } = useContext(AuthContext);

  // Use query selector to position the indicator and dropdown correctly.
  const relativeElements = steps.map((step) =>
    step.positionSelector ? document.querySelector(step.positionSelector) : null
  );
  const currentStep = useMemo(
    () => steps[currentStepIndex],
    [steps, currentStepIndex]
  );

  // Leave this here for posterity from copy-over job from ZX, we may need to bring it back at
  // some point.
  //
  // React.useLayoutEffect(() => {
  //   if (props.scroll) {
  //     const scrollAnchor = document.querySelector(props.scroll);
  //     if (scrollAnchor) {
  //       scrollAnchor.scrollIntoView();
  //       relativeElement.scrollIntoView();
  //     }
  //   }
  // }, [relativeElement, props.scroll]);

  const {
    top: parentTop,
    left: parentLeft,
    width: parentWidth,
    height: parentHeight,
  } = relativeElements[currentStepIndex]?.getBoundingClientRect?.() || {};

  const nextStep = useCallback(() => {
    if (currentStepIndex === steps.length - 1) {
      onFinish({ stepsCompleted: currentStepIndex + 1 });
      return;
    }
    setCurrentStepIndex(currentStepIndex + 1);
  }, [currentStepIndex, steps, onFinish]);

  const skipRemaining = useCallback(() => {
    onFinish({ stepsCompleted: currentStepIndex + 1 });
  }, [onFinish, currentStepIndex]);

  const { top, left } = useMemo(() => {
    if (currentStep.positionSelector === null) {
      return { top: null, left: null };
    }
    let top = Math.max(0, parentTop + parentHeight + 8);
    let left = Math.max(
      0,
      parentLeft + parentWidth / 2 - (currentStep.width || DEFAULT_WIDTH) / 2
    );
    // With a flick of the wrist, ensure the dropdown is within the window bounds.
    const right = left + (currentStep.width || DEFAULT_WIDTH);
    // Height doesn't include padding so we need to add 48.
    // We also add 10 because the indicator takes up 10px above the "top" of the element.
    const bottom = top + height + 48 + 10;
    if (right > windowWidth) {
      left -= right - windowWidth;
    }
    if (bottom > windowHeight) {
      top -= bottom - windowHeight;
    }
    return { top, left };
  }, [
    currentStep,
    height,
    windowWidth,
    windowHeight,
    parentTop,
    parentHeight,
    parentLeft,
    parentWidth,
  ]);

  if (!user?.walkthrough_progress?.has_viewed_redesign_introduction) {
    // We do a short circuit here. No walkthroughs can be rendered until the user has
    // acknowledged the welcome page. For now that welcome page happens to be the
    // redesign introduction.
    return null;
  }

  return (
    <YukaThemeProvider
      theme={{
        button: {
          [ButtonStyles.CTA]: {
            container: KEY_ACTION_BUTTON_CONTAINER,
          },
          [ButtonStyles.RAISED]: {
            container: KEY_ACTION_BUTTON_CONTAINER,
          },
        },
      }}
    >
      {currentStep.positionSelector && (
        <StyledIndicator
          style={{ top, left: parentLeft + parentWidth / 2 - 8 }}
        >
          {IndicatorArrow}
        </StyledIndicator>
      )}
      {currentStep.positionSelector ? (
        <StyledWalkthroughContainer
          ref={contentRef}
          $width={currentStep.width || DEFAULT_WIDTH}
          style={{ top: top + 10, left }}
        >
          <StyledHeader>
            <Fonts.Headline2theme100>
              {currentStep.title}
            </Fonts.Headline2theme100>
            <Fonts.Body2theme50>
              {currentStepIndex + 1} of {steps.length}
            </Fonts.Body2theme50>
          </StyledHeader>
          <StyledBody2>{currentStep.text}</StyledBody2>
          <StyledFooter>
            <Button buttonStyle={ButtonStyles.RAISED} onClick={skipRemaining}>
              Skip
            </Button>
            <Button buttonStyle={ButtonStyles.CTA} onClick={nextStep}>
              {currentStepIndex === steps.length - 1 ? "Finish" : "Next"}
            </Button>
          </StyledFooter>
        </StyledWalkthroughContainer>
      ) : (
        ReactDOM.createPortal(
          <StyledWalkthroughContainer
            ref={contentRef}
            $height={height}
            $width={currentStep.width || DEFAULT_WIDTH}
            $center={true}
          >
            <StyledHeader>
              <Fonts.Headline3theme100>
                {currentStep.title}
              </Fonts.Headline3theme100>
              <Fonts.Body2theme50>
                {currentStepIndex + 1} of {steps.length}
              </Fonts.Body2theme50>
            </StyledHeader>
            <StyledBody2>{currentStep.text}</StyledBody2>
            <StyledFooter>
              <Button buttonStyle={ButtonStyles.RAISED} onClick={skipRemaining}>
                Skip
              </Button>
              <Button buttonStyle={ButtonStyles.CTA} onClick={nextStep}>
                {currentStepIndex === steps.length - 1 ? "Finish" : "Next"}
              </Button>
            </StyledFooter>
          </StyledWalkthroughContainer>,
          document.body
        )
      )}
    </YukaThemeProvider>
  );
};

Walkthrough.propTypes = {
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      positionSelector: PropTypes.string,
      title: PropTypes.string,
      text: PropTypes.node,
      width: PropTypes.number,
    })
  ).isRequired,
  initialStep: PropTypes.number,
  width: PropTypes.number,
  onFinish: PropTypes.func,
};

Walkthrough.defaultProps = {
  initialStep: 0,
  width: 400,
  onFinish: _.noop,
};

export default Walkthrough;
