import React from "react";
import styled from "styled-components";
import { Card, CardStyles, Fonts, HyperLink, LinkTypes } from "yuka";

import { API_ENDPOINTS } from "../../api/constants";
import useFetch from "../../api/useFetch";
import MixpanelEvents from "../../utils/mixpanel/MixpanelEvents";
import { useCompany } from "../hooks";
import { List, ListItem } from "../StyledComponents";

const StyledCardContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-bottom: 16px;
`;

const formatWebsiteUrl = (rawUrl) =>
  rawUrl ? rawUrl.replace("http://", "").replace("www.", "") : "";

const AboutCard = () => {
  const [company, companyIsLoading] = useCompany();

  const sectorQuery = useFetch(
    API_ENDPOINTS.COMPANY_SECTORS(),
    {
      company: company?.zb_id,
    },
    {
      enabled: Boolean(company?.zb_id),
    }
  );

  if (companyIsLoading || sectorQuery.isLoading) {
    return (
      <Card title="About">
        <span>Loading...</span>
      </Card>
    );
  }

  if (!company || sectorQuery.isError) {
    return (
      <Card cardStyle={CardStyles.TITLE} title="About">
        <Fonts.Body1theme30>An error has occurred.</Fonts.Body1theme30>
      </Card>
    );
  }

  return (
    <Card title="About" cardStyle={CardStyles.PADDED}>
      <StyledCardContent>
        <Fonts.Body1theme80>{company.description}</Fonts.Body1theme80>
        <List>
          <ListItem>
            <Fonts.Body1theme50>Legal Name</Fonts.Body1theme50>
            <Fonts.Body1theme80>
              {company.legal_name || company.name}
            </Fonts.Body1theme80>
          </ListItem>
          <ListItem>
            <Fonts.Body1theme50>Headquarters</Fonts.Body1theme50>
            <Fonts.Body1theme80>{company.headquarters}</Fonts.Body1theme80>
          </ListItem>
          <ListItem>
            <Fonts.Body1theme50>Sectors</Fonts.Body1theme50>
            <Fonts.Body1theme80>
              {sectorQuery.cleanedData.data.map((sector) => (
                <div key={`sector_${sector.name}`}>{sector.name}</div>
              ))}
            </Fonts.Body1theme80>
          </ListItem>
          <ListItem>
            <Fonts.Body1theme50>Website</Fonts.Body1theme50>
            <Fonts.Body1theme80>
              <HyperLink
                onClick={() =>
                  MixpanelEvents.navigateToCompanyWebsite(
                    company.name,
                    company.url
                  )
                }
                url={company.url}
                linkType={LinkTypes.EXTERNAL_LINK}
              >
                {formatWebsiteUrl(company.url)}
              </HyperLink>
            </Fonts.Body1theme80>
          </ListItem>
        </List>
      </StyledCardContent>
    </Card>
  );
};

export default AboutCard;
