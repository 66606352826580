import { useMemo } from "react";
import { useParams } from "react-router-dom";
import _ from "lodash";

import { API_ENDPOINTS } from "../../api/constants";
import useFetch from "../../api/useFetch";

/**
 * Fetches a company's latest order flow data (information about the company) given the ID,
 * specified in the URL using a route with `/:id` in it.
 *
 * @returns {unknown}
 */
const useCompanyFromURL = () => {
  const params = useParams();
  const keyCompanyId = _.get(params, "id");

  const keyCompanyLatestOrderFlowQuery = useFetch(
    API_ENDPOINTS.COMPANY_LATEST_ORDER_FLOW(),
    {
      id: keyCompanyId,
      time_frame: "weekly",
    },
    { enabled: Boolean(keyCompanyId) }
  );

  return useMemo(
    () => keyCompanyLatestOrderFlowQuery?.cleanedData?.data[0] || {},
    [keyCompanyLatestOrderFlowQuery]
  );
};

export default useCompanyFromURL;
