import { SECURITY_TYPE_MARKET_INDICES } from "./constants";

import { API_ENDPOINTS } from "../../api/constants";
import useFetch from "../../api/useFetch";

const usePrivateMarketIndices = () => {
  const privateMarketIndicesQuery = useFetch(
    API_ENDPOINTS.COMPANY_AND_SECURITY_SEARCH(),
    {
      "page[size]": 10000,
      security_type: SECURITY_TYPE_MARKET_INDICES,
      removed: false,
    }
  );
  return privateMarketIndicesQuery.cleanedData?.data;
};

export default usePrivateMarketIndices;
