import PropTypes from "prop-types";
import React, { useMemo } from "react";
import { useFormState } from "react-final-form";

import useLatestReportedMarks from "./useLatestReportedMarks";
import useNoDataErrorState from "./useNoDataErrorState";
import ValuationPriceComponentRow from "./ValuationPriceComponentRow";

const FundMarksPriceComponentRow = (props) => {
  const {
    values: { valuation_date: valuationDate },
  } = useFormState();

  const [reportedMarksData, , reportedMarksDataIsLoading] =
    useLatestReportedMarks(
      props.companyId,
      valuationDate,
      !props.initialValue && Boolean(props.companyId)
    );

  const computedValue = useMemo(
    () =>
      props.initialValue
        ? props.initialValue
        : Number(reportedMarksData?.weighted_avg) || null,
    [props.initialValue, reportedMarksData?.weighted_avg]
  );

  const error = useNoDataErrorState(
    props.initialValue || !valuationDate || reportedMarksDataIsLoading,
    computedValue
  );

  return (
    <ValuationPriceComponentRow
      {...props}
      errorText={error}
      editable={!props.initialValue}
      initialValue={computedValue}
    />
  );
};

FundMarksPriceComponentRow.propTypes = {
  initialValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  companyId: PropTypes.string,
};

export default FundMarksPriceComponentRow;
