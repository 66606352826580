import PropTypes from "prop-types";
import React, { useEffect, useMemo } from "react";
import { useForm, useFormState } from "react-final-form";
import styled from "styled-components";
import {
  Button,
  ButtonStyles,
  ColorPalette,
  FinalFormField,
  Fonts,
  YukaThemeProvider,
} from "yuka";

import useComponents from "./useComponents";

import { expandedMoneyFormat } from "../../utils/displayFormatUtils";
import getWeightedValueForComponent from "../utils/getWeightedValueForComponent";

const StyledFooterRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  border-top: 1px solid ${ColorPalette.white15};
  padding: 16px;

  > :first-child {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
`;

const StyledOverlineUnit = styled(Fonts.Overlinetheme50)`
  margin-left: 8px;
`;

/**
 * Computes the valuation based on the available form values, by adding them up.
 * Also renders the previous valuation + current date ƒorm fields.
 */
const ComputedValuationFooterRow = ({
  valuation,
  onClose,
  onSubmit,
  onDelete,
}) => {
  const { values, valid, submitting } = useFormState();
  const { change } = useForm();

  const components = useComponents(values);
  const sumOfWeights = useMemo(
    () =>
      Object.keys(components).reduce(
        (acc, key) => acc + (components[key].weight || 0),
        0
      ),
    [components]
  );

  const computedValuation = useMemo(() => {
    if (valuation) {
      return valuation.computed_value;
    }
    if (sumOfWeights !== 100) {
      return null;
    }
    if (
      Object.keys(components).some(
        (key) =>
          Boolean(components[key].weight) &&
          !components[key].currentValue &&
          components[key].currentValue !== 0
      )
    ) {
      // Null value acts as a sentinel to indicate that there is an un-computed value with non-zero
      // weighting; therefore the valuation cannot be computed.
      return null;
    }

    const fieldContributingValues = components.map((component) =>
      getWeightedValueForComponent(
        component.currentValue,
        component.weight || 0,
        component.componentType,
        values
      )
    );

    if (fieldContributingValues.some((value) => value === null)) {
      // Null value acts as a sentinel to indicate that there is an un-computed value with non-zero
      // weighting; therefore the valuation cannot be computed.
      return null;
    }

    return fieldContributingValues.reduce((acc, value) => acc + value, 0);
  }, [components, valuation, values, sumOfWeights]);

  useEffect(() => {
    change("computed_valuation", computedValuation);
  }, [computedValuation, change]);

  return (
    <YukaThemeProvider theme={{ surfaceLevel: 2 }}>
      <StyledFooterRow>
        <div>
          {/* Sneaky form field to handle submission validation. */}
          <FinalFormField
            type="hidden"
            name="computed_valuation"
            validate={() => {
              if (valuation || computedValuation !== null) {
                return undefined;
              }
              return "Valuation cannot be computed with unpopulated fields.";
            }}
          />
          <Fonts.Overlinetheme50>Calculated valuation</Fonts.Overlinetheme50>
          <div>
            <Fonts.Headline3theme80>
              {computedValuation || computedValuation === 0
                ? expandedMoneyFormat(computedValuation, 2)
                : "--"}
            </Fonts.Headline3theme80>
            <StyledOverlineUnit>USD / Share</StyledOverlineUnit>
          </div>
        </div>
        <div>
          <Button buttonStyle={ButtonStyles.RAISED} onClick={onClose}>
            {valuation ? "Close" : "Cancel"}
          </Button>
          {valuation ? (
            <Button buttonStyle={ButtonStyles.RAISED} onClick={onDelete}>
              Delete
            </Button>
          ) : (
            <Button
              onClick={onSubmit}
              buttonStyle={ButtonStyles.CTA}
              disabled={valuation || !valid || submitting}
            >
              Save
            </Button>
          )}
        </div>
      </StyledFooterRow>
    </YukaThemeProvider>
  );
};

ComputedValuationFooterRow.propTypes = {
  onDelete: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  valuation: PropTypes.shape({
    computed_value: PropTypes.number,
  }),
};

export default ComputedValuationFooterRow;
