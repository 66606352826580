import _ from "lodash";
import PropTypes from "prop-types";
import React, { useEffect, useMemo } from "react";

import { useSortBy, useRowSelect, useTable } from "react-table";

import DefaultHeaderRenderer from "./DefaultHeaderRenderer";
import DefaultCellRenderer from "./DefaultCellRenderer";
import EmptyTablePlaceholder from "./EmptyTablePlaceholder";

import {
  StyledLoadingSpinnerContainer,
  StyledTableBody,
  StyledTableHead,
  StyledRow,
  StyledTableScrollableArea,
  StyledPlaceholderCell,
} from "./StyledComponents";
import useScrollable from "../utils/useScrollable";
import LoadingSpinner from "./utils/LoadingSpinner";
import getEventHandlerProps from "./utils/getEventHandlerProps";
import { useYukaTheme, YukaThemeProvider } from "../ThemeContext";
import useTableStyles from "./hooks/useTableStyles";
import useEditableData from "./hooks/useEditableData";
import useKeyboardNavigation from "./hooks/useKeyboardNavigation";
import useTableMetadata from "./hooks/useTableMetadata";
import RenderedTableCell from "./RenderedTableCell";
import RenderedTableHeader from "./RenderedTableHeader";
import useElementContentRect from "../utils/useElementContentRect";

const EMPTY_DATA = [];
const EMPTY_COLUMNS = [];

/**
 * Table component that wraps react-table's implementation.
 *
 */
const Table = ({
  data = EMPTY_DATA,
  columns: userColumns = EMPTY_COLUMNS,
  updateData,
  isLoading = false,
  paginationFunc = _.noop,
  isPaginationLoading = false,
  onRowClick,
  onRowRightClick,
  onRowMouseOut,
  onRowMouseOver,
  manualSortBy = false,
  sortState = [],
  sortTypes = {},
  usePercentageColumnWidths = false,
  showBorders = false,
  onRowSelectStateChange = _.noop,
  ...props
}) => {
  const tableStyles = useTableStyles();
  const theme = useYukaTheme();
  // useEditableData will have no effect if `updateData` is not provided.
  const {
    selectedCell,
    setSelectedCell,
    editingCell,
    setEditingCell,
    determineEditingStateOnCellClick,
    debouncedUpdate,
  } = useEditableData(data, userColumns, updateData);

  // const tableScrollableAreaRef = useRef(null);
  // A browser scrollbar appearing on its own accord when content needs to be vertically scrollable
  // does NOT trigger the 'resize' event, so we need to take the minimum of the table's clientWidth,
  // and the width that gets tracked by window resizes.
  const [tableScrollableAreaRef, currentTableScrollableArea, { width: actualTableWidth }] =
    useElementContentRect();

  // Obtain some metadata about how the table's actually going to render.
  const { specifiedTableWidth, fixedWidthColumnWidth, numberOfStickyColumns } =
    useTableMetadata(userColumns);

  // Process the user columns to produce table-ingestible column objects.
  const columnsForTable = useMemo(
    () =>
      userColumns.map((column, index) => {
        const partialColumn = {
          ...getEventHandlerProps({
            onCellClick: onRowClick,
            onCellRightClick: onRowRightClick,
            ...column, // Allows a column-defined handler to override Table-defined handlers.
          }),
          cellRenderer: value => value.value,
          Header: DefaultHeaderRenderer,
          Cell: DefaultCellRenderer,
          ...column,
          // Possibly override column.sticky and width if `usePercentageColumnWidths` is true.
          sticky: !usePercentageColumnWidths && Boolean(column.sticky),
          // Enforce that every column has a width value in pixels.
          width:
            (usePercentageColumnWidths || actualTableWidth >= specifiedTableWidth) &&
            !column.useFixedWidth
              ? (actualTableWidth - fixedWidthColumnWidth) *
                (column.width / (specifiedTableWidth - fixedWidthColumnWidth))
              : column.width,
          disableSortBy: !column.sortable,
          index,
        };
        // Handle selecting cells by clicking if and only if cells in this column are editable.
        if (debouncedUpdate) {
          const eventHandlerFunc = partialColumn.onCellClick;
          // Wrap the onCellClick to include the selected/editing cell logic.
          partialColumn.onCellClick = ({ row, isCellSelected, ...args }) => {
            // isCellSelected is determined by the individual cells instead of in this
            // hook to save re-computations of the table column objects based on 'selectedCell'.
            eventHandlerFunc?.({ row, isCellSelected, ...args });
            determineEditingStateOnCellClick(row, column, isCellSelected);
          };
        }
        return partialColumn;
      }),
    [
      fixedWidthColumnWidth,
      specifiedTableWidth,
      usePercentageColumnWidths,
      userColumns,
      onRowClick,
      onRowRightClick,
      debouncedUpdate,
      actualTableWidth,
      determineEditingStateOnCellClick,
    ]
  );

  const initialState = useMemo(() => {
    const sortBy = [];
    const state = { sortBy };
    if (sortState && sortState.length) {
      // props.sortState conforms to the same shape as react-table's sortBy state prop.
      state.sortBy = sortState;
    }
    return state;
  }, [sortState]);

  const tableInstance = useTable(
    {
      columns: columnsForTable,
      initialState,
      data,
      updateData: debouncedUpdate,
      // selectedCell and editingCell will be available to all cells, for them
      // to determine if they are selected and/or being edited.
      numberOfStickyColumns,
      selectedCell,
      editingCell,
      sortTypes: {
        // Provides case-insensitive alphanumeric sorting.
        alphanumeric: (a, b, columnId) => {
          const rowA = a.values[columnId];
          const rowB = b.values[columnId];
          if (typeof rowA === "string" && typeof rowB === "string") {
            if (rowA.toLowerCase() === rowB.toLowerCase()) return 0;
            return rowA.toLowerCase() > rowB.toLowerCase() ? 1 : -1;
          }
          if (Number(rowA) === Number(rowB)) return 0;
          return Number(rowA) > Number(rowB) ? 1 : -1;
        },
        ...sortTypes,
      },
      // If updateData is provided, then we do not want to reset sorting when editing table values.
      autoResetSortBy: !debouncedUpdate,
      autoResetSelectedRows: false,
      sortState,
      manualSortBy,
      showBorders,
      ...props,
    },
    useSortBy,
    useRowSelect
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    columns,
    state: { selectedRowIds },
  } = tableInstance;

  useKeyboardNavigation(
    rows,
    columns,
    selectedCell,
    setSelectedCell,
    editingCell,
    setEditingCell,
    debouncedUpdate
  );

  // Exposes the internal row-selected state to clients.
  useEffect(
    () => onRowSelectStateChange?.(selectedRowIds),
    [onRowSelectStateChange, selectedRowIds]
  );

  useScrollable({
    scrollCurrent: currentTableScrollableArea,
    onScrollUp: _.noop,
    onScrollDown: distanceFromBottom => {
      if (
        !isPaginationLoading &&
        paginationFunc &&
        paginationFunc !== _.noop &&
        distanceFromBottom < 100
      ) {
        paginationFunc();
      }
    },
  });

  // Apply default here so we don't break legacy support
  const emptyTablePlaceholder = _.isUndefined(props.emptyTablePlaceholder)
    ? "No data to display"
    : props.emptyTablePlaceholder;

  // Since the table cannot know how large it is going to be before it renders any rows, we render
  // the loading spinner so the user doesn't see a really squished table first. We need
  // to include this hack to allow our snapshot testing to continue to work. __is_testing__ should
  // not be used non-testing environments.
  const isInitialRender = actualTableWidth === 0 && !theme.__is_testing__;

  return (
    <YukaThemeProvider theme={{ ...theme, tableStyles }}>
      <StyledTableScrollableArea
        $usePercentageColumnWidths={usePercentageColumnWidths}
        $width={specifiedTableWidth} // Ignored if usePercentageColumnWidths is provided.
        {...getTableProps()}
        ref={tableScrollableAreaRef}
      >
        {!props.disableHeader && (
          <StyledTableHead
            $width={
              usePercentageColumnWidths
                ? actualTableWidth
                : Math.max(actualTableWidth, specifiedTableWidth)
            }
          >
            {headerGroups.map((headerGroup, i) => (
              <StyledRow key={`header_group_${i}`} {...headerGroup.getHeaderGroupProps()}>
                {
                  // Loop over the headers in each row.
                  headerGroup.headers.map((column, j) => {
                    const tooltipContent =
                      column.headerTooltip && typeof column.headerTooltip === "function"
                        ? column.headerTooltip(column)
                        : null;

                    return (
                      <RenderedTableHeader
                        key={`rendered_table_header_${i}_${j}`}
                        column={column}
                        tooltipContent={tooltipContent}
                      />
                    );
                  })
                }
              </StyledRow>
            ))}
          </StyledTableHead>
        )}
        <StyledTableBody
          {...getTableBodyProps()}
          $width={
            usePercentageColumnWidths
              ? actualTableWidth
              : Math.max(actualTableWidth, specifiedTableWidth)
          }
        >
          {!isLoading &&
            !isInitialRender && // Loop over the table rows.
            rows.map((row, index) => {
              // Prepare the row for display using react-table magic.
              // https://github.com/TanStack/table/blob/v7/docs/src/pages/docs/api/useTable.md
              // "Lazily" prepares a row for rendering, every row intended for rendering needs to be passed
              // to this function prior. This logic allows us to only prepare and render rows
              // that are visible in the scrollable parent container.

              // Determine how many rows should be visible in the scrollable area.
              const numberOfRowsVisible = Math.floor(
                (currentTableScrollableArea?.getBoundingClientRect().height || 0) /
                  (tableStyles.cells?.height || 1)
              );
              const parentScrollPosition = currentTableScrollableArea?.scrollTop || 0;
              const firstVisibleRow = Math.floor(
                parentScrollPosition / (tableStyles.cells?.height || 1)
              );
              const lastVisibleRow = firstVisibleRow + numberOfRowsVisible;
              // Since the table cannot know how large it is going to be before it renders any
              // rows, we render the first 50 rows as a fallback. Once the table renders once,
              // it will know in future how many rows are visible to the user at once. When the
              // parentScrollPosition is 0 i.e. no scrolling has been done yet, then we render
              // 50 rows as a fallback.
              const rowShouldRender =
                (firstVisibleRow - 10 <= index && index <= lastVisibleRow + 10) ||
                (parentScrollPosition === 0 && index <= 50);

              if (rowShouldRender) {
                // Is the row too high or too low down to be visible?
                prepareRow(row);
                const rowProps = row.getRowProps();
                return (
                  // Apply the row props.
                  <StyledRow
                    {...rowProps}
                    key={`row_${index}`}
                    $row={row}
                    $onRowClick={onRowClick}
                    onMouseLeave={onRowMouseOut ? event => onRowMouseOut({ event, row }) : _.noop}
                    onMouseEnter={onRowMouseOver ? event => onRowMouseOver({ event, row }) : _.noop}
                  >
                    {
                      // Loop over the rows cells.
                      row.cells.map(cell => {
                        // Apply the cell props and style.

                        // Allow for a column-specified tooltip function to generate the
                        // tooltip for this cell, if applicable. Tooltips are implemented one
                        // layer above rendering "Cell", because users might want tooltip support
                        // and also full visual control over the cell rendering.
                        const tooltipContent =
                          cell.column.tooltip && typeof cell.column.tooltip === "function"
                            ? cell.column.tooltip({ ...props, ...cell })
                            : null;

                        // If the user elects to not use the DefaultCellRenderer, then the
                        // onClick has to be set up on the container, so the users' function
                        // will not have access to the full set of table props as the
                        // DefaultCellRenderer does, because those are determined by the call
                        // to cell.render("Cell") below.
                        const cellOnClick =
                          cell.column.onCellClick && cell.column.Cell !== DefaultCellRenderer
                            ? event => {
                                const { column, row, value } = cell;
                                cell.column.onCellClick({ event, column, row, value });
                              }
                            : _.noop;
                        return (
                          <RenderedTableCell
                            key={cell.getCellProps().key}
                            cell={cell}
                            row={row}
                            selectedCell={selectedCell}
                            onClick={cellOnClick}
                            tooltipContent={tooltipContent}
                          />
                        );
                      })
                    }
                  </StyledRow>
                );
              }

              // Render an empty "Loading row" because this row is not visible.
              return (
                <StyledRow key={`row_${index}`}>
                  <StyledPlaceholderCell $height={tableStyles.cells?.height || 1}>
                    --
                  </StyledPlaceholderCell>
                </StyledRow>
              );
            })}
          {!isLoading && rows.length === 0 && Boolean(emptyTablePlaceholder) && (
            <EmptyTablePlaceholder
              content={emptyTablePlaceholder}
              width={currentTableScrollableArea?.getBoundingClientRect().width}
            />
          )}
        </StyledTableBody>
        {isPaginationLoading || isLoading || isInitialRender ? (
          <StyledLoadingSpinnerContainer
            $width={currentTableScrollableArea?.getBoundingClientRect().width}
          >
            {(isPaginationLoading || isLoading || isInitialRender) && <LoadingSpinner />}
          </StyledLoadingSpinnerContainer>
        ) : null}
      </StyledTableScrollableArea>
    </YukaThemeProvider>
  );
};

Table.propTypes = {
  /**
   * An array of objects where each key is a column ID, and each value is
   * the cell value for that column. Each item in the array specifies a full row.
   */
  data: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.any })),
  /**
   * An array of objects where the object properties specify all the information necessary
   * to display a column in the table. Properties like 'sortable', 'sticky', that specify
   * how columns behave in the table live on these objects. Columns should appear in this list
   * in the order they should be presented in the table.
   */
  columns: PropTypes.arrayOf(
    PropTypes.shape({ id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]) })
  ),
  /**
   * Optional text to render if there is no table data.
   */
  emptyTablePlaceholder: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  /**
   * An optional function used to mutate the data in the table. If specified, for all
   * editable columns, this function is called when editing the cell. This function should consume
   * the full RTRowInterface object, the full RTColumnInterface object, and the updated cell value.
   */
  updateData: PropTypes.func,
  /**
   * A boolean parameter used to indicate from outside the table context if the input data
   * is loading. The table will render a loading spinner if this boolean is true.
   */
  isLoading: PropTypes.bool,
  /**
   * An optional function used to query the next page of data for the table. Is
   * automatically triggered when the user scrolls to a certain distance from the bottom of the
   * rendered table. Useful for when table data is linked to paged queries.
   */
  paginationFunc: PropTypes.func,
  /**
   * Similarly to isLoading, a boolean parameter that's used to indicate to the
   * table that a new page of data is loading and the table should not attempt to fetch another
   * page. The table will automatically call a pagination function when near the bottom of the
   * scrollable region, and this flag equalling true prevents it from fetching another page while
   * loading.
   */
  isPaginationLoading: PropTypes.bool,
  /**
   * An optional function to call when clicking a row. If `updateData` is provided, cells
   * are editable, and so clicking a cell (and therefore also its row) puts the cell in edit state.
   * Therefore, this handler probably should not redirect the user if `updateData` is provided.
   */
  onRowClick: PropTypes.func,
  /**
   * Optional row handler function to fire when the row is right-clicked. If provided, will
   * prevent default event.
   */
  onRowRightClick: PropTypes.func,
  /**
   * Optional row handler function to fire when the user moves their mouse out of a row.
   */
  onRowMouseOut: PropTypes.func,
  /**
   * Optional row handler function to fire when the user moves their mouse over a row.
   */
  onRowMouseOver: PropTypes.func,
  /**
   * Handler to expose the tables internal set of selected rows to implementors. Is called
   * with a map mapping row IDs to a boolean value, true for "is selected".
   */
  onRowSelectStateChange: PropTypes.func,
  /**
   * Boolean to indicate that sorting is handled external to the table implementation.
   * This table implementation ships with a primitive sorting facility, using the `accessor` field
   * on column objects. You may be interested in sorting the data server-side and served in pages,
   * in which case manualSortBy should be true. If primitive value sorting is acceptable, leave
   * this flag as false.
   */
  manualSortBy: PropTypes.bool,
  /**
   * Boolean flag to toggle the header. Sometimes a table should be rendered without a header
   * row, so set this flag to true to display this way.
   */
  disableHeader: PropTypes.bool,
  /**
   * Boolean to indicate that the widths specified on column objects should
   * be treated as percentages and not absolute pixel values. When this flag is set to true, the
   * table will fill its available space, and normalize the column widths so that they add up to
   * 100%, and then determine their actual width to render based on the available table space. Note
   * that you cannot simultaneously have `usePercentageColumnWidths == true` and have any columns
   * that are sticky, because side-scrolling doesn't make sense in this context. The table will
   * ignore column stickiness in this case.
   */
  usePercentageColumnWidths: PropTypes.bool,
  /**
   * Boolean whether to include column-dividing borders. Leave unset to only render
   * borders to divide rows. Note that regardless of this value, the table will never have an
   * extreme left nor extreme right border.
   */
  showBorders: PropTypes.bool,
  /**
   * Array of { id: <column_id>, desc: <boolean> } objects, indicating which columns
   * to sort by (using ID), and whether they are descending (desc = true).
   * https://react-table-v7.tanstack.com/docs/api/useSortBy#table-options
   */
  sortState: PropTypes.arrayOf(PropTypes.shape({})),
  /**
   * Object mapping the names of custom sorting functions to their function definitions. Useful for
   * enabling sort on a column when the built-in case-insensitive alphanumeric sort will not
   * suffice.
   */
  sortTypes: PropTypes.shape({
    [PropTypes.string]: PropTypes.func,
  }),
};

Table.displayName = "Table";

export default Table;
