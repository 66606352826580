import React, { useCallback } from "react";
import PropTypes from "prop-types";
import NPMModal from "react-modal";
import styled from "styled-components";

import Surface, { __internalsSurfaceContext } from "./internal/Surface";
import Card, { CardStyles } from "./Card";

import { Z_INDEXES } from "../StylingConstants";
import { headline2, FontColors } from "../Typography";
import { Button, ButtonStyles } from "../Button";

const ModalStyles = {
  BASIC: "BASIC",
  SCROLLABLE: "SCROLLABLE",
  MINIMAL: "MINIMAL", // Only use for totally custom modals that we just want the overlay for
};

const StyledHeader = styled.header`
  ${headline2}
  ${FontColors.theme100}
  padding: 16px;
  margin-bottom: 8px;
  border-radius: 12px 12px 0 0;
  flex-shrink: 0;
`;

const StyledFooter = styled.footer`
  padding: 16px;
  display: flex;
  justify-content: flex-end;
  margin-top: 8px;
  border-radius: 0 0 12px 12px;
  flex-shrink: 0;
`;

const StyledScrollContent = styled.div`
  margin: -8px 0;
  overflow-y: auto;
  flex-grow: 1;
  padding: 24px 16px;
`;

const StyledNoScrollContent = styled.div`
  overflow-y: hidden;
  flex-grow: 1;
  padding: 0 16px;
  &:first-child {
    padding-top: 16px;
  }
`;

const StyledModal = styled(Card).attrs({ cardStyle: CardStyles.BASIC })`
  width: 100%;
  height: 100%;
  padding: 0;
`;

// Remove base inherited styles, rely on child surface defined above. Just position it
const StrippedNPMModal = styled(NPMModal)`
  min-width: min(400px, 100vw);
  max-width: 100vw;
  ${props => (props.$height ? `height: ${props.$height}px;` : "")}
  max-height: 100vh;
  &:focus-visible {
    outline: none;
  }
`;

NPMModal.defaultStyles.overlay = {
  ...NPMModal.defaultStyles.overlay,
  backgroundColor: "rgba(0, 0, 0, 0.7)",
  zIndex: Z_INDEXES.zIndexModal,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
};

/**
 * Yuka modal element including a built in header and footer.
 *
 * This is best used by wrapping in other repos to inject the app element prop consistently.
 * This prop is critical for proper accessibility. By default, we will use the first div element
 * that is a direct child of body.
 */
const Modal = React.forwardRef((props, ref) => {
  const root = props.appElement || document.querySelector("body > div:first-of-type");

  const { onClose: propsOnClose } = props;
  const onClose = useCallback(() => {
    if (props.closeConfirmationMessage) {
      if (confirm(props.closeConfirmationMessage)) {
        propsOnClose();
      }
    } else {
      propsOnClose();
    }
  }, [propsOnClose, props.closeConfirmationMessage]);

  let contents;
  if (props.modalStyle === ModalStyles.SCROLLABLE) {
    contents = (
      <>
        <StyledHeader as={Surface}>{props.title}</StyledHeader>
        <StyledScrollContent>{props.children}</StyledScrollContent>
        <StyledFooter as={Surface}>
          <Button onClick={onClose}>{props.cancelText}</Button>
          {props.onClickAlt && (
            <Button
              buttonStyle={props.altButtonStyle}
              disabled={props.altDisabled}
              onClick={props.onClickAlt}
            >
              {props.altText}
            </Button>
          )}
          {props.onClickCta && (
            <Button
              buttonStyle={ButtonStyles.CTA}
              disabled={props.ctaDisabled}
              onClick={props.onClickCta}
            >
              {props.ctaText}
            </Button>
          )}
        </StyledFooter>
      </>
    );
  } else if (props.modalStyle === ModalStyles.BASIC) {
    contents = (
      <>
        {props.title && <StyledHeader>{props.title}</StyledHeader>}
        <StyledNoScrollContent>{props.children}</StyledNoScrollContent>
        <StyledFooter>
          <Button onClick={onClose}>{props.cancelText}</Button>
          {props.onClickAlt && (
            <Button
              buttonStyle={props.altButtonStyle}
              disabled={props.altDisabled}
              onClick={props.onClickAlt}
            >
              {props.altText}
            </Button>
          )}
          {props.onClickCta && (
            <Button
              buttonStyle={ButtonStyles.CTA}
              onClick={props.onClickCta}
              disabled={props.ctaDisabled}
            >
              {props.ctaText}
            </Button>
          )}
        </StyledFooter>
      </>
    );
  } else if (props.modalStyle === ModalStyles.MINIMAL) {
    contents = props.children;
  }

  return (
    <StrippedNPMModal
      onRequestClose={props.onClose}
      isOpen
      contentLabel={props.title || "Confirm"}
      appElement={root}
      $height={props.height}
    >
      <__internalsSurfaceContext.Provider value={2}>
        <StyledModal className={props.className} ref={ref} elevated>
          {contents}
        </StyledModal>
      </__internalsSurfaceContext.Provider>
    </StrippedNPMModal>
  );
});

Modal.propTypes = {
  modalStyle: PropTypes.oneOf(Object.values(ModalStyles)),
  closeConfirmationMessage: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string,
  ctaText: PropTypes.string,
  ctaDisabled: PropTypes.bool,
  onClickCta: PropTypes.func,
  cancelText: PropTypes.string,
  altDisabled: PropTypes.bool,
  altButtonStyle: PropTypes.oneOf(Object.values(ButtonStyles)),
  onClickAlt: PropTypes.func,
  altText: PropTypes.string,
  children: PropTypes.node.isRequired,
  appElement: PropTypes.element,
  className: PropTypes.string,
  height: PropTypes.number,
};

Modal.defaultProps = {
  modalStyle: ModalStyles.BASIC,
  cancelText: "Cancel",
  altButtonStyle: ButtonStyles.OUTLINE,
  ctaDisabled: false,
  altDisabled: false,
};

Modal.displayName = "Modal";

export default Modal;
export { ModalStyles };
