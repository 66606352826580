import { css } from "styled-components";
import { YukaColorPalette, ColorPalette } from "../../StylingConstants";
import { IconButtonStyles } from "../constants";

const DEFAULT_ICON_BUTTON_CONTAINER = css`
  border-radius: 50%;
  border: 0 solid transparent;
  height: 32px;
  width: 32px;
  padding: 8px;
`;

const NON_RAISED_DISABLED = css`
  background: transparent;
  cursor: default;
`;

const RAISED_DISABLED = css`
  background: ${ColorPalette.white05};
  cursor: default;
`;

const NON_RAISED_DEFAULT = css`
  background: transparent;
`;
const NON_RAISED_HOVER = css`
  background: ${YukaColorPalette.hoverTransparent};
`;
const NON_RAISED_ACTIVE = css`
  background: ${YukaColorPalette.activeTransparent};
`;

const RAISED_DEFAULT = css`
  border: none;
  background: ${ColorPalette.white10};
`;
const RAISED_HOVER = css`
  background: ${ColorPalette.white15};
`;

const RAISED_ACTIVE = css`
  background: rgba(255, 255, 255, 0.17);
`;

const DEFAULT_ICON_BUTTON_THEME = {
  [IconButtonStyles.STANDARD]: {
    container: DEFAULT_ICON_BUTTON_CONTAINER,
    disabled: NON_RAISED_DISABLED,
    default: NON_RAISED_DEFAULT,
    hover: NON_RAISED_HOVER,
    active: NON_RAISED_ACTIVE,
  },
  [IconButtonStyles.RAISED]: {
    container: DEFAULT_ICON_BUTTON_CONTAINER,
    disabled: RAISED_DISABLED,
    default: RAISED_DEFAULT,
    hover: RAISED_HOVER,
    active: RAISED_ACTIVE,
  },
  [IconButtonStyles.OUTLINE]: {
    container: DEFAULT_ICON_BUTTON_CONTAINER,
    disabled: NON_RAISED_DISABLED,
    default: NON_RAISED_DEFAULT,
    hover: NON_RAISED_HOVER,
    active: NON_RAISED_ACTIVE,
  },
};

export default DEFAULT_ICON_BUTTON_THEME;
