import PropTypes from "prop-types";
import React from "react";
import styled, { css } from "styled-components";
import { ColorPalette, FontColors, Fonts } from "yuka";

import RoundedSquareIcon from "./RoundedSquareIcon";

const StyledActionButton = styled(Fonts.Body1theme50).attrs({ as: "div" })`
  display: flex;
  align-items: center;
  width: fit-content;
  gap: 8px;
  cursor: pointer;
  white-space: nowrap;
  line-height: 24px;

  ${({ $disabled }) => {
    if ($disabled) {
      return css`
        ${FontColors.theme30}
        cursor: unset;

        > :first-child {
          background: ${ColorPalette.white10};
        }

        svg path {
          fill: ${ColorPalette.white15};
        }
      `;
    }
    return css`
      > :first-child {
        background: ${ColorPalette.white15};
      }

      svg path {
        fill: ${ColorPalette.white50};
      }

      &&:hover {
        ${FontColors.theme80}
        > :first-child {
          background: ${ColorPalette.white15};
        }
        svg path {
          fill: ${ColorPalette.white80};
        }
      }

      &&:active {
        ${FontColors.theme100}
        > :first-child {
          background: rgba(255, 255, 255, 0.2);
        }
        svg path {
          fill: ${ColorPalette.white100};
        }
      }

      // Allow users to programmatically force the active state.
      ${(props) =>
        props.$active &&
        css`
          ${FontColors.theme100}
          > :first-child {
            background: rgba(255, 255, 255, 0.2);
          }
          svg path {
            fill: ${ColorPalette.white100};
          }
        `}
    `;
  }}
`;

const ActionButton = React.forwardRef(
  ({ icon, onClick, children, active, disabled, className, id }, ref) => {
    return (
      <StyledActionButton
        id={id}
        $disabled={disabled}
        $active={active}
        role="button"
        onClick={onClick}
        className={className}
        ref={ref}
      >
        <RoundedSquareIcon containerSize={20} iconSize={14} icon={icon} />
        {children}
      </StyledActionButton>
    );
  }
);

ActionButton.propTypes = {
  id: PropTypes.string,
  disabled: PropTypes.bool,
  active: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.node,
  onClick: PropTypes.func,
  icon: PropTypes.elementType.isRequired,
};

export default ActionButton;
