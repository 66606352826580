import { DateTime } from "luxon";
import PropTypes from "prop-types";
import React, { useMemo } from "react";
import { useFormState } from "react-final-form";

import useNoDataErrorState from "./useNoDataErrorState";
import usePrivateMarketIndices from "./usePrivateMarketIndices";
import ValuationPriceComponentRow from "./ValuationPriceComponentRow";

import { API_ENDPOINTS } from "../../api/constants";
import useFetch from "../../api/useFetch";
import computePrivateMarketGain from "../utils/computePrivateMarketGain";

/**
 * A wrapper for the value-computing valuation component, which handles fetching the relevant
 * index values and computing the percentage change. Propagates the percentage to the form values
 * to be used downstream in the computed value computation.
 * @param props
 *
 */
const IndexComparisonComponentRow = (props) => {
  const {
    values: {
      selected_sector_index: selectedSectorIndexId,
      valuation_date: endDate,
      previous_pps: previousPPS,
      previous_date: startDate,
    },
  } = useFormState();

  const previousFriday = useMemo(() => {
    if (!startDate) {
      return null;
    }
    // Get the previous friday to startDate unless startDate is a friday.
    const date = DateTime.fromISO(startDate);
    const dayOfWeek = date.weekday;
    const friday = 5;
    let daysToSubtract = 0;
    if (dayOfWeek !== friday) {
      if (dayOfWeek > friday) {
        daysToSubtract = dayOfWeek - friday;
      } else {
        daysToSubtract = 7 - (friday - dayOfWeek);
      }
    }
    return date.minus({ days: daysToSubtract }).toISODate();
  }, [startDate]);
  const privateMarketIndices = usePrivateMarketIndices();
  const privateMarketIndex = privateMarketIndices?.find(
    (index) => index.private_market_index?.[1] === selectedSectorIndexId
  );

  const privateMarketIndexValuesQuery = useFetch(
    API_ENDPOINTS.ZX_MARKET_INDEX_VALUES(),
    {
      market_index: selectedSectorIndexId,
      start_date: previousFriday, // We cannot query these without a previous valuation date.
      ...(endDate && { end_date: endDate }),
    },
    {
      enabled: Boolean(
        selectedSectorIndexId && !props.initialValue && startDate
      ),
    }
  );

  const privateMarketIndexValues = useMemo(
    () => privateMarketIndexValuesQuery.cleanedData?.data || [],
    [privateMarketIndexValuesQuery.cleanedData?.data]
  );

  const computedValue = useMemo(() => {
    if (props.initialValue) {
      return props.initialValue;
    }
    if (!startDate || privateMarketIndexValuesQuery.isLoading) {
      return null;
    }
    if (!previousPPS) {
      return null;
    }
    return computePrivateMarketGain(privateMarketIndexValues);
  }, [
    previousPPS,
    startDate,
    props.initialValue,
    privateMarketIndexValuesQuery.isLoading,
    privateMarketIndexValues,
  ]);

  const error = useNoDataErrorState(
    props.initialValue ||
      !startDate ||
      !previousPPS ||
      privateMarketIndexValuesQuery.isLoading,
    computedValue
  );

  return (
    <ValuationPriceComponentRow
      {...props}
      helpText={
        privateMarketIndex &&
        `${privateMarketIndex?.name} (${privateMarketIndex?.symbol})`
      }
      errorText={error}
      editable={!props.initialValue}
      initialValue={computedValue}
    />
  );
};

IndexComparisonComponentRow.propTypes = {
  initialValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  companyName: PropTypes.string.isRequired,
};

export default IndexComparisonComponentRow;
