import PropTypes from "prop-types";
import React from "react";
import { Modal, ModalStyles, Fonts, Button, ButtonStyles } from "yuka";

import { usePortfolio, usePortfolioCompanies } from "./hooks";
import { StyledModalButtonRow, StyledModalContent } from "./StyledComponents";

import { API_ENDPOINTS } from "../api/constants";
import useDelete from "../api/useDelete";
import MixpanelEvents from "../utils/mixpanel/MixpanelEvents";

const DeletePortfolioModal = ({ onClose }) => {
  const [portfolio] = usePortfolio();
  const [portfolioCompanies, portfolioCompaniesLoading] = usePortfolioCompanies(
    portfolio?.apiId
  );
  const deletePortfolioQuery = useDelete(API_ENDPOINTS.PORTFOLIOS(), {
    invalidateUrls: [
      {
        url: API_ENDPOINTS.PORTFOLIO_DETAIL(portfolio.apiId),
        queryParams: {},
      },
    ],
  });

  const onSubmit = () => {
    MixpanelEvents.deletePortfolio(portfolio.apiId, portfolio.name);
    deletePortfolioQuery.mutate({ id: portfolio.apiId });
    onClose();
  };

  if (!portfolio.apiId || portfolioCompaniesLoading) {
    return null;
  }

  return (
    <Modal modalStyle={ModalStyles.MINIMAL} onClose={onClose}>
      <StyledModalContent>
        <Fonts.Headline2theme80>Delete this portfolio?</Fonts.Headline2theme80>
        <Fonts.Body1theme80>
          This portfolio with{" "}
          {`${portfolioCompanies.length} ${
            portfolioCompanies.length === 1 ? " company" : " companies"
          }`}{" "}
          will be deleted.
        </Fonts.Body1theme80>
      </StyledModalContent>
      <StyledModalButtonRow>
        <Button buttonStyle={ButtonStyles.RAISED} onClick={onClose}>
          Cancel
        </Button>
        <Button buttonStyle={ButtonStyles.CTA} onClick={onSubmit}>
          Delete
        </Button>
      </StyledModalButtonRow>
    </Modal>
  );
};

DeletePortfolioModal.propTypes = {
  onClose: PropTypes.func,
};

export default DeletePortfolioModal;
