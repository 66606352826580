import { YukaColorPalette } from "yuka";

/*
 * Some hardcoded values to implement the hover state for the graph, which has a fixed size and
 * offset.
 */
const GRAPH_TOOLTIP_STYLES = {
  textAlign: "center",
  width: 72,
  height: 24,
  fontSize: 12,
  lineHeight: "24px",
  left: -36,
  background: YukaColorPalette.surface3,
  borderRadius: 4,
};

const AXIS_LABEL_FONT_SIZE = 14;

export { GRAPH_TOOLTIP_STYLES, AXIS_LABEL_FONT_SIZE };
