import { DateTime } from "luxon";
import PropTypes from "prop-types";
import { useContext, useMemo, useState } from "react";
import styled from "styled-components";
import {
  Fonts,
  HyperLink,
  IconButton,
  LinkStyles,
  PencilIcon,
  PlusIcon,
  TrashIcon,
} from "yuka";

import {
  MODE_CUSTOM_VALUATION,
  MODE_ZX_INDEX_VALUE,
  REMOVED_REASON_MAP,
} from "./constants";
import DeletePortfolioCompanyModal from "./DeletePortfolioCompanyModal";
import { PortfolioModeContext } from "./PortfolioProfile";
import PriceRangeIndicator from "./PriceRangeIndicator";
import ShareLotTable from "./ShareLotTable";
import SingleShareLotModal from "./SingleShareLotModal";
import { PortfolioTableRowGridLayout } from "./StyledComponents";
import { isRequestedPortfolioCompany } from "./utils";
import isRemovedPortfolioCompany from "./utils/isRemovedPortfolioCompany";

import { ValuationCalculatorModal } from "../company/ValuationCalculator";
import RobustnessScoreLabel from "../company/ValuationCalculator/RobustnessScoreLabel";
import { AccordionContent, AccordionTrigger, ActionButton } from "../hdYuka";
import { ROUTING_PATH } from "../routes/constants";
import addCommasToNum from "../utils/addCommasToNum";
import {
  expandedMoneyFormat,
  percentFormat,
} from "../utils/displayFormatUtils";

const EMPTY_VALUE_PLACEHOLDER = <Fonts.Body2theme50>--</Fonts.Body2theme50>;

const StyledActionButton = styled(ActionButton)`
  margin-top: 16px;
`;

const StyledCurrentPriceCell = styled(Fonts.Body2theme80).attrs({ as: "div" })`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const MODAL_DELETING_COMPANY = "deletingCompany";
const MODAL_CREATING_SHARE_LOT = "creatingShareLot";
const MODAL_CREATING_CUSTOM_VALUATION = "creatingCustomValuation";

/**
 *
 * @param company - MUST be the output of the `usePortfolioCompanyAggregates` hook.
 * @return {JSX.Element}
 */
const PortfolioCompanyAccordionTrigger = ({ companyAggregate, id }) => {
  const [isHovered, setIsHovered] = useState(false);
  const [modal, setModal] = useState(null);
  const { mode } = useContext(PortfolioModeContext);

  // Sam's log: isError is currently not in use because we technically allow users to add
  // companies to portfolios that do not have order flow data showing. This component renders
  // such companies as a bunch of --'s for every field except quantity. The network returns a 404
  // for these companies when fetching orderflow, so isError is true.
  const {
    company,
    isLoading,
    // isError,
    name,
    customValuation,
    shareQuantity,
    totalValueFromZXIndexValue,
    totalValueFromCustomValuation,
    unrealizedGainFromZXIndexValue,
    unrealizedGainFromCustomValuation,
    costBasis,
    shareLots,
    low52weeks,
    high52weeks,
    currentZXIndexValue,
    currentZXIndexValuePercentChange,
    currentRobustnessScore,
  } = companyAggregate;

  const zxIndexValueChangeDisplay = useMemo(() => {
    if (currentZXIndexValuePercentChange === null) {
      return <Fonts.Body1theme80>(+0.00%)</Fonts.Body1theme80>;
    }
    const percentDisplay = currentZXIndexValuePercentChange * 100;
    if (percentDisplay > 0) {
      return (
        <Fonts.Body1buy>(+{percentFormat(percentDisplay)})</Fonts.Body1buy>
      );
    }
    if (percentDisplay < 0) {
      return (
        <Fonts.Body1sell>({percentFormat(percentDisplay)})</Fonts.Body1sell>
      );
    }
    return (
      <Fonts.Body1theme80>({percentFormat(percentDisplay)})</Fonts.Body1theme80>
    );
  }, [currentZXIndexValuePercentChange]);

  const totalValueDisplay = useMemo(() => {
    const totalValue =
      mode === MODE_ZX_INDEX_VALUE
        ? totalValueFromZXIndexValue
        : totalValueFromCustomValuation;
    return totalValue ? (
      <Fonts.Body2theme80>
        {expandedMoneyFormat(totalValue, 2, 2)}
      </Fonts.Body2theme80>
    ) : (
      EMPTY_VALUE_PLACEHOLDER
    );
  }, [mode, totalValueFromZXIndexValue, totalValueFromCustomValuation]);

  /*
   * Display the unrealized gain in the correct color and with the percentage change.
   */
  const unrealizedGainDisplay = useMemo(() => {
    const totalValue =
      mode === MODE_ZX_INDEX_VALUE
        ? totalValueFromZXIndexValue
        : totalValueFromCustomValuation;
    const unrealizedGain =
      mode === MODE_ZX_INDEX_VALUE
        ? unrealizedGainFromZXIndexValue
        : unrealizedGainFromCustomValuation;
    if (unrealizedGain === null) {
      return EMPTY_VALUE_PLACEHOLDER;
    }
    const percentageChange = costBasis
      ? ((totalValue - costBasis) / costBasis) * 100
      : null;
    if (unrealizedGain > 0) {
      return (
        <Fonts.Body2buy>
          +{expandedMoneyFormat(unrealizedGain, 2, 2)} (+
          {percentFormat(percentageChange)})
        </Fonts.Body2buy>
      );
    }
    if (unrealizedGain < 0) {
      return (
        <Fonts.Body2sell>
          {expandedMoneyFormat(unrealizedGain, 2, 2)} (
          {percentFormat(percentageChange)})
        </Fonts.Body2sell>
      );
    }
    // Vanishingly uncommon case.
    return (
      <Fonts.Body2theme80>
        {expandedMoneyFormat(unrealizedGain, 2, 2)} (
        {percentFormat(percentageChange)})
      </Fonts.Body2theme80>
    );
  }, [
    mode,
    totalValueFromZXIndexValue,
    totalValueFromCustomValuation,
    unrealizedGainFromZXIndexValue,
    unrealizedGainFromCustomValuation,
    costBasis,
  ]);

  const companyNameDisplay = useMemo(() => {
    if (company?.company) {
      return (
        <div>
          <HyperLink
            url={ROUTING_PATH.COMPANY(company.company.zb_id)}
            onClick={(e) => e.stopPropagation()}
            linkStyle={LinkStyles.INVISIBLE}
          >
            <Fonts.Body2theme80>{name}</Fonts.Body2theme80>
          </HyperLink>
        </div>
      );
    } else if (isRequestedPortfolioCompany(company)) {
      return <Fonts.Body2theme80>{name}</Fonts.Body2theme80>;
    }
    return EMPTY_VALUE_PLACEHOLDER;
  }, [company, name]);

  const zxIndexValueDisplay = useMemo(
    () =>
      currentZXIndexValue ? (
        <StyledCurrentPriceCell>
          {expandedMoneyFormat(currentZXIndexValue, 2, 2)}{" "}
          {zxIndexValueChangeDisplay}
          <RobustnessScoreLabel score={currentRobustnessScore} />
        </StyledCurrentPriceCell>
      ) : (
        EMPTY_VALUE_PLACEHOLDER
      ),
    [currentZXIndexValue, zxIndexValueChangeDisplay, currentRobustnessScore]
  );

  const currentValueDisplay = useMemo(() => {
    // Will show the current ZX Index Value (+ robustness) or the latest custom valuation
    // for the company depending on what mode we're in.
    if (mode === MODE_ZX_INDEX_VALUE) {
      return zxIndexValueDisplay;
    }
    // Return custom valuation, with the option to create a new one.
    return customValuation ? (
      <StyledCurrentPriceCell>
        {expandedMoneyFormat(customValuation.computed_value, 2, 2)}
        <Fonts.Body1theme30>
          {DateTime.fromISO(customValuation.valuation_date).toLocaleString()}
        </Fonts.Body1theme30>
        <IconButton
          icon={isHovered ? PencilIcon : () => null}
          onClick={(e) => {
            setModal(MODAL_CREATING_CUSTOM_VALUATION);
            // Prevents click from expanding accordion.
            e.stopPropagation();
          }}
        />
      </StyledCurrentPriceCell>
    ) : (
      <ActionButton
        icon={PlusIcon}
        onClick={(e) => {
          setModal(MODAL_CREATING_CUSTOM_VALUATION);
          // Prevents click from expanding accordion.
          e.stopPropagation();
        }}
      >
        New valuation
      </ActionButton>
    );
  }, [mode, zxIndexValueDisplay, customValuation, isHovered]);

  return (
    <>
      <AccordionTrigger
        chevronId={id}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        className="text-left"
      >
        <PortfolioTableRowGridLayout
          $showData={
            !isRemovedPortfolioCompany(companyAggregate) &&
            !(
              mode === MODE_ZX_INDEX_VALUE &&
              isRequestedPortfolioCompany(company)
            )
          }
        >
          {companyNameDisplay}
          {isRemovedPortfolioCompany(companyAggregate) && (
            <Fonts.Body1theme50>
              This company was removed
              {companyAggregate.removedReason &&
                ` (${REMOVED_REASON_MAP[companyAggregate.removedReason]})`}{" "}
              on
              <Fonts.Body1theme80>
                {" "}
                {DateTime.fromISO(companyAggregate.removedOn).toFormat(
                  "MM/dd/yyyy"
                )}{" "}
              </Fonts.Body1theme80>
              and we won't be displaying data going forward.
            </Fonts.Body1theme50>
          )}
          {mode === MODE_ZX_INDEX_VALUE &&
            isRequestedPortfolioCompany(company) && (
              <Fonts.Body1theme50>
                This company is not yet verified in ZXData and does not
                contribute to portfolio value.
              </Fonts.Body1theme50>
            )}
          {!isRemovedPortfolioCompany(companyAggregate) &&
            !(
              mode === MODE_ZX_INDEX_VALUE &&
              isRequestedPortfolioCompany(company)
            ) && (
              <>
                {currentValueDisplay}
                {shareQuantity ? (
                  <Fonts.Body2theme80>
                    {addCommasToNum(shareQuantity)}
                  </Fonts.Body2theme80>
                ) : (
                  EMPTY_VALUE_PLACEHOLDER
                )}
                {totalValueDisplay}
                {unrealizedGainDisplay}
                {!isLoading && mode === MODE_ZX_INDEX_VALUE && (
                  <PriceRangeIndicator
                    low={low52weeks}
                    high={high52weeks}
                    current={Number(currentZXIndexValue)}
                  />
                )}
                {mode === MODE_CUSTOM_VALUATION && zxIndexValueDisplay}
              </>
            )}
          <IconButton
            icon={isHovered ? TrashIcon : () => null}
            onClick={(e) => {
              setModal(MODAL_DELETING_COMPANY);
              // Prevents click from expanding accordion.
              e.stopPropagation();
            }}
          />
        </PortfolioTableRowGridLayout>
      </AccordionTrigger>
      <AccordionContent>
        {shareLots?.length > 0 && (
          <ShareLotTable companyAggregate={companyAggregate} />
        )}
        <StyledActionButton
          onClick={() => setModal(MODAL_CREATING_SHARE_LOT)}
          icon={PlusIcon}
        >
          Record a{shareLots?.length > 0 ? "nother" : ""} share lot
        </StyledActionButton>
      </AccordionContent>
      {modal === MODAL_CREATING_SHARE_LOT ? (
        <SingleShareLotModal
          company={company}
          onClose={() => setModal(null)}
          shareLot={null}
        />
      ) : null}
      {modal === MODAL_DELETING_COMPANY && (
        <DeletePortfolioCompanyModal
          portfolioCompanyAggregate={companyAggregate}
          onClose={() => setModal(null)}
        />
      )}
      {modal === MODAL_CREATING_CUSTOM_VALUATION && (
        <ValuationCalculatorModal
          onClose={() => setModal(null)}
          requestedCompanyId={companyAggregate.company?.requested_company?.id}
          companyId={companyAggregate.company?.company?.zb_id}
        />
      )}
    </>
  );
};

PortfolioCompanyAccordionTrigger.propTypes = {
  id: PropTypes.string,
  companyAggregate: PropTypes.shape({
    company: PropTypes.shape({
      company: PropTypes.shape({
        zb_id: PropTypes.string,
      }),
      requested_company: PropTypes.shape({
        id: PropTypes.string,
      }),
    }),
    removed: PropTypes.bool,
    removedOn: PropTypes.string,
    removedReason: PropTypes.number,
    isLoading: PropTypes.bool,
    name: PropTypes.string,
    shareQuantity: PropTypes.number,
    totalValueFromZXIndexValue: PropTypes.number,
    totalValueFromCustomValuation: PropTypes.number,
    unrealizedGainFromZXIndexValue: PropTypes.number,
    unrealizedGainFromCustomValuation: PropTypes.number,
    costBasis: PropTypes.number,
    shareLots: PropTypes.array,
    low52weeks: PropTypes.number,
    high52weeks: PropTypes.number,
    currentZXIndexValue: PropTypes.string,
    currentZXIndexValuePercentChange: PropTypes.string,
    currentRobustnessScore: PropTypes.number,
    customValuation: PropTypes.shape({
      computed_value: PropTypes.string,
      valuation_date: PropTypes.string,
    }),
  }),
};

export default PortfolioCompanyAccordionTrigger;
