// The commented out colors are left in place to remind us which colors we still need to
// standardize in our theme.
const DataverseColors = {
  // branding900: ColorPalette.blue900,
  // branding800: ColorPalette.blue800,
  // branding700: ColorPalette.blue700,
  // branding600: ColorPalette.blue600,
  branding500: "#74CBD3",
  branding400: "#74CBD3",
  branding300: "#3d4d4f", // TODO i made this up, need designer input.
  branding200: "#2B393A",
  // branding100: ColorPalette.blue100,
  red: "#FFBC96",
  // redHover: "#ff7272",
  // redActive: "#ff7a7a",
  green: "#92CAB7",
  // greenHover: "#33f8a2",
  // greenActive: "#66fab9",
  yellow: "#DFCD6F",
  magenta: "#D593DB",
  indigo: "#557EE7",
};

export { DataverseColors };
