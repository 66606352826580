import _ from "lodash";
import { DateTime } from "luxon";
import React, { useCallback, useMemo, useState } from "react";
import {
  ComposedChart,
  Line,
  Area,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import styled from "styled-components";
import { ActionChip, ColorPalette, Fonts, GridIcon } from "yuka";

import ReportedMarksModal from "./ReportedMarksModal";

import { API_ENDPOINTS } from "../../../api/constants";
import useFetch from "../../../api/useFetch";
import { DataverseColors } from "../../../hdYuka/constants";
import SurfaceZeroCard from "../../../hdYuka/SurfaceZeroCard";
import { expandedMoneyFormat } from "../../../utils/displayFormatUtils";
import LoadingSpinner from "../../../utils/LoadingSpinner";
import { useCompany } from "../../hooks";
import {
  List,
  ListItem,
  StyledCircle,
  StyledEmptyPill,
  StyledCenteredEmptyState,
} from "../../StyledComponents";

const GraphXAxisYearContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
`;

const LegendItemContainer = styled(Fonts.Body1theme50).attrs({ as: "div" })`
  display: flex;
  gap: 12px;
  align-items: center;
`;

const ReportedMarksCard = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const closeModal = useCallback(() => setIsModalOpen(false), [setIsModalOpen]);
  const openModal = useCallback(() => setIsModalOpen(true), [setIsModalOpen]);
  const [company, companyIsLoading] = useCompany();
  const [hoverStats, setHoverStats] = useState(null);

  const reportedMarksQuery = useFetch(
    API_ENDPOINTS.COMPANY_REPORTED_MARKS_VALUATION(company.zb_id)
  );
  const reportedMarksData = reportedMarksQuery?.data?.data;
  const graphData = useMemo(
    () =>
      _.map(reportedMarksData, (d, date) => ({
        date,
        area: [d.min, d.max],
        midline: d.weighted_avg,
      })).reverse(),
    [reportedMarksData]
  );

  const xAxisTicks = useMemo(() => _.map(graphData, "date"), [graphData]);
  const startDate = useMemo(() => _.last(xAxisTicks), [xAxisTicks]);
  const endDate = useMemo(() => _.first(xAxisTicks), [xAxisTicks]);

  // Set hover point data
  const onMouseMove = useCallback(
    (d) => {
      if (!reportedMarksData) {
        return;
      }

      if (d.activeLabel) {
        const hoveredMark = reportedMarksData[d.activeLabel];
        setHoverStats({
          ...hoveredMark,
          report_period: d.activeLabel,
        });
      } else {
        setHoverStats(null);
      }
    },
    [reportedMarksData]
  );

  // The active dot on the <Line /> component requires a tooltip to be enabled
  // So we pass in a null tooltip function as a hack
  const renderNullTooltip = useCallback(() => null, []);

  if (companyIsLoading) {
    return <LoadingSpinner />;
  }

  if (!reportedMarksData) {
    return (
      <SurfaceZeroCard title="Reported Marks">
        <StyledEmptyPill>
          <StyledCenteredEmptyState $margin={48}>
            Data not available
          </StyledCenteredEmptyState>
        </StyledEmptyPill>
      </SurfaceZeroCard>
    );
  }

  return (
    <SurfaceZeroCard
      title="Reported Marks"
      headerTrailingContent={
        <ActionChip
          text="Full data"
          leadingIcon={GridIcon}
          onClick={openModal}
        />
      }
    >
      <ResponsiveContainer width="100%" minHeight={150}>
        <ComposedChart
          data={graphData}
          onMouseMove={onMouseMove}
          margin={{
            top: 0,
            right: 12,
            left: 12,
            bottom: 0,
          }}
        >
          <YAxis
            domain={[(dataMin) => dataMin * 0.98, (dataMax) => dataMax * 1.02]}
            hide
          />
          <XAxis
            axisLine={false}
            tickLine={false}
            interval={0}
            fontSize={11}
            tickFormatter={(value) => DateTime.fromISO(value).toFormat("MMM")}
            ticks={xAxisTicks}
            dataKey="date"
            stroke={ColorPalette.white50}
          />
          <Area
            type="monotone"
            dataKey="area"
            stroke="none"
            fill={ColorPalette.white15}
            connectNulls
            activeDot={false}
          />
          <Tooltip content={renderNullTooltip} />
          <Line
            type="natural"
            dataKey="midline"
            stroke={DataverseColors.branding400}
            strokeWidth={2}
            dot={false}
            connectNulls
            activeDot={{ r: 4, strokeWidth: 2, stroke: ColorPalette.black50 }}
          />
        </ComposedChart>
      </ResponsiveContainer>
      <GraphXAxisYearContainer>
        <Fonts.Caption1theme50>
          {DateTime.fromISO(startDate).year}
        </Fonts.Caption1theme50>
        <Fonts.Caption1theme50>
          {DateTime.fromISO(endDate).year}
        </Fonts.Caption1theme50>
      </GraphXAxisYearContainer>
      <List>
        <ListItem>
          <LegendItemContainer>
            <StyledCircle color={DataverseColors.branding400} />
            Dollar-Weighted AVG.
          </LegendItemContainer>
          {hoverStats?.weighted_avg ? (
            <Fonts.Body2theme80>
              {expandedMoneyFormat(hoverStats.weighted_avg, 2, 2)}
            </Fonts.Body2theme80>
          ) : (
            <Fonts.Body2theme30>--</Fonts.Body2theme30>
          )}
        </ListItem>
        <ListItem>
          <LegendItemContainer>
            <StyledCircle color={ColorPalette.white15} />
            Max | Min
          </LegendItemContainer>
          <span>
            {hoverStats?.max ? (
              <Fonts.Body2theme80>
                {expandedMoneyFormat(hoverStats.max, 2, 2)}
              </Fonts.Body2theme80>
            ) : (
              <Fonts.Body2theme30>--</Fonts.Body2theme30>
            )}
            <Fonts.Body2theme30> | </Fonts.Body2theme30>
            {hoverStats?.min ? (
              <Fonts.Body2theme80>
                {expandedMoneyFormat(hoverStats.min, 2, 2)}
              </Fonts.Body2theme80>
            ) : (
              <Fonts.Body2theme30>--</Fonts.Body2theme30>
            )}
          </span>
        </ListItem>
        <ListItem>
          <LegendItemContainer>Report period</LegendItemContainer>
          {hoverStats?.report_period ? (
            <Fonts.Body2theme80>
              {DateTime.fromISO(hoverStats.report_period).toLocaleString({
                month: "short",
                year: "numeric",
              })}
            </Fonts.Body2theme80>
          ) : (
            <Fonts.Body2theme30>--</Fonts.Body2theme30>
          )}
        </ListItem>
      </List>
      {isModalOpen && <ReportedMarksModal closeModal={closeModal} />}
    </SurfaceZeroCard>
  );
};

export default ReportedMarksCard;
