/* eslint-disable */
import React from "react";
import useEmblaCarousel from "embla-carousel-react";

import {
  ChevronLeftIcon,
  ChevronRightIcon,
  Fonts,
  IconButton,
  IconButtonStyles,
  YukaColorPalette,
} from "yuka";
import cn from "../../utils/cn";
import styled, { css } from "styled-components";

const CarouselContext = React.createContext(null);

const useCarousel = () => {
  const context = React.useContext(CarouselContext);

  if (!context) {
    throw new Error("useCarousel must be used within a <Carousel />");
  }

  return context;
};

const StyledEmptyOverlay = styled(Fonts.Headline1theme15).attrs({ as: "div" })`
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  height: 100%;
  width: 100%;
  background-image: linear-gradient(
    0.25turn,
    transparent,
    ${(props) =>
      YukaColorPalette[
        `surface${Math.max(props.theme.surfaceLevel - 1 || 0, 0)}`
      ]}
  );
`;

const Carousel = React.forwardRef(
  (
    {
      // We currently do not support EmblaCarousel's vertical orientation.
      // orientation = "horizontal"
      opts,
      setApi,
      plugins,
      className,
      children,
      slidesToScroll = 3,
      empty,
      ...props
    },
    ref
  ) => {
    const [carouselRef, api] = useEmblaCarousel(
      {
        ...opts,
        slidesToScroll,
        duration: 20,
        axis: "x",
        // axis: orientation === "horizontal" ? "x" : "y",
      },
      plugins
    );
    const [canScrollPrev, setCanScrollPrev] = React.useState(false);
    const [canScrollNext, setCanScrollNext] = React.useState(false);

    const onSelect = React.useCallback((api) => {
      if (!api) {
        return;
      }

      setCanScrollPrev(api.canScrollPrev());
      setCanScrollNext(api.canScrollNext());
    }, []);

    const scrollPrev = React.useCallback(() => {
      api?.scrollPrev();
    }, [api]);

    const scrollNext = React.useCallback(() => {
      api?.scrollNext();
    }, [api]);

    const handleKeyDown = React.useCallback(
      (event) => {
        if (event.key === "ArrowLeft") {
          event.preventDefault();
          scrollPrev();
        } else if (event.key === "ArrowRight") {
          event.preventDefault();
          scrollNext();
        }
      },
      [scrollPrev, scrollNext]
    );

    React.useEffect(() => {
      if (!api || !setApi) {
        return;
      }

      setApi(api);
    }, [api, setApi]);

    React.useEffect(() => {
      if (!api) {
        return;
      }

      onSelect(api);
      api.on("reInit", onSelect);
      api.on("select", onSelect);

      return () => {
        api?.off("select", onSelect);
      };
    }, [api, onSelect]);

    return (
      <CarouselContext.Provider
        value={{
          carouselRef,
          api: api,
          opts,
          orientation: "horizontal",
          // orientation: orientation || (opts?.axis === "y" ? "vertical" : "horizontal"),
          scrollPrev,
          scrollNext,
          canScrollPrev,
          canScrollNext,
          slidesToScroll,
        }}
      >
        <div
          ref={ref}
          onKeyDownCapture={handleKeyDown}
          className={cn("relative", className)}
          role="region"
          aria-roledescription="carousel"
          {...props}
        >
          {children}
          {empty && <StyledEmptyOverlay>Data not available</StyledEmptyOverlay>}
        </div>
      </CarouselContext.Provider>
    );
  }
);
Carousel.displayName = "Carousel";

const StyledContent = styled.div`
  > :not(:last-child) {
    margin-right: 16px;
  }
`;

const StyledStart = styled.div`
  position: absolute;
  left: 0;
  height: 100%;
  width: 32px;
  background: linear-gradient(
    0.75turn,
    transparent,
    ${(props) =>
        YukaColorPalette[
          `surface${Math.max(props.theme.surfaceLevel - 1 || 0, 0)}`
        ]}
      62.5%
  );
`;

const StyledEnd = styled.div`
  position: absolute;
  right: 0;
  height: 100%;
  width: 32px;
  background: linear-gradient(
    0.25turn,
    transparent,
    ${(props) =>
        YukaColorPalette[
          `surface${Math.max(props.theme.surfaceLevel - 1 || 0, 0)}`
        ]}
      62.5%
  );
`;

const CarouselContent = React.forwardRef(({ className, ...props }, ref) => {
  const {
    carouselRef,
    // orientation,
    canScrollPrev,
    canScrollNext,
    slidesToScroll,
  } = useCarousel();

  return (
    <div ref={carouselRef} className="relative overflow-hidden flex w-full">
      <StyledContent
        // $orientation={orientation}
        ref={ref}
        className={cn("flex w-full", className)}
        {...props}
      />
      {canScrollPrev && <StyledStart $slidesToScroll={slidesToScroll} />}
      {canScrollNext && <StyledEnd $slidesToScroll={slidesToScroll} />}
    </div>
  );
});
CarouselContent.displayName = "CarouselContent";

const StyledItem = styled.div`
  // The carousel will show $slidesToScroll items, plus half an extra item
  // to indicate scroll ability.
  flex-basis: calc(
    calc(100% - calc(16px * ${(props) => props.$slidesToScroll})) /
      ${(props) => props.$slidesToScroll + 0.4}
  );
`;

const CarouselItem = React.forwardRef(({ className, ...props }, ref) => {
  const { slidesToScroll } = useCarousel();

  return (
    <StyledItem
      $slidesToScroll={slidesToScroll}
      ref={ref}
      role="group"
      aria-roledescription="slide"
      className={cn("min-w-0 shrink-0 grow-0", className)}
      {...props}
    />
  );
});
CarouselItem.displayName = "CarouselItem";

const CarouselPrevious = React.forwardRef(
  ({ className, variant = "outline", size = "icon", ...props }, ref) => {
    const { scrollPrev, canScrollPrev } = useCarousel();

    return (
      <IconButton
        buttonStyle={IconButtonStyles.OUTLINE}
        icon={ChevronLeftIcon}
        ref={ref}
        variant={variant}
        size={size}
        disabled={!canScrollPrev}
        onClick={scrollPrev}
        {...props}
      />
    );
  }
);
CarouselPrevious.displayName = "CarouselPrevious";

const CarouselNext = React.forwardRef(
  ({ className, variant = "outline", size = "icon", ...props }, ref) => {
    const { scrollNext, canScrollNext } = useCarousel();

    return (
      <IconButton
        className="self-end"
        buttonStyle={IconButtonStyles.OUTLINE}
        icon={ChevronRightIcon}
        ref={ref}
        variant={variant}
        size={size}
        disabled={!canScrollNext}
        onClick={scrollNext}
        {...props}
      />
    );
  }
);
CarouselNext.displayName = "CarouselNext";

export {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselPrevious,
  CarouselNext,
};
