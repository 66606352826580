// Copied from zanbato/orderflow/constants.py
// Normally passed through JSContext which is not available here
const ORDER_FLOW_TIME_FRAME_WEEKLY = 1000;
const ORDER_FLOW_TIME_FRAME_MONTHLY = 2000;

const ONE_THOUSAND = 1000;
const TEN_THOUSAND = 10_000;
const ONE_MILLION = 1_000_000;
const ONE_BILLION = 1_000_000_000;
const ONE_TRILLION = 1_000_000_000_000;

const WEEKLY = "weekly";

const TENTH_SECOND = 100; // 100ms
const HALF_SECOND = 500; // 500ms
const ONE_SECOND = 1000; // 1000 ms
const ONE_MINUTE = ONE_SECOND * 60;

const MONEY_FORMAT_MIN_DECIMAL_PLACES = 2;
const MONEY_FORMAT_MAX_DECIMAL_PLACES = 4;

const MIXPANEL_SOURCE_COMPANY_PROFILE = "Company Profile";
const MIXPANEL_SOURCE_COMPANY_PROFILE_FLOATING_HEADER =
  "Company Profile - Floating Header";
const MIXPANEL_SOURCE_SUPERCHART = "Superchart";

export {
  ORDER_FLOW_TIME_FRAME_WEEKLY,
  ORDER_FLOW_TIME_FRAME_MONTHLY,
  WEEKLY,
  TENTH_SECOND,
  HALF_SECOND,
  ONE_SECOND,
  ONE_MINUTE,
  ONE_THOUSAND,
  TEN_THOUSAND,
  ONE_MILLION,
  ONE_BILLION,
  ONE_TRILLION,
  MONEY_FORMAT_MIN_DECIMAL_PLACES,
  MONEY_FORMAT_MAX_DECIMAL_PLACES,
  MIXPANEL_SOURCE_COMPANY_PROFILE,
  MIXPANEL_SOURCE_COMPANY_PROFILE_FLOATING_HEADER,
  MIXPANEL_SOURCE_SUPERCHART,
};
