import { useMemo } from "react";
import _ from "lodash";
import { DateTime } from "luxon";

/**
 * Helper to find the non-whitespace data point whose `time` field matches the given `date`.
 * Returns undefined if there is no non-whitespace element at `time`. Whitespace objects are
 * objects with only 1 key: `time`.
 *
 * @param data - Array of chart data.
 * @param date - ISO date, 2023-10-23
 * @returns {object|undefined}
 */
const findNonWhiteSpacePoint = (data, date) => {
  return _.find(
    data,
    (datum) => datum.time === date && !_.isEqual(Object.keys(datum), ["time"])
  );
};

/**
 * Returns the point in the provided chart line data that should be used in the legend item for
 * that line; if the user is hovering the graph it returns the nearest point <= where the mouse is,
 * otherwise return the last (most recent) non-whitespace value in the data. Whitespace values are
 * objects with no properties other than `time`.
 *
 * @param {string} hoverDate - current x value being hovered, if one exists
 * @param {Array<object>} data - the { time, absolute, percent } timeseries data of the graph
 * @param {boolean} strict - if true, only return the point if it matches the hoverDate exactly
 *
 * @returns {object} - a { time, absolute, percent } dataPoint
 */
const useChartReferencePoint = (hoverDate, data, strict = false) => {
  return useMemo(() => {
    if (!data?.length) return null;

    let hoverPoint = findNonWhiteSpacePoint(data, hoverDate);

    if (strict) {
      return hoverPoint;
    }

    if (hoverDate && !hoverPoint) {
      // find last non-whitespace date in the data, if one exists
      let currentDate = hoverDate;

      while (
        !hoverPoint &&
        DateTime.fromISO(currentDate) >= DateTime.fromISO(data[0].time)
      ) {
        currentDate = DateTime.fromISO(currentDate)
          .minus({ days: 1 })
          .toISODate();
        hoverPoint = findNonWhiteSpacePoint(data, currentDate);
      }
    }
    return (
      hoverPoint ||
      _.findLast(data, (datum) => !_.isEqual(Object.keys(datum), ["time"]))
    );
  }, [hoverDate, data]);
};

export default useChartReferencePoint;
