import { DateTime } from "luxon";
import { useState } from "react";
import styled from "styled-components";
import {
  List,
  ColorPalette,
  HyperLink,
  LinkTypes,
  body1,
  Fonts,
  Button,
  ButtonStyles,
} from "yuka";

import { EMPTY_STATE_MARGIN, INITIAL_TABLE_SIZE } from "./constants";
import { StyledButtonContainer } from "./StyledComponents";

import { API_ENDPOINTS } from "../../../api/constants";
import useFetch from "../../../api/useFetch";
import { ListItem } from "../../../hdYuka";
import MixpanelEvents from "../../../utils/mixpanel/MixpanelEvents";
import { useCompany } from "../../hooks";
import { StyledCenteredEmptyState } from "../../StyledComponents";

const StyledLink = styled(HyperLink)`
  ${body1}
`;

const StyledListItem = styled(ListItem)`
  border-top: 1px dotted ${ColorPalette.white15};
`;

const StyledLoadingState = styled.div`
  display: flex;
  justify-content: center;
`;

const COIList = () => {
  const [company] = useCompany();
  const [isShowingMore, setIsShowingMore] = useState(false);

  const coiFilings = useFetch(
    API_ENDPOINTS.COI_FILINGS(),
    {
      company: company.zb_id,
    },
    { enabled: Boolean(company?.zb_id) }
  );
  const companyCOIs = coiFilings?.cleanedData?.data || [];

  if (coiFilings.isLoading) {
    return (
      <StyledLoadingState>
        <Fonts.Body1theme50>Loading...</Fonts.Body1theme50>
      </StyledLoadingState>
    );
  }
  if (coiFilings.isError) {
    return (
      <StyledCenteredEmptyState>An error has occurred</StyledCenteredEmptyState>
    );
  }
  if (coiFilings.isSuccess && companyCOIs.length === 0) {
    return (
      <StyledCenteredEmptyState $margin={EMPTY_STATE_MARGIN}>
        COIs not available
      </StyledCenteredEmptyState>
    );
  }
  const numberOfRows = isShowingMore ? companyCOIs.length : INITIAL_TABLE_SIZE;

  return (
    <>
      <List divider>
        <StyledListItem
          key="header"
          text={<Fonts.Overlinetheme50>Date</Fonts.Overlinetheme50>}
        />
        {companyCOIs.slice(0, numberOfRows).map((coi) => (
          <StyledListItem
            key={coi.apiId}
            text={DateTime.fromISO(coi.filing_date).toLocaleString(
              DateTime.DATE_FULL
            )}
            trailingContent={
              <StyledLink
                url={coi.file_kn_url}
                linkType={LinkTypes.EXTERNAL_LINK}
              >
                View
              </StyledLink>
            }
            onClick={() =>
              MixpanelEvents.viewCompanyCertificateOfIncorporation(
                company?.name,
                coi.filing_date
              )
            }
          />
        ))}
      </List>
      {companyCOIs.length > INITIAL_TABLE_SIZE && (
        <StyledButtonContainer>
          <Button
            buttonStyle={ButtonStyles.RAISED}
            onClick={() => {
              setIsShowingMore(!isShowingMore);
            }}
          >
            {isShowingMore ? "Show fewer" : "Show more"}
          </Button>
        </StyledButtonContainer>
      )}
    </>
  );
};

export default COIList;
