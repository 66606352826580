import _ from "lodash";
import { DateTime } from "luxon";
import React, { useCallback, useMemo, useState } from "react";
import {
  CartesianGrid,
  LineChart,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Line,
  Tooltip,
  Bar,
  ComposedChart,
} from "recharts";
import styled from "styled-components";
import {
  ColorPalette,
  PageSection,
  PlusIcon,
  useDropdown,
  YukaColorPalette,
} from "yuka";

import ActiveVolumeBar from "./ActiveVolumeBar";
import AddComparisonDropdown from "./AddComparisonDropdown";
import AddIndicatorDropdown from "./AddIndicatorDropdown";
import CompanyLineGraphTooltip from "./CompanyLineGraphTooltip";
import { AXIS_LABEL_FONT_SIZE, GRAPH_TOOLTIP_STYLES } from "./constants";
import useXAxisTicks from "./hooks/useXAxisTicks";
import LegendItem from "./LegendItem";
import { getActiveDot } from "./utils";

import {
  API_ENDPOINTS,
  PRIVATE_MARKET_INDICES_FEATURE_NAME,
} from "../../api/constants";
import useFetch from "../../api/useFetch";
import useFetches from "../../api/useFetches";
import useHasFeatureAccess from "../../company/hooks/useHasFeatureAccess";
import { ActionButton } from "../../hdYuka";
import { DataverseColors } from "../../hdYuka/constants";
import {
  INDICATOR_COLORS,
  INDICATOR_TYPES,
  KEY_COMPANY_SERIES_TYPE,
  ONE_YEAR_KEY,
  PUBLIC_COMPARISON_SERIES_TYPE,
  SIX_MONTHS_KEY,
  THREE_MONTHS_KEY,
  TWO_YEAR_KEY,
  YTD_KEY,
  ZX_COMPANY_EXCHANGE,
  ZX_COMPARISON_SERIES_TYPE,
  ZX_MARKET_INDEX_SERIES_COMPARISON_TYPE,
  getAbsoluteIndicatorValue,
} from "../../superchart/constants";
import OptionSelectList from "../../superchart/OptionSelectList";
import {
  combinePublicAndPreIPOData,
  makeAllIndicatorData,
  makeSuperchartData,
  mapGenericSecurityToId,
  mapGenericSecurityToSeriesType,
  pickRandomColor,
} from "../../superchart/utils";
import applyOpacityToHex from "../../utils/applyOpacityToHex";
import { ORDER_FLOW_TIME_FRAME_MONTHLY } from "../../utils/constants";
import {
  percentFormat,
  shortMoneyFormat,
} from "../../utils/displayFormatUtils";
import MixpanelEvents from "../../utils/mixpanel/MixpanelEvents";
import { useCompany } from "../hooks";
import { StyledCenteredEmptyState, StyledEmptyPill } from "../StyledComponents";

const StyledLineGraphLayout = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const StyledGraphControlRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  > :first-child {
    display: flex;
    gap: 16px;
  }
`;

const StyledLegendContainer = styled.div`
  display: flex;
  gap: 12px;
  flex-wrap: wrap;
`;

const StyledAbsoluteEmptyPill = styled(StyledEmptyPill)`
  position: absolute;
  height: 120px;
  width: 400px;
  top: calc(50% - 60px);
  left: calc(50% - 200px);

  > :first-child {
    height: 100%;
  }
`;

const DEFAULT_DATE_RANGE = ONE_YEAR_KEY;

const getLineProps = (chartElement) => ({
  connectNulls: true,
  isAnimationActive: false,
  dot: false,
  type: "monotone",
  dataKey: chartElement.id,
  activeDot: getActiveDot(chartElement),
  strokeWidth: 2,
  stroke: chartElement.color,
});

const SHARED_BAR_PROPS = {
  yAxisId: "volumeYAxis",
  stackId: "volumeStack",
  isAnimationActive: false,
  barSize: 12,
};

const CompanyProfileLineGraph = () => {
  const [zxMarketIndexAccess] = useHasFeatureAccess(
    PRIVATE_MARKET_INDICES_FEATURE_NAME
  );
  const [company, companyIsLoading] = useCompany();
  // Store all comparisons in a single state object.
  // List of { id: "21312412", type: "PUBLIC_COMPARISON", symbol: "AAPL", data: [] }
  const [comparisons, setComparisons] = useState([]);
  const [selectedIndicators, setSelectedIndicators] = useState(["ZIVT"]);
  const [selectedRange, setSelectedRange] = useState(DEFAULT_DATE_RANGE);
  // We'll leverage uuid4 collision rate to identify the hovered legend item.
  const [hoveredDate, setHoveredDate] = useState(null);
  const [hoveredLegendItem, setHoveredLegendItem] = useState(null);

  const companyOrderFlowQuery = useFetch(
    API_ENDPOINTS.COMPANY_PRICE_VOLUME_ORDER_FLOW(),
    {
      company: company?.zb_id,
    },
    {
      enabled: Boolean(company?.zb_id),
    }
  );

  const monthlyOrderFlowQuery = useFetch(
    API_ENDPOINTS.COMPANY_VOLUME_TRADING_DATA(company?.zb_id),
    {
      years: 2,
      time_frame: ORDER_FLOW_TIME_FRAME_MONTHLY,
    },
    {
      enabled: Boolean(company?.zb_id),
    }
  );

  const comparisonQueryObjects = useMemo(
    () =>
      comparisons
        .map(({ type, id }) => {
          if (type === ZX_COMPARISON_SERIES_TYPE) {
            return {
              url: API_ENDPOINTS.COMPANY_PRICE_VOLUME_ORDER_FLOW(),
              queryParams: { company: id },
            };
          } else if (
            type === ZX_MARKET_INDEX_SERIES_COMPARISON_TYPE &&
            zxMarketIndexAccess
          ) {
            return {
              url: API_ENDPOINTS.ZX_MARKET_INDEX_VALUES(),
              queryParams: { market_index: id },
            };
          } else if (type === PUBLIC_COMPARISON_SERIES_TYPE) {
            // Need to also add a query for the potentially existent orderflow data for
            // public companies.
            return [
              {
                url: API_ENDPOINTS.PUBLIC_SECURITY_QUOTE(),
                queryParams: { security: id },
              },
              {
                supplementary: true,
                url: API_ENDPOINTS.COMPANY_PRICE_VOLUME_ORDER_FLOW(),
                queryParams: { public_security: id },
              },
            ];
          }
        })
        .flat(),
    [zxMarketIndexAccess, comparisons]
  );
  const comparisonsQueries = useFetches(comparisonQueryObjects, {});

  const companyData = useMemo(() => {
    if (companyOrderFlowQuery.isSuccess) {
      return {
        id: company?.zb_id,
        name: company?.name,
        exchange: ZX_COMPANY_EXCHANGE,
        color: DataverseColors.branding400,
        data: makeSuperchartData(
          companyOrderFlowQuery.cleanedData.data,
          KEY_COMPANY_SERIES_TYPE
        )[selectedRange],
      };
    }
    return null;
  }, [
    selectedRange,
    company?.zb_id,
    company?.name,
    companyOrderFlowQuery.isSuccess,
    companyOrderFlowQuery.cleanedData,
  ]);

  const xAxisTicks = useXAxisTicks(companyData);

  const companyIndicatorData = useMemo(() => {
    if (companyOrderFlowQuery.isSuccess && monthlyOrderFlowQuery.isSuccess) {
      return makeAllIndicatorData(
        companyOrderFlowQuery.cleanedData.data,
        monthlyOrderFlowQuery.cleanedData.data
      );
    }
    return null;
  }, [companyOrderFlowQuery, monthlyOrderFlowQuery]);

  const availableIndicators = useMemo(() => {
    // The full set of available indicators. Will be used in combination with `selectedIndicators`
    // to determine the chart data to render.
    if (!companyIndicatorData) {
      return [];
    }
    return Object.keys(companyIndicatorData).map((indicatorName, index) => ({
      id: indicatorName,
      name: indicatorName,
      ...INDICATOR_TYPES[indicatorName],
      data: companyIndicatorData[indicatorName][selectedRange],
      color: INDICATOR_COLORS[index % INDICATOR_COLORS.length],
    }));
  }, [selectedRange, companyIndicatorData]);

  const superchartComparisonData = useMemo(() => {
    if (comparisonsQueries.isAnySuccess) {
      // Since for public companies we need to query our private endpoints to cover the
      // "recently IPO'd" case we need to first pre-process the result of our comparison queries.
      // Attempt to match the public company data with the orderflow data based on location in
      // the queries output.
      const actualCleanedData = comparisonsQueries.cleanedData
        .map((comparisonData, index) => {
          if (!comparisonData && !comparisonQueryObjects[index].supplementary) {
            return comparisonData;
          }
          if (comparisonQueryObjects[index + 1]?.supplementary) {
            // combine the next query's results with this one.
            return {
              ...comparisonData,
              data: combinePublicAndPreIPOData(
                comparisonData.data,
                comparisonsQueries.cleanedData[index + 1]?.data || []
              ),
            };
          } else if (comparisonQueryObjects[index].supplementary) {
            return null;
          }
          return comparisonData;
        })
        .filter((data) => data !== null); // Remove supplementary data points.

      return actualCleanedData.map((comparisonData, index) => {
        const data = comparisonData?.data.length
          ? makeSuperchartData(comparisonData.data, comparisons[index].type)[
              selectedRange
            ]
          : [];

        return {
          ...comparisons[index],
          isLoading: !comparisonData?.data,
          data,
        };
      });
    }
    return [];
  }, [selectedRange, comparisons, comparisonQueryObjects, comparisonsQueries]);

  const superchartIndicatorData = useMemo(() => {
    // The actual chart data to render for the indicators.
    if (!companyIndicatorData) return [];

    return availableIndicators.filter((indicator) =>
      selectedIndicators.includes(indicator.id)
    );
  }, [availableIndicators, companyIndicatorData, selectedIndicators]);

  const onSelectComparison = useCallback(
    (security) => {
      setComparisons([
        ...comparisons,
        {
          id: mapGenericSecurityToId(security),
          type: mapGenericSecurityToSeriesType(security),
          name: security.symbol,
          color: pickRandomColor(),
          exchange: security.exchange,
          enabled: true,
        },
      ]);
      MixpanelEvents.superChartAddComparison(company?.name, security.name);
    },
    [comparisons, company?.name]
  );

  const onRemoveComparison = useCallback(
    (comparisonId) => {
      setComparisons(comparisons.filter((comp) => comp.id !== comparisonId));
      setHoveredLegendItem(null);
    },
    [comparisons]
  );

  const onSelectIndicator = useCallback(
    (indicatorName) => {
      if (companyData) {
        setSelectedIndicators([
          ...new Set([...selectedIndicators, indicatorName]),
        ]);
      }
      MixpanelEvents.superChartAddIndicator(company?.name, indicatorName);
    },
    [company?.name, companyData, selectedIndicators]
  );

  const onRemoveIndicator = useCallback(
    (indicatorName) => {
      setSelectedIndicators(_.without(selectedIndicators, indicatorName));
      setHoveredLegendItem(null);
    },
    [selectedIndicators]
  );

  const [addComparisonDropdown, addComparisonRef, toggleAddComparisonDropdown] =
    useDropdown(({ toggleIsOpen }) => (
      <AddComparisonDropdown
        onSelectComparison={(security) => {
          onSelectComparison(security);
          toggleIsOpen();
        }}
      />
    ));

  const chartData = useMemo(() => {
    if (!companyData) {
      return null;
    }
    // Get full list of dates from the `time` field of the comparisons and companyData.
    const allDates = [
      ...new Set(
        [
          ...companyData.data.map((data) => data.time),
          ...superchartComparisonData
            .map((data) => data.data.map((d) => d.time))
            .flat(),
          ...superchartIndicatorData
            .map((data) => data.data.map((d) => d.time))
            .flat(),
        ].sort()
      ),
    ];

    // Construct these 3 specialized data structures to provide O(1) lookups in the allDates.map
    // step next.
    const companyDataPointsByDate = _.keyBy(companyData.data, "time");
    const comparisonDataByDate = superchartComparisonData.map((comparison) => ({
      id: comparison.id,
      data: _.keyBy(comparison.data, "time"),
    }));
    const indicatorDataByDate = superchartIndicatorData.map((indicator) => ({
      id: indicator.id,
      name: indicator.name,
      getIndicatorValueObject:
        indicator.getIndicatorValueObject || getAbsoluteIndicatorValue,
      data: _.keyBy(indicator.data, "time"),
    }));

    // Create a new list of data points, where each point contains the date, the percent and
    // absolute values from the companyData, the percent or absolute values from the indicator
    // data, and the percent values from the comparisonData, to be fed into the recharts LineGraph.
    return allDates.map((date) => {
      const companyDataPoint = companyDataPointsByDate[date];
      const comparisonDataPoints = comparisonDataByDate.map(({ id, data }) => {
        const comparisonDataPoint = data[date];
        return comparisonDataPoint ? { [id]: comparisonDataPoint.percent } : {};
      });
      const indicatorDataPoints = indicatorDataByDate.map(
        ({ id, data, getIndicatorValueObject }) => {
          const indicatorDataPoint = data[date];
          return indicatorDataPoint
            ? getIndicatorValueObject(id, indicatorDataPoint)
            : {};
        }
      );

      return {
        time: date,
        percent: companyDataPoint?.percent,
        absolute: companyDataPoint?.absolute,
        ...Object.assign({}, ...comparisonDataPoints),
        ...Object.assign({}, ...indicatorDataPoints),
      };
    });
  }, [companyData, superchartComparisonData, superchartIndicatorData]);

  const hoveredChartElement = useMemo(() => {
    // Craftily, we'll store the actual rendering of the hovered line in memory so we can render
    // it last in the DOM, and make it appear above the greyed-out lines. We'll use the uniqueness
    // of the legend item IDs, and the fact that all data charted conforms to the same shape to
    // achieve this.
    if (hoveredLegendItem === null) {
      return null;
    }

    if (hoveredLegendItem.isVolume) {
      return (
        <>
          <Bar
            {...SHARED_BAR_PROPS}
            key={`${hoveredLegendItem.id}_bid`}
            dataKey={`${hoveredLegendItem.id}_bid`}
            fill={DataverseColors.green}
          />
          <Bar
            {...SHARED_BAR_PROPS}
            style={{ transform: "translateY(-2px)" }}
            key={`${hoveredLegendItem.id}_offer`}
            dataKey={`${hoveredLegendItem.id}_offer`}
            fill={DataverseColors.red}
          />
        </>
      );
    }
    return (
      <Line
        {...getLineProps(hoveredLegendItem)}
        dataKey={hoveredLegendItem.dataKey || hoveredLegendItem.id}
      />
    );
  }, [hoveredLegendItem]);

  if (!company || !companyData) {
    return (
      <StyledLineGraphLayout>
        <StyledGraphControlRow>
          <div>
            <ActionButton
              id="add-comparison-button"
              disabled={true}
              onClick={toggleAddComparisonDropdown}
              ref={addComparisonRef}
              icon={PlusIcon}
            >
              Comparisons
            </ActionButton>
            <AddIndicatorDropdown disabled={true} />
          </div>
          <OptionSelectList
            title={null}
            defaultOption={selectedRange}
            onOptionChange={(option) => {
              setSelectedRange(option);
            }}
            disabled={true}
            options={[
              { label: THREE_MONTHS_KEY },
              { label: SIX_MONTHS_KEY },
              { label: ONE_YEAR_KEY },
              { label: TWO_YEAR_KEY },
              { label: YTD_KEY },
            ]}
          />
        </StyledGraphControlRow>
        <ResponsiveContainer width="100%" height={300}>
          <LineChart width={500} height={300} data={[]}>
            <CartesianGrid
              horizontalPoints={[0, 75, 150, 225, 300]}
              vertical={false}
              stroke={ColorPalette.white05}
            />
          </LineChart>
          <StyledAbsoluteEmptyPill>
            <StyledCenteredEmptyState $margin={0}>
              {companyIsLoading ? "Loading..." : "No data available"}
            </StyledCenteredEmptyState>
          </StyledAbsoluteEmptyPill>
        </ResponsiveContainer>
      </StyledLineGraphLayout>
    );
  }

  return (
    <StyledLineGraphLayout>
      <StyledGraphControlRow>
        <div>
          <ActionButton
            id="add-comparison-button"
            active={addComparisonDropdown}
            onClick={toggleAddComparisonDropdown}
            ref={addComparisonRef}
            icon={PlusIcon}
          >
            Comparisons
          </ActionButton>
          <AddIndicatorDropdown
            availableIndicators={availableIndicators}
            addIndicator={onSelectIndicator}
            removeIndicator={onRemoveIndicator}
            selectedIndicators={selectedIndicators}
          />
        </div>
        <OptionSelectList
          title={null}
          defaultOption={selectedRange}
          onOptionChange={(option) => {
            setSelectedRange(option);
          }}
          disabled={!company}
          options={[
            { label: THREE_MONTHS_KEY },
            { label: SIX_MONTHS_KEY },
            { label: ONE_YEAR_KEY },
            { label: TWO_YEAR_KEY },
            { label: YTD_KEY },
          ]}
        />
      </StyledGraphControlRow>
      <StyledLegendContainer>
        {companyData && (
          <LegendItem
            isHovered={hoveredLegendItem?.id === companyData.id}
            onMouseEnter={() =>
              setHoveredLegendItem({
                ...companyData,
                dataKey: comparisons.length > 0 ? "percent" : "absolute",
              })
            }
            onMouseLeave={() => setHoveredLegendItem(null)}
            chartElement={{
              ...companyData,
              name:
                comparisons.length > 0
                  ? companyData.name
                  : "ZX Index Value - Current",
              exchange: comparisons.length > 0 ? companyData.exchange : null,
            }}
            onRemove={null}
            hoveredDate={hoveredDate}
          />
        )}
        {comparisons.length === 0 &&
          superchartIndicatorData.map((indicator) => {
            return (
              <indicator.LegendItem
                key={`legend_${indicator.id}`}
                isHovered={hoveredLegendItem?.id === indicator.id}
                onMouseEnter={() => setHoveredLegendItem(indicator)}
                onMouseLeave={() => setHoveredLegendItem(null)}
                onRemove={() => onRemoveIndicator(indicator.id)}
                hoveredDate={hoveredDate}
                chartElement={indicator}
              />
            );
          })}
        {superchartComparisonData.map((comparison) => (
          <LegendItem
            key={`legend_${comparison.id}`}
            isHovered={hoveredLegendItem?.id === comparison.id}
            onMouseEnter={() => setHoveredLegendItem(comparison)}
            onMouseLeave={() => setHoveredLegendItem(null)}
            onRemove={() => onRemoveComparison(comparison.id)}
            chartElement={comparison}
            hoveredDate={hoveredDate}
          />
        ))}
      </StyledLegendContainer>
      <ResponsiveContainer width="100%" height={300}>
        <ComposedChart
          width={500}
          height={300}
          data={chartData}
          margin={{ left: 20, right: 8, top: 8 }}
        >
          <CartesianGrid vertical={false} stroke="rgba(255, 255, 255, 0.05)" />
          <XAxis
            interval={0}
            fontSize={AXIS_LABEL_FONT_SIZE}
            dy={8}
            tickFormatter={(value) => DateTime.fromISO(value).toFormat("MMM")}
            ticks={xAxisTicks}
            dataKey="time"
          />
          <YAxis
            width={0}
            yAxisId="volumeYAxis"
            domain={[0, (dataMax) => dataMax * 3]}
          />
          <YAxis
            domain={["dataMin", "dataMax"]}
            orientation="right"
            fontSize={AXIS_LABEL_FONT_SIZE}
            tickFormatter={(value) =>
              comparisons.length === 0
                ? shortMoneyFormat(value)
                : percentFormat(value)
            }
          />
          <Tooltip
            offset={0}
            allowEscapeViewBox={{ x: true }}
            wrapperStyle={GRAPH_TOOLTIP_STYLES}
            position={{ x: hoveredDate, y: 240 }}
            cursor={{ stroke: ColorPalette.white50, strokeWidth: 1 }}
            content={
              <CompanyLineGraphTooltip setHoveredDate={setHoveredDate} />
            }
          />
          <Line
            {...getLineProps(companyData)}
            dataKey={comparisons.length > 0 ? "percent" : "absolute"}
            stroke={
              hoveredLegendItem === null
                ? companyData.color
                : YukaColorPalette.surface3
            }
          />
          {superchartComparisonData.map((comparison) =>
            hoveredLegendItem?.id === comparison.id ? null : (
              <Line
                {...getLineProps(comparison)}
                key={comparison.id}
                stroke={
                  hoveredLegendItem === null
                    ? comparison.color
                    : YukaColorPalette.surface3
                }
              />
            )
          )}
          {comparisons.length === 0 &&
            superchartIndicatorData.map((indicator) => {
              if (hoveredLegendItem?.id === indicator.id) {
                // We can return null for this indicator b/c it'll be rendered
                // by `hoveredChartElement`.
                return null;
              }
              if (indicator.isVolume) {
                return (
                  <>
                    <Bar
                      {...SHARED_BAR_PROPS}
                      activeBar={ActiveVolumeBar(
                        `${indicator.id}_bid`,
                        DataverseColors.green,
                        0
                      )}
                      key={`${indicator.id}_bid`}
                      dataKey={`${indicator.id}_bid`}
                      fill={applyOpacityToHex(DataverseColors.green, 0.5)}
                    />
                    <Bar
                      {...SHARED_BAR_PROPS}
                      activeBar={ActiveVolumeBar(
                        `${indicator.id}_offer`,
                        DataverseColors.red,
                        1
                      )}
                      style={{ transform: "translateY(-2px)" }}
                      key={`${indicator.id}_offer`}
                      dataKey={`${indicator.id}_offer`}
                      fill={applyOpacityToHex(DataverseColors.red, 0.5)}
                    />
                  </>
                );
              }
              return (
                <Line
                  {...getLineProps(indicator)}
                  key={indicator.id}
                  stroke={
                    hoveredLegendItem === null
                      ? indicator.color
                      : YukaColorPalette.surface3
                  }
                />
              );
            })}
          {hoveredChartElement}
        </ComposedChart>
      </ResponsiveContainer>
      <PageSection>{addComparisonDropdown}</PageSection>
    </StyledLineGraphLayout>
  );
};

export default CompanyProfileLineGraph;
