import { DateTime } from "luxon";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import {
  Card,
  CardStyles,
  Fonts,
  HyperLink,
  LinkStyles,
  LinkTypes,
  ImageIcon,
  ColorPalette,
} from "yuka";

const StyledArticleImage = styled.div`
  ${(props) =>
    props.$background &&
    css`
      background-image: url(${(props) => props.$background});
      background-size: cover;
      background-position: center;
    `}
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -16px;
  width: 100%;
  height: 100px;
`;

const StyledCardContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 16px;
`;

const StyledCard = styled(Card)`
  height: 100%;
`;

const MentionArticleCard = ({ article }) => (
  <HyperLink
    linkStyle={LinkStyles.UNSTYLED}
    linkType={LinkTypes.EXTERNAL_LINK}
    url={article.original_url}
  >
    <StyledCard cardStyle={CardStyles.MINIMAL} onClick={() => {}}>
      <StyledArticleImage
        $background={
          article.mention_image || article.mention_image_original_url
        }
      >
        {article.mention_image || article.mention_image_original_url ? null : (
          <ImageIcon color={ColorPalette.white50} size={24} />
        )}
      </StyledArticleImage>
      <StyledCardContent>
        <Fonts.Body1theme50>
          {article.mention_source_name} |{" "}
          {DateTime.fromISO(article.published_at).toRelative()}
        </Fonts.Body1theme50>
        <Fonts.Body2theme80>{article.title}</Fonts.Body2theme80>
      </StyledCardContent>
    </StyledCard>
  </HyperLink>
);

MentionArticleCard.propTypes = {
  article: PropTypes.shape({
    published_at: PropTypes.string,
    original_url: PropTypes.string,
    title: PropTypes.string,
    mention_source_name: PropTypes.string,
    mention_image: PropTypes.string,
    mention_image_original_url: PropTypes.string,
  }).isRequired,
};

export default MentionArticleCard;
