import _ from "lodash";
import React, { useMemo } from "react";
import styled from "styled-components";
import { CardStyles, Fonts, TabList, useCurrentTab } from "yuka";

import COIList from "./COIList";
import FundingRoundsStat from "./FundingRoundsStat";
import FundingRoundTable from "./FundingRoundTable";
import InvestorsTable from "./InvestorsTable";

import { API_ENDPOINTS } from "../../../api/constants";
import useInfiniteFetch from "../../../api/useInfiniteFetch";
import useEntityProfileFetches from "../../../company/utils/useEntityProfileFetches";
import { SurfaceZeroCard } from "../../../hdYuka";
import { shortMoneyFormat } from "../../../utils/displayFormatUtils";
import LoadingSpinner from "../../../utils/LoadingSpinner";
import { StyledError } from "../../../utils/StyledComponents";
import { useCompany } from "../../hooks";

const StyledSurfaceZeroCard = styled(SurfaceZeroCard)`
  min-height: 434px;
`;

const EmptyInfoContainer = styled.div`
  padding: 0 16px;
`;

const StyledFundingRoundsHeader = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding-bottom: 16px;
`;

const StyledStatsRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  column-gap: 48px;
`;

const StyledTabListContainer = styled.div`
  padding-bottom: 24px;
`;

const TAB_MAP = {
  FUNDING_ROUNDS: "Funding rounds",
  INVESTORS: "Investors",
  COIS: "COIs",
};

const FundingCard = () => {
  const currentTab = useCurrentTab();
  const [company] = useCompany();

  // Using a hack page size here that a query to the funding rounds/stock split table for a given
  // company will not exceed in practice. There is great difficulty with combining multiple models
  // into 1 resultant queryset + using pagination. This is a hack to get around that.
  const hdFundingRoundsReq = useInfiniteFetch(
    API_ENDPOINTS.HD_FUNDING_ROUNDS(),
    {
      company: company?.zb_id,
      "page[size]": 100000,
    }
  );

  const stockSplitsReq = useInfiniteFetch(API_ENDPOINTS.STOCK_SPLITS(), {
    company: company?.zb_id,
    "page[size]": 100000,
  });

  const fundingRounds = useMemo(() => {
    return hdFundingRoundsReq.isSuccess
      ? hdFundingRoundsReq.cleanedData.data
      : [];
  }, [hdFundingRoundsReq.isSuccess, hdFundingRoundsReq.cleanedData.data]);

  const totalPreferredRounds = useMemo(() => {
    return fundingRounds.filter((round) =>
      round.security?.toLowerCase().includes("preferred")
    ).length;
  }, [fundingRounds]);

  const totalFundingAmount = useMemo(
    () =>
      fundingRounds.reduce(
        (acc, round) => acc + Number(round.amount_raised) || 0,
        0
      ),
    [fundingRounds]
  );

  const stockSplits = useMemo(
    () => (stockSplitsReq.isSuccess ? stockSplitsReq.cleanedData.data : []),
    [stockSplitsReq.isSuccess, stockSplitsReq.cleanedData.data]
  );

  const entityProfilesQuery = useEntityProfileFetches(fundingRounds);
  const entityProfiles = useMemo(
    () =>
      entityProfilesQuery.isAnySuccess
        ? _.keyBy(entityProfilesQuery?.cleanedData.data || [], "apiId")
        : {},
    [entityProfilesQuery.isAnySuccess, entityProfilesQuery.cleanedData]
  );

  const numFundingRounds = fundingRounds ? fundingRounds.length : 0;
  const loading = hdFundingRoundsReq.isLoading || stockSplitsReq.isLoading;

  let tableContent = null;
  if (loading) {
    tableContent = <LoadingSpinner absolute={false} />;
  } else if (hdFundingRoundsReq.isError || stockSplitsReq.isError) {
    tableContent = (
      <EmptyInfoContainer>
        <StyledError>An error has occurred</StyledError>
      </EmptyInfoContainer>
    );
  } else {
    const grouped = [];
    let currGroupedFundingRounds = [];
    let currNumFundingRounds = 0;
    let currRoundIndex = 0;
    let currSplitIndex = 0;
    while (
      currNumFundingRounds < numFundingRounds &&
      currSplitIndex < stockSplits.length
    ) {
      const currRound = fundingRounds[currRoundIndex];
      const currSplit = stockSplits[currSplitIndex];
      if (currRound.order > currSplit.order) {
        // part of the funding round table
        currGroupedFundingRounds.push(currRound);
        currRoundIndex += 1;
        currNumFundingRounds += 1;
      } else {
        // break up the funding round table and insert a row for the stock split
        grouped.push(currGroupedFundingRounds);
        grouped.push(currSplit);
        currGroupedFundingRounds = [];
        currSplitIndex += 1;
      }
    }
    if (currNumFundingRounds === numFundingRounds) {
      // at the end, attach the last batch of included funding rounds
      if (currGroupedFundingRounds.length > 0) {
        grouped.push(currGroupedFundingRounds);
      }
    } else if (currNumFundingRounds < numFundingRounds) {
      const numNeeded = numFundingRounds - currNumFundingRounds;
      grouped.push(
        fundingRounds.slice(currRoundIndex, currRoundIndex + numNeeded)
      );
    }

    const nextSplit = stockSplits[currSplitIndex];
    if (nextSplit) {
      grouped.push(nextSplit);
    }

    tableContent = (
      <FundingRoundTable
        entityProfiles={entityProfiles}
        fundingRounds={_.flatten(grouped)}
      />
    );
  }

  const tabContent = useMemo(() => {
    switch (currentTab) {
      case TAB_MAP.FUNDING_ROUNDS:
        return tableContent;
      case TAB_MAP.INVESTORS:
        return (
          <InvestorsTable
            entityProfiles={entityProfiles}
            fundingRounds={fundingRounds}
          />
        );
      case TAB_MAP.COIS:
        return <COIList />;
      default:
        return <div>An error has occurred</div>;
    }
  }, [currentTab, tableContent, entityProfiles, fundingRounds]);

  return (
    <StyledSurfaceZeroCard cardStyle={CardStyles.PADDED} title="Funding">
      <StyledFundingRoundsHeader>
        <StyledStatsRow>
          <FundingRoundsStat
            mainStatistic={shortMoneyFormat(totalFundingAmount)}
            supportingText="Total funding amount"
          />
          <FundingRoundsStat
            mainStatistic={totalPreferredRounds}
            supportingText="Funding rounds"
          />
          <FundingRoundsStat
            mainStatistic={
              entityProfilesQuery.isLoading
                ? "Loading"
                : Object.keys(entityProfiles).length
            }
            supportingText="Investors"
          />
        </StyledStatsRow>
        <Fonts.Caption2theme30>
          Data is collected from state filings and publicly available sources,
          and may be incomplete.
        </Fonts.Caption2theme30>
      </StyledFundingRoundsHeader>
      <StyledTabListContainer>
        <TabList
          tabs={[
            { label: TAB_MAP.FUNDING_ROUNDS },
            { label: TAB_MAP.INVESTORS },
            { label: TAB_MAP.COIS },
          ]}
          defaultTab={TAB_MAP.FUNDING_ROUNDS}
        />
      </StyledTabListContainer>
      <div>{tabContent}</div>
    </StyledSurfaceZeroCard>
  );
};

export default FundingCard;
