import PropTypes from "prop-types";
import React, { useMemo } from "react";
import { useFormState } from "react-final-form";

import { useComponent } from "./useComponents";
import useLatestOrderFlowReport from "./useLatestOrderFlowReport";
import useNoDataErrorState from "./useNoDataErrorState";
import ValuationPriceComponentRow from "./ValuationPriceComponentRow";

const ZXIndexPriceComponentRow = (props) => {
  const { values } = useFormState();
  const { currentValue } = useComponent(values, props.id);

  const [orderFlowReport, orderFlowReportIsLoading] = useLatestOrderFlowReport(
    props.companyId,
    values.valuation_date,
    !props.initialValue &&
      Boolean(values.valuation_date) &&
      Boolean(props.companyId)
  );

  const computedValue = useMemo(
    () =>
      props.initialValue
        ? props.initialValue
        : Number(orderFlowReport?.zx_index_value_trailing) || null,
    [orderFlowReport, props.initialValue]
  );

  const error = useNoDataErrorState(
    props.initialValue || !values.valuation_date || orderFlowReportIsLoading,
    computedValue || currentValue
  );

  return (
    <ValuationPriceComponentRow
      {...props}
      errorText={error}
      initialValue={computedValue}
      editable={!props.initialValue}
    />
  );
};

ZXIndexPriceComponentRow.propTypes = {
  id: PropTypes.number,
  initialValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  companyId: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};

export default ZXIndexPriceComponentRow;
