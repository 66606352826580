import PropTypes from "prop-types";
import styled from "styled-components";
import { Card, ColorPalette, YukaThemeProvider } from "yuka";

const StyledCard = styled(Card)`
  border: 1px solid ${ColorPalette.white30};
`;

const SurfaceZeroCard = ({ children, ...props }) => {
  return (
    <YukaThemeProvider theme={{ surfaceLevel: -1 }}>
      <StyledCard {...props}>
        <YukaThemeProvider theme={{ surfaceLevel: 1 }}>
          {children}
        </YukaThemeProvider>
      </StyledCard>
    </YukaThemeProvider>
  );
};

SurfaceZeroCard.propTypes = {
  children: PropTypes.node,
};

export default SurfaceZeroCard;
