import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";
import {
  ActionChip,
  CheckIcon,
  ChevronDownIcon,
  List,
  ListItem,
  PageSection,
  useDropdown,
} from "yuka";

import {
  SELECTED_RATIO_TYPE_VOLUME,
  SELECTED_RATIO_TYPE_TICKETS,
} from "./constants";

import { DataverseColors } from "../../../hdYuka/constants";

const StyledDropdownContainer = styled(PageSection)`
  padding: 12px 0;
  margin: -16px 0;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const StyledListItem = styled(ListItem)`
  padding: 0 12px;
  height: 32px;
  min-height: 32px;
`;

const IconPlaceholderDiv = styled.div`
  width: 18px;
  height: 18px;
`;

const SelectTypeDropdown = (props) => {
  const isVolumeSelected =
    props.selectedRatioType === SELECTED_RATIO_TYPE_VOLUME;

  // eslint-disable-next-line react/prop-types
  const TypeDropdown = ({ toggleIsOpen }) => {
    const selectVolume = () => {
      props.setSelectedRatioType(SELECTED_RATIO_TYPE_VOLUME);
      toggleIsOpen();
    };
    const selectNumTickets = () => {
      props.setSelectedRatioType(SELECTED_RATIO_TYPE_TICKETS);
      toggleIsOpen();
    };

    return (
      <StyledDropdownContainer>
        <List>
          <StyledListItem
            text="by volume"
            onClick={selectVolume}
            leadingIconColor={DataverseColors.branding400}
            leadingIcon={isVolumeSelected ? CheckIcon : IconPlaceholderDiv}
          />
          <StyledListItem
            text="by tickets"
            onClick={selectNumTickets}
            leadingIconColor={DataverseColors.branding400}
            leadingIcon={!isVolumeSelected ? CheckIcon : IconPlaceholderDiv}
          />
        </List>
      </StyledDropdownContainer>
    );
  };

  const [dropdownElement, dropdownRef, toggleDropdown] =
    useDropdown(TypeDropdown);

  return (
    <>
      <ActionChip
        onClick={toggleDropdown}
        text={
          props.selectedRatioType === SELECTED_RATIO_TYPE_VOLUME
            ? "by volume"
            : "by tickets"
        }
        trailingIcon={ChevronDownIcon}
        ref={dropdownRef}
      />
      {dropdownElement}
    </>
  );
};

SelectTypeDropdown.propTypes = {
  selectedRatioType: PropTypes.oneOf([
    SELECTED_RATIO_TYPE_VOLUME,
    SELECTED_RATIO_TYPE_TICKETS,
  ]).isRequired,
  setSelectedRatioType: PropTypes.func.isRequired,
};

export default SelectTypeDropdown;
